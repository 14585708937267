
import { ADD_TO_CART } from './../actions/index';
export default function (state:any, action:any) {
  switch(action.type) {
    case ADD_TO_CART:
      return {
        'type': 'opened',
      };
    default:
      return {
        'type': 'closed',
      }
  }
}
