import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderComponentComponent from './../../../components/nav/header_component/header_component.component';

let this_component:any;
interface Props {
  history: any
}  
class CommandIsSavedComponentComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  render():Array<JSX.Element> {
    this_component = this;
    (window as any).react_history = this.props.history;
    setTimeout(() => {
      (window as any).react_history.push('/order/command_saved/');
    }, 2 * 1000);

    return [
      (
        <>
          <HeaderComponentComponent />
        </>
      ),
      (
        <div className="CommandIsSavedComponentComponent">
          <h1>Votre commande</h1>
          <div className="commandContainer">
             <div className="">Votre commande <br/> est enregistrée !</div>
             <div className="">Un serveur va <br/> vous apporter vo(tre) <br/> {(window as any).data.cart_list[1].name}</div>
          </div>
        </div>
      )
    ];
  }
}
function mapStateToProps(state:any) {
    if(this_component!==undefined) {

    }
    return {
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    // goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CommandIsSavedComponentComponent);
