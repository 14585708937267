import React from 'react';
import { connect } from 'react-redux';
import { is_electron_browser } from './../../../../libs/index';
import PopInBinaryChoiceComponent from './../../../nav/pop_in_binary_choice/pop_in_binary_choice.component';

let this_component: any;
interface Props { popin_this: any, }
class CommandTypeComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  data = {
    'type': 'command_type',
    'title_first_part': "COMMENT PRENEZ VOUS",
    'title_second_part': "VOTRE COMMANDE ?",
    'choices': [
      {
        'text': 'SUR PLACE',
        'style': {
          'backgroundImage': 'url(/public/img/order/command_type/sur_place.png)',
        },
        'go_to': { 'action': 'popin', 'id': 'payement_type' },
        'add_to_cart_config': {
          'command_type': 'in_the_restaurant',
        }
      },
      {
        'text': 'À EMPORTER',
        'style': {
          'backgroundImage': 'url(/public/img/order/command_type/a_emporter.png)',
        },
        'go_to': { 'action': 'popin', 'id': 'payement_type' },
        'add_to_cart_config': {
          'command_type': 'takeaway',
        }
      }
    ]
  }
  render(): JSX.Element {
    if (!is_electron_browser()) {
      this.data.choices[0].go_to = { 'action': 'history', 'id': '/order/select_payment_type/' }
      this.data.choices[0].style = Object.assign({
        'backgroundPosition': '50% 23px',
        'backgroundSize': '100%',
      }, this.data.choices[0].style);
      //  a emporter
      this.data.choices[1].go_to = { 'action': 'history', 'id': '/order/select_payment_type/' }
      this.data.choices[1].style = Object.assign({
        'backgroundPosition': '50% 23px',
        'backgroundSize': '100%',
      }, this.data.choices[1].style)
    }
    this_component = this;
    (window as any).react_history = (this.props as any).history;
    return (
      <PopInBinaryChoiceComponent popin_this={this.props.popin_this} type="basic" data={this.data}></PopInBinaryChoiceComponent>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version
  };
}

export default connect(mapStateToProps)(CommandTypeComponent);
