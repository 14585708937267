import { useFormik } from "formik";
import React, {  } from 'react';
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  prenom: Yup.string().required('Merci de saisir votre prenom'),
  nom: Yup.string().required('Merci de saisir votre nom'),
  bipper: Yup.string().required('Merci de saisir votre numero'),
});

export const OnsiteUserComponent = (props) => {

  const { user } = props;
  
  const { handleSubmit, errors, values, handleChange } = useFormik({
    initialValues: {
      prenom: user.prenom || "",
      nom: user.nom || "",
      bipper: user.bipper || ""
    },
    validationSchema: SignupSchema,
    onSubmit: (val) => {
      console.log("Form values: ", val);
      console.log("Form errors: ", errors);
      props.setUser({ ...val, mode: 'surplace' });
      setTimeout(() => {
        props.goToPath({
          'action': 'history',
          'id': '/order/'
        });
      }, 100);
    }
  });

  (window as any).react_history = props.history;
  // console.log("Form Values: ", values);

  return (
    <div className="user-form">
      <div className="header">
        <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
        </div>
      </div>

      <div className="container">
        <h2 className="title">IDENTIFIEZ-VOUS !</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <label htmlFor="prenom">Prénom</label>
              <input
                id="prenom"
                name="prenom"
                type="text"
                onChange={handleChange}
                value={values.prenom}
              />
              {errors.prenom && <div className="error_text">{errors.prenom}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="nom">Nom</label>
              <input
                id="nom"
                name="nom"
                type="text"
                onChange={handleChange}
                value={values.nom}
              />
              {errors.nom && <div className="error_text">{errors.nom}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="bipper">N° de table ou de bipper</label>
              <input
                id="bipper"
                name="bipper"
                type="text"
                onChange={handleChange}
                value={values.bipper}
              />
              {errors.bipper && <div className="error_text">{errors.bipper}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button className="btn button_yellow_on_white box_shadow_light" type="submit">Continuer</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
