

import { AUTHENTIFICATION, LOGOUT, UPDATE_DATA, LOAD_CUSTOMER, is_redux_init } from './../actions/index';
export default function (state:any, action:any) {
  if (is_redux_init(action)) {
    return {
      'authenticated': false,
    };
  }

  switch(action.type){
    case UPDATE_DATA:
      // if (action.payload.for_reducer==='authentication') {
      //   return action.payload.data;
      // }
      return state;
    case AUTHENTIFICATION:
        // TODO -> Ici il faudra ajouter le code pour renvoyer des informations intéressantes
        let auth = {
          'authenticated': true
        };
        return auth;
    case LOAD_CUSTOMER:
      return {
       'authenticated': true,
     };
    case LOGOUT:
       auth = {
        'authenticated': false,
      };
      return auth;
    default:
        return state;
  }
}
