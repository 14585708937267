import React from 'react';
import { connect } from 'react-redux';
import { goToPath } from './../../../actions/index';
import { bindActionCreators } from 'redux';
let this_component =  undefined;

// -> Générer les listes des temps possibles.
let LIST_OF_TIME = []
let list_evolution = [0, 15, 30, 45];
for (let i = 0; i<24; i++) {
    for (let evolution of list_evolution) {
      LIST_OF_TIME.push([i, evolution])
    }
}

interface Props {
  type: any,
  enterprise: any,
}
class HourComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  state:any = {
  }


  transform_slot(slot) {
    let open = slot[0].split('h');
    let close = slot[1].split('h');
    open = [isNaN(parseInt(open[0])) ? 0 : parseInt(open[0]),isNaN(parseInt(open[1])) ? 0 : parseInt(open[1])];
    close = [isNaN(parseInt(close[0])) ? 0 : parseInt(close[0]),isNaN(parseInt(close[1])) ? 0 : parseInt(close[1])];

    let open_and_close = {
      'open': open,
      'close': close,
    };
    return open_and_close;
  }

  generate_hour(enterprise) {
    let calendar = enterprise.calendar;
    let week_day_list = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    let week_day = week_day_list[new Date().getDay()];
    let unformated_hours = calendar[week_day].split(';');
    let hours = [];
    for (let hour of unformated_hours) {
      let slot = hour.split('->');
      hours.push(this.transform_slot(slot));
    }
    return hours;
  }

  is_good_slot(hour, minutes, slot) {
    return (slot.open[0] <= hour && slot.open[1] <= minutes && slot.close[0] > hour);
  }

  is_out_of_good_hour(hour, minutes, hours) {
      for (let time of hours) {
        if (time.open[0] <= hour && time.open[1] <= minutes && time.close[0] > hour) {
          return true;
        }
      }
      return false;
  }

  renderDecompteDay() {
      let render = [];
      let now = new Date();
      let week_day_list = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
      render.push((<option value={now.getDate() + ' ' + week_day_list[now.getDay()] + ' ' + now.getFullYear()}>{now.getDate() + ' ' + week_day_list[now.getDay()] + ' ' + now.getFullYear()}</option>))
      return render;
  }

  get_last_time(hour, minutes, hours) {
    let list_last_time = [];
    for (let time of hours) {
        if (time.close[0] >= hour) {
          if (time.close[0]==hour && time.close[1] >= minutes) {
            continue
          }
          list_last_time.push(time)
        }
    }
    return list_last_time;
  }

  renderDecompteHour() {
    let render = [];
    let hours = this.generate_hour(this.props.enterprise);
    let now = new Date();
    let list_last_time = this.get_last_time(now.getHours(), now.getMinutes(), hours);
    console.log(list_last_time);
    // -> SI aucune heure n'est disponibles
    if (list_last_time.length==0) {
      return (<option value="">Aucune heure disponibles</option>);
    }
    let options = [];

    // -> Modifier la valeur du premier slot si l'heure et les minutes actuel empiete avec le commencements.
    let first_slot = list_last_time[0];
    if (first_slot.open[0] < now.getHours()) {
      first_slot.open[0] = now.getHours();
    } else if (first_slot.open[0] == now.getHours() && first_slot.open[1] < now.getMinutes()) {
      first_slot.open[1] = now.getMinutes();
    }

    // ###################
    for (let slot of list_last_time) {
      let base_index = 0;
      let end_index = 0
      console.log('------------------')
      console.log(slot);
      for (let tt of LIST_OF_TIME) {
        if (slot.open[0] > tt[0]) { continue }
        if (slot.close[0] < tt[0]) { continue }
        if (slot.open[0] == tt[0] && slot.open[1] > tt[1]) { continue }
        if (slot.close[0] == tt[0] && slot.close[1] < tt[1]) { continue }
        render.push((<option value={tt}>{tt[0] + 'h ' + (tt[1]==0 ? '' : tt[1]) }</option>))
      }
    }
    // -> Rechercher l'heure qui est inférieur à l'heure actuel ensuite il faut faire 15 30 45 00 comme suite logique pour propose un panel intéressants d'heure
    return render;
  }

  render_title() {
    if (this.props.type=='not_has_hour') {
      return (
        <h1>Restaurant fermer choissisez votre heure de commande.</h1>
      );
    }
    return (
      <h1>Choissisez votre heure de commande.</h1>
    );
  }

  onChangeDay = (event) => {

  }

  onChangeTime = (event) => {
    console.log(event);
  }

  next() {
      (window as any).react_history.push('/order/');
  }

  render():JSX.Element {
    this_component = this;
    return (
      <div className="click_and_collect_hour_container">
        {this.render_title()}
        <div className="form-group">
          <select onChange={(event) => this.onChangeDay(event) }
                  className="form-control"
                  style={{width:'90%', margin: 'auto'}}>
            {this.renderDecompteDay()}
          </select>
        </div>
        <div className="form-group">
          <select onChange={(event) => this.onChangeDay(event) }
                  className="form-control"
                  style={{width:'90%', margin: 'auto'}}>
            {this.renderDecompteHour()}
          </select>
        </div>
        <div className="form-group">
          <button type="button"
                  className="form-control"
                  onClick={() => this.next()}
                  style={{width:'90%', margin: 'auto'}}>CONTINUER</button>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state:any) {
  return {
    handdicap_version: state.handdicap_version,
  };
}


function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HourComponent);
