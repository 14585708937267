import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cartItemsSelector } from '../../../selectors';
import { addToCart, editCartItem, goToPath, reduceCart, update_data } from './../../../actions/index';
import { calculate_product, reduce_product_to_cart_list } from './../../../libs/cart.calculator';
import { add_00_in_end, is_electron_browser, staticFile } from './../../../libs/index';

let this_component: any;
let this_component_usual_command: any;
interface Props {
  type_cart_container: any, // cart list or usual_command_cart_list
  cart_list: any
  as_usual_command: any,
  update_data: Function,
  addToCart: Function,
  reduceCart: Function,
}

class CartContainer extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  state: any = {
    'cart_list': [],
    'product_order_list_style': {},
  }

  hold_cart_list_length = 0;

  add_to_cart(event, product: any, number = 1) {
    event.stopPropagation();

    if (this.props.type_cart_container === 'cart_list') {
      this.props.addToCart({ 'product': product, 'number': number });
    } else if (this.props.type_cart_container === 'usual_command_cart_list') {
      product.quantity += number;
      calculate_product(product);
      this.props.update_data('usual_command_cart_list', this.state.cart_list);
    }
  }

  reduce_to_cart(event, product: any, number = 1) {
    event.stopPropagation();

    if (this.props.type_cart_container === 'cart_list') {
      this.props.reduceCart({ 'product': product, 'number': number });
    } else if (this.props.type_cart_container === 'usual_command_cart_list') {
      reduce_product_to_cart_list(product, this.state.cart_list, number);
      this.props.update_data('usual_command_cart_list', this.state.cart_list);
    }
  }

  map_complement(complement: Array<any> | undefined): Array<JSX.Element> {
    if (complement === undefined || complement.length === 0) {
      return;
    }
    let html_complements: any = [];
    let i = 0;
    for (let product of complement) {
      html_complements.push((
        <li key={'cart_complement_product' + i}> {product.quantity !== 1 ? product.quantity : ''} {product.name}</li>
      ));
      i++;
    }
    return [
      (<div className='complement_line'></div>),
      (
        <ul className="complement">
          {html_complements}
        </ul>
      )];
  }

  map_discounts(discounts: Array<any> | undefined): Array<JSX.Element> {
    if (discounts === undefined || discounts.length === 0) {
      return null;
    }

    let html_complements: any = [];
    let i = 0;
    for (let discount of discounts) {
      html_complements.push((
        <li key={'cart_discount_product' + i}> {discount.quantity !== 1 ? discount.quantity : ''} {discount.name}</li>
      ));
      i++;
    }
    return [
      (<div className='complement_line'></div>),
      (
        <ul className="complement">
          {html_complements}
        </ul>
      )];
  }

  cartItemPressed = (event, product: any, index: number) => {
    event.stopPropagation();

    const { editCartItem, goToPath } = this.props as any;
    editCartItem({
      product,
      index
    });

    goToPath({
      action: "popin",
      id: "product_edit"
    });
  }

  map_list(cart_list: Array<any>): JSX.Element {
    let list_mapped: any = [];
    let i = 0;
    for (let product of cart_list.slice(1)) {
      list_mapped.push((
        <div
          onClick={(evt) => this.cartItemPressed(evt, product, i)}
          className="product_in_cart box_shadow_light"
          key={'product_in_cart' + i}
        >
          <div onClick={(event) => this.reduce_to_cart(event, product, product.quantity)} className="close"></div>
          <div style={{
            backgroundImage: 'url("' + staticFile(product.ico) + '")',
          }}
            className="imageContainer"></div>
          <p className="name">{product.name}</p>
          <div className='button_more_and_less'>
            <ul>
              <li onClick={(event) => this.reduce_to_cart(event, product)} className='less'>-</li>
              <li className="quantity"> {product.quantity} </li>
              <li onClick={(event) => this.add_to_cart(event, product)} className='more box_shadow_light'>+</li>
            </ul>
          </div>
          <div className="price"> {add_00_in_end(product.total_price)} €</div>
          {this.map_complement(product.complement)}
          {this.map_discounts(product.discounts)}
        </div>
      ))
      i++;
    }

    if (this.props.type_cart_container === 'cart_list') {
      list_mapped.push((
        <div key={'cart_container_space_end'}
          className="space_in_end">
        </div>
      ))
    }
    return (
      <div>
        {list_mapped}
      </div>
    )
  }

  scroll_to_end(new_cart_list_length: any, hold_cart_list_length: any) {
    // if (!is_electron_browser()) { return; }
    if (new_cart_list_length == hold_cart_list_length) { return; }
    if (new_cart_list_length < hold_cart_list_length) { return; }

    // if(cart_list!==this.state.cart_list) { return; }
    if (this.dom_product_in_cart === undefined) { return; }
    (window as any).product_in_cart = this.dom_product_in_cart;
    setTimeout(() => {
      let that = this;
      // Prendre en compte la différences entre la positions du début et celle de fin
      // Pour que l'éléments n'aille pas trop vite sur de petites durée
      let left = this.dom_product_in_cart.scrollLeft;
      let velocity = that.dom_product_in_cart.scrollWidth - left;
      // TODO > Si jamais il faut ralentir l'animation ont fera ça ici
      let scrollTo = setInterval(function () {
        if (left <= that.dom_product_in_cart.scrollWidth) {
          that.dom_product_in_cart.scrollLeft = left;
          left += 40;
        } else {
          clearInterval(scrollTo);
        }
      }, 20);
    }, 100);
  }

  update_cart_list(cart_list: any) {
    this_component.hold_cart_list_length = this.state.cart_list.length;
    this.setState({
      'cart_list': cart_list,
    });
  }

  define_product_order_list_style() {
    let width = (this.props.type_cart_container === 'usual_command_cart_list') ? ((this.state.cart_list.length - 1) * 272) + 'px' : '1000px';
    if (!is_electron_browser()) {
      width = '250px';
    }

    this.setState({
      product_order_list_style: { width: width },
    })
  }

  render(): JSX.Element {
    this_component = this;
    if (this.props.type_cart_container === 'cart_list') {
      this.state.cart_list = this.props.cart_list;
      (this as any).hold_cart_list_length = this.state.cart_list.length;
      return (
        <div className="product_order_list"
          id={this.props.type_cart_container === 'cart_list' ? 'product_in_cart' : ''}
          key={"cart_container_product_order_list"}>
          <div className={(this.props.type_cart_container === 'usual_command_cart_list') ? 'usual_command_centered' : ''}
            style={{
              width: (this.props.type_cart_container === 'usual_command_cart_list') ? (((this.state.cart_list.length - 1) * 272)) + 'px' : '10px',
              paddingBottom: is_electron_browser() ? '150px' : '15px',
            }}>
            {this.map_list((this.state as any).cart_list)}
          </div>
        </div>
      )
    } else if (this.props.type_cart_container === 'usual_command_cart_list') {
      this.state.cart_list = this.props.as_usual_command;
      this_component_usual_command = this;
      return (
        <div className={(this.props.type_cart_container === 'usual_command_cart_list') ? 'usual_command_centered' : ''}
          key={"cart_container_usual_command_centered"}
          style={{ width: (this.props.type_cart_container === 'usual_command_cart_list') ? (((this.state.cart_list.length - 1) * 272)) + 'px' : '1000px' }}>
          {this.map_list((this.state as any).cart_list)}
        </div>
      );
    }
  }

  dom_product_in_cart: any = undefined;
  componentDidMount() {
    if (this.props.type_cart_container !== 'cart_list') { return; }
    this.dom_product_in_cart = document.getElementById('product_in_cart');
    this.scroll_to_end(this.state.cart_list.length, 0);
  }
}

function mapStateToProps(state: any) {
  const cartItems = cartItemsSelector(state);
  if (this_component !== undefined) {
    this_component.scroll_to_end(cartItems.length, this_component.hold_cart_list_length);
    this_component.update_cart_list(cartItems);
  }

  if (this_component_usual_command !== undefined) {
    this_component_usual_command.update_cart_list(state.usual_command);
  }

  return {
    as_usual_command: state.as_usual_command,
    cart_list: cartItems,
    handdicap_version: state.handdicap_version
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    addToCart: addToCart,
    reduceCart: reduceCart,
    update_data: update_data,
    goToPath: goToPath,
    editCartItem: editCartItem
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
