export function get_first_category() {
  for (let category of (window as any).data.category) {
    if (category.hasOwnProperty('display')) { continue }
    return category;
  }
}

// console.log('####### ALL PRODUCTS ###########');
// console.log((window as any).data.product_list);
export const all_product_categories: any = (window as any).data.product_list;
