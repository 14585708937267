
import { ACTIVE_HANDICAPPED_VERSION, LOGOUT, is_redux_init } from './../actions/index';

export default function (state:any, action:any) {
  let not_handdicaped_version = {
    'activated': false
  };
  if (is_redux_init(action)) {
    return not_handdicaped_version;;
  }

  switch(action.type) {
      case ACTIVE_HANDICAPPED_VERSION:
          return action.payload;
      case LOGOUT:
        return not_handdicaped_version;
      default:
        return state;
    }
}
