import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cartIsEmpty, hasUserAndDeliveryModeSet } from '../../../../selectors';
import { goToPath } from './../../../../actions/index';
import { CONFIGURATION_PAGE } from './../../../../libs/config';
import { is_electron_browser } from './../../../../libs/index';
import PopInBinaryChoiceComponent from './../../../nav/pop_in_binary_choice/pop_in_binary_choice.component';

interface Props {
  goToPath: Function,
  popin_this: any,
}
class SelectPaymentTypeComponent extends React.Component<Props, any> {
  private data: any;

  constructor(props) {
    super(props);
    const { paymentsServices } = (window as any);
    
    this.data = {
      type: 'select_payment_type',
      title_first_part: "COMMENT SOUHAITEZ-",
      title_second_part: "VOUS RÉGLER ?",
      choices: []
    };

    if (paymentsServices.length && paymentsServices.includes('payement_cart')) {
      this.data.choices.push({
        'text': 'CARTE BLEUE',
        'style': {
          'backgroundImage': 'url(/public/img/order/payment_type/bank_card.png)',
        },
        'go_to': { 'action': 'history', 'id': '/order/checkout/credit_card_payment/' },
        'add_to_cart_config': {
          'payment_type': 'bank_card',
        }
      });
    }

    if (paymentsServices.length && paymentsServices.includes('payement_caisse')) {
      this.data.choices.push({
        'text': 'AU COMPTOIR',
        'style': {
          'backgroundImage': 'url(/public/img/order/payment_type/au_comptoire.png)',
        },
        'go_to': { 'action': 'history', 'id': '/order/accepted_payement/' },
        'add_to_cart_config': {
          'payment_type': 'cash',
        }
      });
    }
  }

  componentDidMount(): void {
    const props = this.props as any;

    console.log('User and delivery set: ', props.userAndDeliveryModeSet);
    if (!props.userAndDeliveryModeSet) {
      props.goToPath({
        'action': 'history',
        'id': '/user/'
      });
    } else if (props.cartIsEmpty) {
      props.goToPath({
        'action': 'history',
        'id': '/order/'
      });
    }
  }

  render(): JSX.Element {
    (window as any).react_history = (this.props as any).history;
    if (is_electron_browser() && window.location.pathname === '/order/select_payment_type/') {
      const list_text = {
        'undefined': {
          'title_first_part': "VOTRE TENTATIVE DE PAIEMENT A ÉTÉ REJETÉE !",
          'title_second_part': "CHOISISSEZ DE NOUVEAU VOTRE MODE DE PAIEMENT"
        },
        'accepted_payement': {
          'title_first_part': "VOTRE COMMANDE À LA CAISSE A ÉTÉ REJETÉE !",
          'title_second_part': "CHOISISSEZ DE NOUVEAU VOTRE MODE DE PAIEMENT"
        }
      };
      Object.assign(this.data, list_text[(window as any).command_error]);
      // this.data['title_first_part'] = "VOTRE TENTATIVE DE PAYEMENT À ÉTÉ REJETER !";
      // this.data['title_second_part'] = "CHOISISSEZ UN NOUVEAU VOTRE MODE PAYEMENT";
    }

    // console.log(this.props)
    if (this.data.title_first_part === "COMMENT SOUHAITEZ-" && this.data.title_second_part === "VOUS RÉGLER ?") {
      if (!CONFIGURATION_PAGE.payement_cart) {
        setTimeout(() => {
          (window as any).react_history.push('/order/accepted_payement/')
        }, 10);

        if (this.props.popin_this && this.props.popin_this.onHide) {
          this.props.popin_this.onHide();
        }

        return (<div></div>)
      }

      if (!CONFIGURATION_PAGE.payement_caisse) {
        setTimeout(() => {
          (window as any).react_history.push('/order/checkout/credit_card_payment/')
        });
        if (this.props.popin_this && this.props.popin_this.onHide) {
          this.props.popin_this.onHide();
        }
        return (<div></div>)
      }
    }

    return (
      <div className={window.location.pathname === '/order/select_payment_type/' && is_electron_browser() ? "select_payment_type_container" : ""}>
        <PopInBinaryChoiceComponent popin_this={undefined} type="basic" data={this.data}></PopInBinaryChoiceComponent>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version,
    userAndDeliveryModeSet: hasUserAndDeliveryModeSet(state),
    cartIsEmpty: cartIsEmpty(state)
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentTypeComponent);
