import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './loading-animation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const LottieLoading = () => (
  <Lottie options={defaultOptions}
    height={200}
    width={200}
  />
);
