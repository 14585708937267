import { Action } from 'redux-actions';
import { ActionType, ADD_TO_CART, REDUCE_CART, setCartList } from '../actions';
import { add_product_to_cart_list, calculate_cart, calculate_product, reduce_product_to_cart_list } from '../libs/cart.calculator';
import { has_fidelity_product } from '../libs/fidelity';
import { put, select, takeEvery } from 'redux-saga/effects'

function addDiscount(product, discount) {
  if (product.discounts) {
    product.discounts.push(discount);
  } else {
    product.discounts = [discount];
  }
}

function computeCatalogRuleDiscount(cartList: Array<any>, deliveryMode) {
  let { catalogRules } = (window as any);

  console.log("Catalog rules: ", catalogRules);
  catalogRules = catalogRules || [];
  const matchingRules = catalogRules.filter(rule => rule.mode === deliveryMode);
  if (!matchingRules.length || cartList.length < 2) {
    return cartList;
  }

  // Erase discounts
  const products = cartList.slice(1).map(p => {
    p.discounts = [];
    return calculate_product(p);
  });

  const discountRules = [];
  for (const rule of matchingRules) {
    const ruleProducts = rule.produits;
    let productsFound = products.filter((p) =>
      ruleProducts.includes(`prd${p.id}`)
    );
    
    productsFound.sort((p1, p2) =>
      p2.unit_price < p1.unit_price
        ? 1
        : p2.unit_price > p1.unit_price
        ? -1
        : 0
    );

    const totalQty = productsFound.reduce((sum, product) => sum + product.quantity , 0);
    discountRules.push({
      rule,
      products: productsFound,
      discountQuantity: Math.floor(totalQty / rule.min),
      name: rule.nom
    });
  }

  const discountProducts = [];
  for (const dr of discountRules) {
    if (dr.discountQuantity > 0) {
      let discountRate = 0;
      const { rule } = dr;
      if (rule.valeur.endsWith("%")) {
        discountRate =
          parseInt(rule.valeur.substring(0, rule.valeur.length - 1), 10) / 100;
      } else {
        discountRate = parseInt(rule.valeur, 10) / 100;
      }

      let drQt = dr.discountQuantity;
      for(const p of dr.products) {
        const dsUnitPrice = rule.operation * discountRate * p.unit_price;
        
        if (drQt > 0 && p.quantity >= drQt) {
          const discount = {
            name: dr.name,
            quantity: drQt,
            total_price: dsUnitPrice * drQt,
            unit_price: dsUnitPrice,
            valeur: rule.valeur,
            operation: rule.operation
          };

          // p.complement.push(discount);
          addDiscount(p, discount);
          p.total_price = p.total_price + discount.total_price;
          discountProducts.push(p);
          drQt = 0;
        } else if (drQt > 0) {
          const discount = {
            name: dr.name,
            quantity: p.quantity,
            total_price: -(p.unit_price * p.quantity), //TODO: use % discount
            unit_price: -(p.unit_price), //TODO: use % discount
            valeur: rule.valeur,
            operation: rule.operation
          };

          // p.complement.push(discount);
          addDiscount(p, discount);
          p.total_price = p.total_price + discount.total_price;
          discountProducts.push(p);
          drQt = drQt - p.quantity;
        }
      }
    }
  }

  console.log('Discount products: ', discountRules);

  for(const item of discountProducts) {
    const index = cartList.findIndex(p => p.id === item.id);
    cartList.splice(index, 1, item);
  }

  console.log('Cart list products: ', cartList);

  return cartList;
}

function* addCartItem(action: Action<ActionType>) {
  const state = yield select(rootState => rootState.cart);
  const deliveryMode = yield select(rootState => rootState.user.mode);

  let list = undefined;
  if (action.payload && action.payload.hasOwnProperty('product_list')) {
    for (let p of action.payload.product_list) {
      p = Object.assign({}, p);
      if (p.hasOwnProperty('quantity')) {
        list = add_product_to_cart_list(p, state.list, p.quantity);
      }
    }

    const newList = calculate_cart(computeCatalogRuleDiscount(list || state.list, deliveryMode));
    const newState = { ...state, list: newList };
    console.log('Saga new state: ', newState);
    yield put(setCartList(newState));

    return;
  }

  let product: any = {};
  Object.assign(product, action.payload.product);
  let number = product.quantity;
  if (action.payload.hasOwnProperty('number')) {
    number = action.payload.number;
  }

  if (has_fidelity_product(product)) {
    list = add_product_to_cart_list(product, state.list, number);
    const newList = calculate_cart(computeCatalogRuleDiscount(list, deliveryMode));
    const newState = { ...state, list: newList };
    console.log('Saga new state: ', newState);
    yield put(setCartList(newState));

    return;
  }

  list = add_product_to_cart_list(product, state.list, number);
  const newList = calculate_cart(computeCatalogRuleDiscount(list, deliveryMode));
  const newState = { ...state, list: newList };
  console.log('Saga new state: ', newState);
  yield put(setCartList(newState));
}

function* reduceCartItem(action: Action<ActionType>) {
  const state = yield select(state => state.cart);
  const deliveryMode = yield select(rootState => rootState.user.mode);

  let product = action.payload.product || {};
  const qty: number = action.payload.number || product.quantity;

  if (has_fidelity_product(action.payload.product)) {
    const _items = reduce_product_to_cart_list(action.payload.product, state.list, qty);
    const newList = calculate_cart(computeCatalogRuleDiscount(_items || [], deliveryMode));
    const newState = { ...state, list: newList };
    yield put(setCartList(newState));
  } else {
    Object.assign(product, action.payload.product);
    const _items = reduce_product_to_cart_list(action.payload.product, state.list, action.payload.number);
    const newList = calculate_cart(computeCatalogRuleDiscount(_items || [], deliveryMode));
    const newState = { ...state, list: newList };
    yield put(setCartList(newState));
  }
}

export default function* () {
  yield takeEvery(ADD_TO_CART, addCartItem);
  yield takeEvery(REDUCE_CART, reduceCartItem);
}
