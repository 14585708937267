
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPath, logout } from './../../../actions/index';

let this_component:any;
interface Props {
  goToPath: Function,
  popin_this: any,
}
class CancelOrderComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  logout() {
    (this.props as any).logout({'history': (window as any).react_history});
    this.props.popin_this.onHide();
  }
  render():Array<JSX.Element> {
    this_component = this;
    return [
      (
        <div className="pop_in_content_general cancel_container"
             key={"pop_in_content_generalcancel_container"}>
          <h1>QUITTER ET REVENIR À <br/> L'ACCUEIL ? </h1>
          <div className="container-fluid row justify-content-center">
            <div onClick={() => this.logout()} className="box_shadow_extended button_yellow_on_white">QUITTER</div>
            <div onClick={() => this.props.popin_this.onHide() } className="box_shadow_light button_grey_on_white">RESTER</div>
          </div>
        </div>
      )
    ];
  }
}
function mapStateToProps(state:any) {
    if(this_component) {
    }
    return {
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    logout: logout,
    goToPath: goToPath,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CancelOrderComponent);
