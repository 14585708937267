import { GO_TO_PATH, is_redux_init, UPDATE_DATA, UPDATE_PRODUCT } from './../actions/index';
import { all_product_categories, get_first_category } from './request/product_list';
import { updateProduct } from './utils';

let product_list = [];

function add_category_in_always_product(category) {
  product_list = all_product_categories[category];
  for (let product of product_list) {
    product.category = category
  }

  return product_list;
}

export default function (state: any, action: any) {
  if (is_redux_init(action)) {
    let category = get_first_category()
    if (!category) {
      return [];
    }
    return add_category_in_always_product(category.id);
  }

  switch (action.type) {
    case UPDATE_DATA:
      if (action.payload.for_reducer === 'product_list') {
        return action.payload.data;
      }
      return state;
    case 'RELOAD_DATA_BASE':
      let category = get_first_category();
      if (!category) {
        return [];
      }
      return add_category_in_always_product(category.id);
    case GO_TO_PATH:
      if (action.payload.action !== 'category') { return state; }
      let id = action.payload.id;
      if (!all_product_categories.hasOwnProperty(id)) {
        return product_list;
      }
      // console.log(all_product_categories[id])
      return add_category_in_always_product(id)
    // return (all_product_categories as any)[id];
    case UPDATE_PRODUCT:
      const { payload } = action;
      if (payload) {
        return updateProduct(payload, state);
      }
      return state;
    default:
      return state;
  }
}
