import React from 'react';
import { connect } from 'react-redux';
import Keyboard from 'react-virtual-keyboard';
import { bindActionCreators } from 'redux';
import { goToPath, load_customer, update_data } from './../../../../actions/index';
import { load_as_usual_command } from './../../../../libs/fidelity';
import { is_electron_browser } from './../../../../libs/index';
import CloserTimer from './../../../../libs/time';
import { you_fid_client } from './../../../../libs/you_fid_api';
import HeaderNavigationComponent from './../../../nav/header_navigation/header_navigation.component';

function ValidateEmail(mail: string): boolean {
  CloserTimer.new_action();
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(mail).toLowerCase());
}
function ValidatePhoneNUmber(phone_number: string): boolean {
  CloserTimer.new_action();
  // return (/((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/.test(phone_number));
  return (/^((\+)33|0)[1-9](\d{2}){4}$/.test(phone_number));
}

interface Props {
  goToPath: Function,
  load_customer: Function,
  handdicap_version: any,
  shop: any,
  update_data: Function
}

class IdentificationPageComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  state: any = {
    email: '',
    phone_number: '',
    email_style: {},
    airbag: {},
    'title': "",
    'create_account': false,
    'redirect_url': '/profile/',
    'err_email': false,
    'err_email_message': '',
    'err_phone_number': false,
    'err_phone_message': '',
    is_create_page: (window.location.pathname === "/profile/create/"),
    and_or: 'Et'
  }
  emailText: string = '';
  login_submit_email(data: any) {
    if (ValidateEmail(data)) {
      you_fid_client.login_with_mail(data).then((e) => {
        if (!you_fid_client.status_is_ok(e)) { return; }
        // e.data.qr_code = qr_code;
        load_as_usual_command(e.data.qr_code, this.props.shop.siret, this.props);
        this.props.load_customer(e.data);
        (this.props as any).history.push('/profile/');
      });
    }
  }

  phoneNumberText: string = '';
  login_submit_phone_number(data: any) {
    if (ValidatePhoneNUmber(data)) {
      you_fid_client.find_user(data).then((e) => {
        if (e.data.users.length === 0) { return; }
        let qr_code = e.data.users[0].qr_code;
        you_fid_client.login_with_qr_code(qr_code).then((e) => {
          if (!you_fid_client.status_is_ok(e)) { return; }
          e.data.qr_code = qr_code;
          this.props.load_customer(e.data);
          (this.props as any).history.push('/profile/');
        })
      });
    }
  }

  handleKeyUp(event: any) {
    this.login_onInputChanged(event.target.value);
  }

  login_onInputChanged(data: any) {
    if (this.state.is_create_page) {
      this.state[this.lock_field] = data;
      return;
    }
    if (this.lock_field === 'email') {
      this.login_submit_email(data);
    } else if (this.lock_field === 'phone_number') {
      this.login_submit_phone_number(data);
    }
  }

  submit_create_customer() {
    let qr_code = window.location.hash.slice(1,);
    // -> récupérer la valeur de email et de numéro de téléphone
    let error = {};
    let new_state: any = {};
    if (!ValidateEmail(this.state.email)) {
      Object.assign(error, {
        'err_email': true,
        'err_email_message': 'Votre adresse email est incorrect !',
      });
    } else {
      // Object
      new_state.err_email = false;
    }

    if (!ValidatePhoneNUmber(this.state.phone_number)) {
      Object.assign(error, {
        'err_phone_number': true,
        'err_phone_message': 'Votre numéro de téléphone est inccorect !',
      });
    } else {
      new_state.err_phone_number = false;
    }

    if (Object.keys(error).length !== 0) {
      Object.assign(error, new_state);
      return this.setState(error);
    }
    this.setState({ err_email: false, err_phone_number: false });
    you_fid_client.register_customer({
      qr_code: qr_code,
      email: this.state.email,
      phone: this.state.phone_number
    }).then((e) => {
      if (!you_fid_client.status_is_ok(e)) {
        if (e.data.code === 'INVALID_MAIL') {
          this.setState({
            'err_email': true,
            'err_email_message': "Cet email est déjà utilisé !",
          })
        } else if (e.data.code === 'INVALID_PHONE') {
          this.setState({
            'err_phone_number': true,
            'err_phone_message': "Cet email est déjà utilisé !",
          })
        }
        return;
      }
      e.data.qr_code = qr_code;
      this.props.load_customer(e.data);
      (this.props as any).history.push('/profile/')
      return;
    });
  }

  onSubmitEmail(data: any) {
    if (this.state.is_create_page) {
      return this.submit_create_customer();
    }
    this.setState({
      'err_email': true,
      'err_email_message': 'Votre adresse email est incorrect !',
    })
  }
  onSubmitPhoneNumnber(data: any) {
    if (this.state.is_create_page) {
      return this.submit_create_customer();
    }
    this.setState({
      'err_phone_number': true,
      'err_phone_message': 'Votre numéro de téléphone est inccorect !',
    })
  }

  lock_field: string | undefined = undefined;
  open_keyboard_email() {
    this.lock_field = 'email';
    if (this.props.handdicap_version.activated) {
      this.setState({
        'airbag': { 'height': '13%' },
      })
    }
  }
  open_keyboard_phone_number() {
    this.lock_field = 'phone_number';
    if (this.props.handdicap_version.activated) {
      this.setState({
        'email_style': { 'display': 'none' },
        'airbag': { 'height': '13%' },
      });
    }
  }
  close_keyboard() {
    this.lock_field = undefined;
    if (this.props.handdicap_version.activated) {
      this.setState({
        'email_style': {},
        'airbag': {},
      });
    }
  }
  show_keyboard_email() {
    if (is_electron_browser()) {
      return (

        <Keyboard
          value={this.emailText}
          name='keyboard'
          options={{
            type: "input",
            layout: 'custom',
            display: {
              'meta1': '123', // Diamond
              'space': 'Espace',
              'shift': '&nbsp;',
              'a': 'Valider',
              'bksp': '&nbsp;',
            },
            customLayout: {
              'normal': ['a z e r t y u i o p', 'q s d f g h j k l m', '{shift} w x c v b n \' {bksp}	', '{meta1} {space} @ {a}'],
              'shift': ['A Z E R T Y U I O P', 'Q S D F G H J K L M', '{shift} W X C V B N \' {bksp}', '{meta1} {space} @ {a}'],
              'meta1': ['1 2 3 4 5 6 7 8 9 0 ', '- _ : ; ( ) € & @ "', '$  . , ? ! + # \' {bksp}	', '{meta1}  {space} @ {a}']
            },
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: true,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
          }}
          placeholder="Votre adresse email"
          className="form-control"
          onChange={this.login_onInputChanged.bind(this)}
          onAccepted={this.onSubmitEmail.bind(this)}
          onFocus={() => this.open_keyboard_email()}
          onBlur={() => this.close_keyboard()} />
      )
    } else {
      return (
        <input className="form-control col-9"
          onKeyUp={() => this.handleKeyUp(event)}
          onFocus={() => this.open_keyboard_email()}
          onBlur={() => this.close_keyboard()}
          type="email"
          placeholder="Votre adresse email"
          aria-label="Username"
          aria-describedby="basic-addon1" />
      )
    }
  }

  show_keyboard_phone_number() {
    // alert(this.state.is_create_page)
    if (!this.state.is_create_page) { return; }
    if (is_electron_browser()) {
      return (
        <Keyboard
          value={this.phoneNumberText}
          name='keyboard'
          options={{
            type: "input",
            layout: 'custom',
            customLayout: {
              'normal': ['7 8 9 + ', '4 5 6 -', '1 2 3 0', '{bksp} {a}'],
            },
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: false,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
            display: {
              'a': 'Valider',
              'bksp': '&nbsp;',
            }
          }}
          placeholder="Votre numéro de téléphone"
          onChange={this.login_onInputChanged.bind(this)}
          onAccepted={this.onSubmitPhoneNumnber.bind(this)}
          onFocus={() => this.open_keyboard_phone_number()}
          onBlur={() => this.close_keyboard()} className="form-control" />
      )
    } else {
      return (
        <input className="form-control col-9"
          onKeyUp={() => this.handleKeyUp(event)}
          onFocus={() => this.open_keyboard_phone_number()}
          onBlur={() => this.close_keyboard()}
          type="text"
          placeholder="Votre numéro de téléphone"
          aria-label="Username"
          aria-describedby="basic-addon1" />
      )
    }
  }

  create_login_page() {
    if (window.location.pathname === '/profile/create/') {
      Object.assign(this.state, {
        'title': "Créer compte fidélité !",
        'create_account': true,
        'redirect_url': '/order/'
      });
    }
  }

  render(): JSX.Element {
    this.create_login_page();
    (window as any).react_history = (this.props as any).history;
    return (
      <div className="identification_page_container">
        <div>
          <HeaderNavigationComponent history={(this.props as any).history}></HeaderNavigationComponent>
        </div>
        <div className="div_airbag_20percent small_airbag_5percent" style={this.state.airbag}></div>
        <h1>{this.state.title}</h1>
        <div className="container">
          <h2 style={this.state.email_style} className="text_align_center margin_bottom_5percent">RENSEIGNEZ VOTRE ADRESSE EMAIL</h2>
          <div style={this.state.email_style} className="row justify-content-center margin_bottom_6percent">
            <div className="input-group col-11">
              {this.show_keyboard_email()}
              <div className="input-group-append">
                <div onClick={() => { this.onSubmitEmail(event) }}>Ok !</div>
              </div>
            </div>
            <div className="error_text" style={{ display: this.state.err_email ? 'block' : 'none' }}>{this.state.err_email_message}</div>
          </div>
          <p style={Object.assign({ display: this.state.is_create_page ? 'block' : 'none' }, this.state.email_style)} className="text_align_center margin_bottom_6percent or_line">{this.state.and_or}</p>
          <h2 style={{ display: this.state.is_create_page ? 'block' : 'none' }} className="text_align_center margin_bottom_5percent">RENSEIGNEZ VOTRE NUMÉRO DE TÉLÉPHONE</h2>
          <div style={{ display: this.state.is_create_page ? 'flex' : 'none' }} className="row justify-content-center margin_bottom_6percent">
            <div className="input-group col-11 phone_number">
              {this.show_keyboard_phone_number()}
              <div className="input-group-append">
                <div onClick={() => { this.onSubmitPhoneNumnber(event) }}>Ok !</div>
              </div>
            </div>
            <div style={{ display: this.state.err_phone_number ? 'block' : 'none' }} className="error_text">{this.state.err_phone_message}</div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version,
    shop: state.shop,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    load_customer: load_customer,
    goToPath: goToPath,
    update_data: update_data,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(IdentificationPageComponent);
