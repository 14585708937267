

export const calculate_complement_total = (complement: any) => {
  // -> Déjà il faut les organiser par category
  let category = {};
  for (let product of complement) {
    if (!category.hasOwnProperty(product.category)) {
      category[product.category] = {
        product_list: [],
        go_to: product.go_to,
      };
    }
    product = Object.assign({}, product);
    category[product.category].product_list.push(product);
  }

  let menu_unit_price = 0;
  for (let key in category) {
    let num_free_choice = category[key].go_to ? category[key].go_to.num_free_choice || 0 : 0;
    let product_list = category[key].product_list;
    for (let product of product_list) {
      if (product_list.length === 1) {
        menu_unit_price += (product.unit_price * product.quantity) - (product.unit_price * num_free_choice);
        break;
      }
      // -> Maintenant réaliser la même opérations sur plusieurs lignes
      if (num_free_choice > 0) {
        if (product.quantity >= num_free_choice) {
          product.quantity -= num_free_choice;
          num_free_choice = 0;
        } else {
          num_free_choice -= product.quantity;
          product.quantity = 0;
        }
      }
      menu_unit_price += (product.unit_price * product.quantity);
    }
  }
  return menu_unit_price;
}

export const calculate_product = (product: any) => {
  let totalDiscount = 0;
  if (product.discounts && product.discounts.length) {
    totalDiscount = product.discounts.reduce((total, discount) => total + discount.total_price, totalDiscount);
  }

  // console.log(store.getState())
  if (product.hasOwnProperty('complement') && product.complement.length !== 0) {
    product.total_price = calculate_complement_total(product.complement);
    product.total_price = (product.total_price * product.quantity) + (product.unit_price * product.quantity) + totalDiscount
    return product;
  }

  product.total_price = (product.quantity * product.unit_price) + totalDiscount;
  return product;
}

export const calculate_cart = (cart_list: any, for_type = 'cart_list') => {

  let cart_config = {
    'cart_config': true,
    'total_price': 0,
    'for_type': for_type,
    'product_quantity': 0,
    'open': true,
    'qr_code': '',
  };

  if (cart_list.length === 0) {
    cart_list.push(cart_config);
  } else if (cart_list[0].hasOwnProperty('cart_config')) {
    Object.assign(cart_list[0], cart_config);
  } else {
    cart_list.unshift(Object.assign({}, cart_config));
  }

  for (let product of cart_list.slice(1, 1000)) {
    cart_list[0].product_quantity += product.quantity;
    product = calculate_product(product);
    cart_list[0].total_price += product.total_price;
  }

  return [...cart_list];
}

// VERIFIER que le product complement est égal aux produit complement passer
export const product_complement_is_egal_to_product_complement = (product_1: any, product_2: any) => {
  if (!product_1.hasOwnProperty('complement')) { product_1.complement = []; }
  if (!product_2.hasOwnProperty('complement')) { product_2.complement = []; }

  if (product_1.complement.length !== product_2.complement.length) { return false; }
  else if (product_1.length == 0) { return true; }

  let i = 0;
  for (let complement_product of product_1.complement) {
    if (complement_product.id == product_2.complement[i].id) {
      i++;
      continue;
    } else {
      return false;
    }
  }
  return true;
}

export const find_index_to_cart_list = (product: any, cart_list: Array<any>) => {
  let list_elem = [];
  let i = 0;
  for (let cart_product of cart_list) {
    if (cart_product.id === product.id && cart_product.unit_price === product.unit_price) {
      list_elem.push(i);
    }
    i++;
  }
  for (let product_index of list_elem) {
    if (product_complement_is_egal_to_product_complement(cart_list[product_index], product)) {
      return product_index;
    }
  }
  return -1;
}

export const find_to_cart_list = (product: any, cart_list: Array<any>) => {
  let index = find_index_to_cart_list(product, cart_list);
  if (index == -1) { return -1 }
  return cart_list[index];
}

export const reduce_product_to_cart_list = (product, cart_list: Array<any>, reduce_to: any = -1) => {
  let product_index = find_index_to_cart_list(product, cart_list);
  if (product_index === -1) { return false }
  let cart_product = cart_list[product_index];
  if (cart_product.quantity - reduce_to <= 0) {
    // TODO > delete the element in cart list
    cart_list.splice(product_index, 1);
  } else {
    cart_product.quantity -= reduce_to;
  }

  return [...cart_list];
}

export const add_product_to_cart_list = (product: any, cart_list: Array<any>, number: any = null) => {
  let product_index = find_index_to_cart_list(product, cart_list);
  console.log(product_index)
  if (product_index === -1) {
    // cart_list.push(product);
    return [...cart_list, product];
  }

  let cart_product = cart_list[product_index];
  cart_product.quantity += number;
  return [...cart_list];
}

// -> Ajoute les données necessaires pour que l'éléments fonctionne correctements.
export const add_more_configuration_information_to_cart = (cart_list, client) => {
  Object.assign(cart_list[0], {
    qr_code: client.qr_code,
    // qr_code: '500245689',
  });
}
