import axios from 'axios';
import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reloadDataBase } from './../../../actions/index';
import HourComponent from './../hour/hour.component';

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let ENTERPRISE_DATA_LOADED = {

}

interface Props {
  shop: any,
  reloadDataBase: Function
}
class ClickAndCollectAdressComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    (window as any).data.click_and_collect = {};
  }
  state: any = {
    not_has_hour: false,
    adress: {
      coordinate: '',
      postcode: undefined,
      city_postal_code: '',
      numero_de_rue: '',
      rue: '',
    },
    search: {
      city_postal_code: [],
      numero_de_rue: [],
      rue: [],
    },
    restaurant: {
      'loaded': false,
      'result_number': 0,
    },
    save_information: false,
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  load_express_server_data(enterpriseList) {
    for (let enterprise of enterpriseList) {
      axios.get('http://' + enterprise.url_load_data + '/load_data/').then((e) => {
        ENTERPRISE_DATA_LOADED[enterprise.siret] = e.data;
      });
    }
  }

  load_restaurant_to_proximity() {
    this.state.adress.siret = this.props.shop.siret;
    axios.post('https://admin-borne.splash360.fr/click_and_collect/geolocate_restaurant/', this.state.adress).then((e) => {
      this.load_express_server_data(e.data.result);
      this.setState({
        restaurant: e.data
      });
    }).catch(() => {
      this.setState({
        restaurant: {
          'loaded': true,
          'result_number': 0,
        }
      });
    });
  }

  clickSearchResult(line, field) {
    console.log(line, field);
    this.state.adress[field] = line.title;
    this.state.search[field] = [];
    if (field === 'city_postal_code') {
      this.state.adress.postcode = line.value;
    }

    if (field === 'rue') {
      console.log(line);
      this.state.adress.coordinate = line.geometry.coordinates;
      this.load_restaurant_to_proximity();
    }

    this.setState({
      search: this.state.search,
      adress: this.state.adress,
    });

    this.update_input();
  }

  renderSearch(field) {
    let values = this.state.search[field];
    if (values.length === 0) { return (<></>); }
    let rendered = [];
    for (let line of values) {
      rendered.push((<li onClick={() => this.clickSearchResult(line, field)}>{line.title} {line.value}</li>));
    }
    return (
      <div className="input_result">
        <ul>
          {rendered}
        </ul>
      </div>
    );
  }

  use_cookie() {
    if (getCookie('click_and_collect') == undefined) { return; }
    setTimeout(() => {
      let adress = JSON.parse(getCookie('click_and_collect'));
      let input_city = $('.input_city');
      let input_street_name = $('.input_street_name');
      this.setState({
        'adress': adress
      });
      $(input_city).val(adress.city_postal_code);
      $(input_street_name).val(adress.rue);
      this.load_restaurant_to_proximity();
    }, 0);
  }

  save_in_cookie() {
    if (!this.state.save_information) { return false; }
    setCookie("click_and_collect", JSON.stringify(this.state.adress), 365)
  }

  transform_slot(slot) {
    let open = slot[0].split('h');
    let close = slot[1].split('h');
    open = [isNaN(parseInt(open[0])) ? 0 : parseInt(open[0]), isNaN(parseInt(open[1])) ? 0 : parseInt(open[1])];
    close = [isNaN(parseInt(close[0])) ? 0 : parseInt(close[0]), isNaN(parseInt(close[1])) ? 0 : parseInt(close[1])];

    let open_and_close = {
      'open': open,
      'close': close,
    };
    return open_and_close;
  }

  generate_hour(enterprise) {
    let calendar = enterprise.calendar;
    let week_day_list = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    console.log(week_day_list[new Date().getDay()])
    console.log(new Date().getDay());

    let week_day = week_day_list[new Date().getDay()];
    // -> Récupérer les données du jours de la semaines et ensuite renvoyer vérifier que cette éléments n'est pas vide
    let unformated_hours = calendar[week_day].split(';');
    if (unformated_hours[0].length == 0) { return undefined; }

    let hours = [];
    for (let hour of unformated_hours) {
      console.log('#'.repeat(50));
      console.log(hour);
      let slot = hour.split('->');
      hours.push(this.transform_slot(slot));
    }
    return hours;
  }

  is_out_of_good_hour(hour, minutes, hours) {
    for (let time of hours) {
      if (time.open[0] <= hour && time.open[1] <= minutes && time.close[0] > hour) {
        return true;
      }
    }
    return false;
  }

  load_restaurant_data() {
    console.log('load the resta²urants data');
  }

  realizeOperationAfterClickInEnterprise(enterprise) {
    this.save_in_cookie();
    let now = new Date();
    let hour = now.getHours();
    let minutes = now.getMinutes();
    let hours = this.generate_hour(enterprise);
    (window as any).data = ENTERPRISE_DATA_LOADED[enterprise.siret];
    // LOCAL_SERVER(`http://${enterprise.url_load_data}`);
    (window as any).data['server_config'] = {
      "APP_TYPE": "CLICK_AND_COLLECT"
    };

    this.props.reloadDataBase();

    // -> Ouvrir ou fermer ce genre d'éléments à la vu du public.?
    for (let line_enterprise of this.state.restaurant.result) {
      line_enterprise.not_has_calendar = false;
      line_enterprise.opened = false;
    }
    // -> Lorsque l'utilisateur tombe sur un jours ou aucun horaires n'est configurer alors à ce moment la il est bon de l'indiquer
    if (hours == undefined) {
      enterprise.not_has_calendar = true;
      this.setState({
        not_has_hour: this.state.not_has_hour
      })
      return false;
    }
    enterprise.opened = true;
    if (this.is_out_of_good_hour(hour, minutes, hours)) {
      if (this.state.plus_tard) {
        this.setState({
          not_has_hour: true
        });
        return;
      }

      setTimeout(() => {
        (window as any).react_history.push('/order/');
      }, 100);

    } else {
      this.setState({
        plus_tard: false,
        not_has_hour: true,
      });
      // (window as any).react_history.push('/click_and_collect/set_hours/');
    }
  }

  clickInRestaurant(enterprise) {
    let waitLoadData = setInterval(() => {
      if (ENTERPRISE_DATA_LOADED[enterprise.siret] != undefined) {
        clearInterval(waitLoadData);
        this.realizeOperationAfterClickInEnterprise(enterprise);
      }
      console.log('wait data');
    }, 10);
  }

  renderNotASRestaurant(enterprise) {
    if (!enterprise.not_has_calendar) { return (<></>) }
    return (
      <>
        <h1 style={{
          'fontSize': '23px',
          'marginTop': '12px'
        }}>Ce restaurant est fermer pour aujourd'hui</h1>
      </>
    );
  }

  renderRestaurant() {
    if (!this.state.restaurant.loaded) { return (<></>); }
    if (this.state.restaurant.result_number === 0) {
      return (
        <>
          <div className="not_found">
            <h1>Aucun résultat</h1>
          </div>
        </>
      );
    }

    let rendered = [];
    for (let enterprise of this.state.restaurant.result) {
      rendered.push((
        <>
          <div onClick={() => this.clickInRestaurant(enterprise)} className="restaurant">
            <p className="title">{enterprise.name}</p>
            <p className="adress">{enterprise.adress}</p>
          </div>
          {this.renderHourComponent(enterprise)}
          {this.renderNotASRestaurant(enterprise)}
        </>
      ))
    }

    return (
      <>
        <div className="restaurant_container">
          {rendered}
        </div>
      </>
    )
  }

  renderHourComponent(enterprise) {
    if (!enterprise.opened) {
      return;
    }
    if (!this.state.not_has_hour) {
      return (<></>);
    }
    return (<HourComponent type={this.state.plus_tard ? "plus_tard" : "not_has_hour"} enterprise={enterprise} />);
  }

  selectTypeCommand(value) {
    this.setState({
      plus_tard: value,
      not_has_hour: value,
    });
  }

  render(): JSX.Element {
    // this_component = this;
    (window as any).react_history = (this.props as any).history;
    return (
      <div className="click_and_collect_adress_container">
        <form onSubmit={() => console.log('')}>
          <h1>QUAND SOUHAITEZ-VOUS COMMANDER ?</h1>
          <ul className="button_timeout">
            <li><button
              type="button"
              onClick={() => this.selectTypeCommand(false)}
              className={this.state.plus_tard ? '' : 'selected'}>Maintenant</button></li>
            <li><button
              type="button"
              onClick={() => this.selectTypeCommand(true)}
              className={this.state.plus_tard ? 'selected' : ''}>Plus tard</button></li>
          </ul>
          <div className="form-group form_group_city">
            <label>VOTRE VILLE OU CODE POSTAL</label>
            <input type="text" className="form-control input_city" />
            {this.renderSearch('city_postal_code')}
          </div>

          <div className="form-group form_group_input_street_name"
            style={{ 'display': this.state.adress.postcode !== undefined ? 'block' : 'none' }}>
            <label>ADRESSE</label>
            <input type="text" className="form-control input_street_name" />
            {this.renderSearch('rue')}
          </div>
          <p> les instructions de livraison (étage, digicode, etc...) pourront être ajouté lors de la validation de votre commande </p>
          <p>
            <input onClick={() => this.state.save_information = !this.state.save_information} type="checkbox" /> SE SOUVENIR DE MES INFORMATIONS
          </p>

          {this.renderRestaurant()}
        </form>
      </div>
    );
  }

  // {this.renderHourComponent()}
  update_input() {
    let input_city = $('.input_city');
    let input_street_name = $('.input_street_name');
    input_city.val(this.state.adress.city_postal_code);
    input_street_name.val(this.state.adress.rue);
  }

  componentDidMount() {
    $(document).ready(() => {
      this.use_cookie();

      let input_city = $('.input_city');
      let input_street_name = $('.input_street_name');
      input_city.keyup(() => {
        axios.get('https://vicopo.selfbuild.fr/cherche/' + input_city.val()).then((e) => {
          let values = []
          for (let value of e.data.cities) {
            values.push({
              'value': value.code,
              'title': value.city,
            });
          }

          // ---> réorgaaniser les éléments selon la taile
          let new_order_list = {};
          let i = 0;
          for (let line of values) {
            if (!new_order_list.hasOwnProperty(line.title.length)) {
              new_order_list[line.title.length] = [];
            }
            new_order_list[line.title.length].push(i);
            i += 1;
          }

          let new_values = [];
          for (let key in new_order_list) {
            for (let index of new_order_list[key]) {
              new_values.push(values[index]);
            }
          }
          this.state.search.city_postal_code = new_values
          this.setState({
            search: this.state.search
          });
        });
      });
      input_street_name.keyup(() => {
        let adress: any = input_street_name.val();
        adress = adress.split(' ').join('+');
        axios.get('https://api-adresse.data.gouv.fr/search/?q=' + adress + '&postcode=' + this.state.adress.postcode).then((e) => {
          let list = []
          for (let line of e.data.features) {
            list.push({
              'value': '',
              'title': line.properties.label,
              'geometry': line.geometry,
            })
          }
          this.state.search.rue = list;
          this.setState({
            search: this.state.search
          });
        });
      });
    });
  }
}


function mapStateToProps(state: any) {
  return {
    shop: state.shop,
  };
}


function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    reloadDataBase
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClickAndCollectAdressComponent);


// x.
// x.
// x.
// x.
// x.
// x.
// x.
