import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, goToPath } from './../../../actions/index';
import { bindActionCreators } from 'redux';

let this_component:any;
interface Props {
  history: any,
  logout: Function,
  goToPath: Function,
  authentification:any,
}
class HeaderNavigationComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
    this.state = {
      'authentification': {},
    }
  }

  state: any = {
    authentification: {},
  }

  go_to_home(props:any) {
    this.props.goToPath({
      'action': 'popin',
      'id': 'cancel_order',
      'from': 'HeaderNavigationComponent',
    });
  }

  go_to_profile(props:any) {
    this.props.goToPath({
      'action': 'history',
      'id': "/profile/",
      'from': 'HeaderNavigationComponent',
    });
  }

  my_profile_button(authentification:any):JSX.Element|void {
    let href = window.location.href.split('/')
    if (href[href.length-2] === 'profile') { return; }

    if(authentification.authenticated) {
      return (
        <div className="little_text box_shadow go_to_profile" onClick={() => this.go_to_profile(this.props)}>Mon compte</div>
      )
    }
  }

  render():JSX.Element {
    this_component = this;
    this.state.authentification = this.props.authentification;
    return (
      <div className="fluid-container header_navigation_container">
        <div className="little_text box_shadow go_to_home" onClick={() => this.go_to_home(this.props)}>ACCUEIL</div>
        {this.my_profile_button(this.state.authentification)}
      </div>
    );
  }

  componentDidMount() {}
}

function mapStateToProps(state:any) {
    if(this_component) {
      setTimeout(() => {
        this_component.setState({
          'authentification': state.authentification,
        });
      }, 100);
    }
    return {
      'authentification': state.authentification,
    };
}
function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    logout: logout,
    goToPath: goToPath,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavigationComponent);
