import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { activeHandicappedVersion, update_data, update_product } from './actions/index';
import ClickAndCollectAdressComponent from './components/click_and_collect/adress/adress.component';
import FirstPageComponent from './components/click_and_collect/first_page/first_page.component';
import HourComponent from './components/click_and_collect/hour/hour.component';
import FirstRunConfigurationComponent from './components/config/first_run_configuration/first_run_configuration.component';
import HasFidelityCardComponent from './components/fidelity/has_fidelity_card/has_fidelity_card.component';
import ScanFidelityCardComponent from './components/fidelity/scan_fidelity_card/scan_fidelity_card.component';
import HomeComponent from './components/home/home.component';
import AddedToCartComponent from './components/nav/added_to_cart/added_to_cart.component';
// import AvisComponentComponent from './components/nav/avis_component/avis_component.component';
import MaintenanceComponent from './components/nav/maintenance_component/maintenance_component.component';
import PopInComponent from './components/nav/pop_in/pop_in.component';
import TableNumberComponentComponent from './components/nav/table_number_component/table_number_component.component';
import { CartConfirm } from './components/order/cart_confirm/cart_confirm.component';
import AcceptedPaymentComponent from './components/order/checkout/accepted_payement/accepted_payement.component';
import CommandTypeComponent from './components/order/checkout/command_type/command_type.component';
import CreditCardPaymentComponent from './components/order/checkout/credit_card_payment/credit_card_payment.component';
import SelectPaymentTypeComponent from './components/order/checkout/select_payment_type/select_payment_type.component';
import SuggestionComponent from './components/order/checkout/suggestion/suggestion.component';
import TpaErrorComponent from './components/order/checkout/tpa_error/tpa_error.component';
import CommandIsSavedComponentComponent from './components/order/command_is_saved_component/command_is_saved_component.component';
import MyCommandComponentComponent from './components/order/my_command_component/my_command_component.component';
import OrderComponent from './components/order/order.component';
import OpenedProductComponent from './components/order/product_all_list/opened_product/opened_product.component';
import UserComponent from './components/user';
import IdentificationModeComponent from './components/users/auth/identification_mode/identification_mode.component';
import IdentificationPageComponent from './components/users/auth/identification_page/identification_page.component';
import ProfileComponent from './components/users/profile/profile.component';
import { is_electron_browser, staticFile } from './libs/index';
import { IMAGE_LOADER } from './libs/load_image';
import { you_fid_client } from './libs/you_fid_api';

let this_component: any;
class App extends React.Component {
  private connection

  constructor(props: any) {
    super(props);
    this.load_youfid_api();
    IMAGE_LOADER();
    console.log('Init WSocket client ...');
    this.connection = new WebSocket(`${window.location.protocol === 'http:' ? 'ws' : 'wss'}://${window.location.hostname}:${window.location.port}`);
    console.log('WSocket client inited: ', this.connection);
    this.connection.onopen = (e) => {
      console.log("Connection established!");
    };

    // callback messages
    this.connection.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      console.log("Message DATA received!! ", data);

      // Update product in redux state and window.data
      props.dispatchAction(data);
    };

    // Closed window
    this.connection.onclose = (e) => {
      console.log("Connection closed!", e);
    };

    // Error window
    this.connection.onerror = (e) => {
      console.log("Connection error!", e);
    };
  }

  // -------------------------------------------- DEFINE THE VITAL DATA
  load_youfid_api() {
    you_fid_client.merchant_login('', '').then((e) => {
      you_fid_client.merchant_detail().then((e) => {
        (this.props as any).update_data('shop.init', e.data);
        // this.test_youfid_api();
      });
    });
    // setInterval(() => { this.test_youfid_api(); }, 1000)
  }

  test_youfid_api() {
    you_fid_client.merchant_login('', '').then((e) => {
      console.log(e);
    });
    // you_fid_client.merchant_detail().then((e) => {
    //   console.log(e.data);
    //
    // });


    // you_fid_client.register_customer({'qr_code': '81785203', 'email': 'david.beaulan00@gmail.com', 'phone': '0770636173'}).then((e) => {
    //   console.log(e.data)
    // })
    // setInterval(() => {
    //   you_fid_client.merchant_detail().then((e) => {
    //     // console.log('merchant_detail: ', e.data)
    //     // console.log('merchant_detail: ', JSON.stringify(e.data))
    //     // you_fid_client.login_with_qr_code('12345678').then((e) => {
    //     //   console.log('login_with_qr_code',  e.data);
    //     //   console.log('login_with_qr_code',  JSON.stringify(e.data));
    //     // })
    //   });
    // }, 3000)

    // you_fid_client.login_with_qr_code('81785203').then((e) => {
    //   console.log(e.data);
    //   console.log(JSON.stringify(e.data));
    // });
  }

  // -------------------------------------------- APP RENDER
  state: any = {
    real_size_screen: {
      'width': 1080,
      'height': 1920,
    },
    screen_height: 1920 + 'px',
    airbag_style: {},
  };
  fullheight() {
    this.setState({
      'screen_height': this.state.real_size_screen.height + 'px'
    });
  }

  adapt_screen(handdicap_version: any): void {
    let airbag_style = {};
    let screen_height = '1920px';
    if (handdicap_version.activated) {
      airbag_style = {
        height: '848px',
      };
      screen_height = 1920 - 848 + 'px';
    } else {
      airbag_style = {
        height: '0px',
        backgroundColor: 'blue',
      };
      screen_height = 1920 + 'px';
    }

    this.setState({
      'airbag_style': airbag_style,
      'screen_height': screen_height,
      handdicap_version,
    });
  }

  render() {
    (window as any).react_history = (this.props as any).history;
    if (!is_electron_browser()) {
      // Modifier la hauteur de l'élément
      var viewportmeta = document.querySelector('meta[name="viewport"]');
      (viewportmeta as any).content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
      this.updateDimensions(false);
    }
    this.state.handdicap_version = (this.props as any).handdicap_version;
    this_component = this;
    // console.log(this.state.airbag_style)
    return (
      <div id="app-wrapper">
        <div style={{ display: 'none' }}>
          <img src={staticFile("/public/img/headers/header1052x256.jpg")} />
        </div>
        <div style={this.state.airbag_style}>
          <div className="big-header">
            <div className={"closeModePmr " + (Object.keys(this.state.airbag_style).length === 0 ? 'closed' : 'opened')}
              onClick={() => (this.props as any).activeHandicappedVersion({ 'activated': false })}></div>
          </div>
        </div>
        <PopInComponent></PopInComponent>
        <AddedToCartComponent></AddedToCartComponent>
        <Router>

          <div className={[
            'fluid-container',
            'app_global_container',
            this.state.handdicap_version.activated ? 'handicap_mode_activated' : 'handicap_mode_deactivate',
            `path_name${window.location.pathname.replace(/\//g, '_')}`.slice(0, -1)
          ].join(' ')}
          >
            <Switch>
              <Route exact path="/" component={HomeComponent} />
              <Route exact path="/first_run_configuration/" component={FirstRunConfigurationComponent} />

              <Route exact path="/users/auth/identification_mode/" component={IdentificationModeComponent} />
              <Route exact path="/users/auth/identification_page/" component={IdentificationPageComponent} />

              <Route exact path="/fidelity/has_fidelity_card/" component={HasFidelityCardComponent} />
              <Route exact path="/fidelity/scan_fidelity_card/" component={ScanFidelityCardComponent} />

              <Route exact path="/profile/create/" component={IdentificationPageComponent} />
              <Route exact path="/profile/" component={ProfileComponent} />
              <Route exact path="/user" component={UserComponent} />
              <Route exact path="/order/" component={OrderComponent} />
              <Route exact path="/order/select_payment_type/" component={SelectPaymentTypeComponent} />
              <Route exact path="/order/command_type/" component={CommandTypeComponent} />

              <Route exact path="/order/checkout/credit_card_payment/" component={CreditCardPaymentComponent} />
              <Route exact path="/order/accepted_payement/" component={AcceptedPaymentComponent} />
              <Route exact path="/order/suggestion/" component={SuggestionComponent} />
              <Route exact path="/order/cart_confirm/" component={CartConfirm} />
              <Route exact path="/tpa_error/" component={TpaErrorComponent} />
              <Route exact path="/maintenance/" component={MaintenanceComponent} />

              <Route exact path="/table/number/" component={TableNumberComponentComponent} />
              {/* <Route exact path="/avis/votre_avis/" component={AvisComponentComponent} /> */}
              <Route exact path="/order/my_command/" component={MyCommandComponentComponent} />
              <Route exact path="/order/command_saved/" component={MyCommandComponentComponent} />
              <Route exact path="/order/command_is_saved/" component={CommandIsSavedComponentComponent} />
              <Route exact path="/product_all_list/opened_product/" component={OpenedProductComponent} />

              <Route exact path="/click_and_collect/first_page/" component={FirstPageComponent} />
              <Route exact path="/click_and_collect/adress/" component={ClickAndCollectAdressComponent} />
              <Route exact path="/click_and_collect/set_hours/" component={HourComponent} />

              <Route render={() => (<div> Sorry, this page does not exist. </div>)} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }

  updateDimensions(withSetState = false) {
    // if (is_electron_browser()) { return; }
    let height = window.innerHeight + 'px';
    if (window.location.pathname === '/profile/') {
      height = window.innerHeight + 'px';
    }

    if (withSetState) {
      this.setState({
        screen_height: height,
      })
    } else {
      this.state.screen_height = height;
    }
  }

  componentDidMount() {
    this.updateDimensions(true);
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
}
function mapStateToProps(state: any) {
  if (this_component) {
    this_component.adapt_screen(state.handdicap_version);
  }
  return {
    handdicap_version: state.handdicap_version,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({
      update_data,
      activeHandicappedVersion,
      update_product,
    }, dispatch),
    dispatchAction: (action) => dispatch(action)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
