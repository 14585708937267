import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

let this_component:any;
interface Props { }
class TpaErrorComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  render():Array<JSX.Element> {
    this_component = this;
    return [
      (
        <div key={'accepted_payement_container'}
            className="container-fluid accepted_payement_container">
          <div className={(this.props as any).handdicap_version.activated ? "div_airbag_10percent": "div_airbag_20percent"}></div>
          <div className="container">
            <div className='row justify-content-center'>
              <h2 className="col-12 text_align_center">VOTRE TENTATIVE DE PAYEMENT À ÉTÉ REJETER !</h2>
              <p className="sub_title">CHOISISSEZ DE NOUVEAU VOTRE MODE PAYEMENT</p>
            </div>

          </div>
        </div>
      )
    ];
  }
}
function mapStateToProps(state:any) {
    if(this_component!==undefined) { }
    return {
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    // goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TpaErrorComponent);
