import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

let this_component: any;
interface Props {
  added_to_cart: any,
}
class AddedToCartComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  state: any = {
    'show': false,
  }

  onClose = () => {
    console.log("Modal close");
  }

  map_response(added_to_cart: any): JSX.Element {
    if (this.state.show) {
      return (
        <Modal
          onHide={this.onClose}
          size="sm"
          show={this.state.show}
          className="added_to_cart_container"
          aria-labelledby="example-modal-sizes-title-sm">
          <Modal.Body>
            Ajouté
            </Modal.Body>
        </Modal>
      );
    } else {
      return (<div></div>);
    }
  }
  open_and_close(added_to_cart: any) {
    try {
      if (added_to_cart.type === 'opened') {
        this_component.setState({ 'show': true });
        setTimeout(() => {
          if (this_component.state.show) {
            this_component.setState({ 'show': false })
          }
        }, 300);
      }
    } catch (e) { }
  }
  render(): JSX.Element {
    this_component = this;

    let res = this.map_response((this.props as any).added_to_cart);
    return (
      <div>
        {res}
      </div>
    );
  }
  componentDidCatch(error, info) {

  }
}
function mapStateToProps(state: any) {
  if (this_component !== undefined) {
    this_component.open_and_close(state.added_to_cart);
  }
  return {
    added_to_cart: state.added_to_cart
  };
}
export default connect(mapStateToProps)(AddedToCartComponent);
