import * as lod from "lodash";
import { createSelector } from "reselect";

export const cartStateSelector = (state) => state.cart;

export const cartItemsSelector = createSelector(
  cartStateSelector,
  (state) => state.list
);

export const editedCartItemSelector = createSelector(
  cartStateSelector,
  (state) => state.edited
);

export const cartCategoryMapper = createSelector(cartItemsSelector, (items) => {
  const config = items[0];
  const { data } = window as any;
  const { category: categoriesList } = data;

  const categories = [];
  for (let i = 1; i < items.length; i++) {
    const item = items[i];
    const found = categoriesList.filter((cat) => cat.id === item.category);
    let category;
    if (found && found.length) {
      category = {
        id: found[0].id,
        name: found[0].name,
      };
    } else {
      category = {
        id: 0,
        name: "Suggestion",
      };
    }

    const idx = categories.map((cat) => cat.id).indexOf(category.id);
    if (idx === -1) {
      categories.push({
        ...category,
        items: [item],
      });
    } else {
      categories[idx].items.push(item);
    }
  }

  return {
    config,
    categories,
  };
});

export const cartIsEmpty = createSelector(
  cartItemsSelector,
  (list) => list && list.length <= 1
);

export const userSelector = (state) => state.user;

export const hasUserAndDeliveryModeSet = createSelector(
  userSelector,
  (user) => {
    if (!user) return false;

    const cncMode = (window as any).cncMode;

    if (cncMode === 'onsite') {
      const userSet =
        !lod.isEmpty(user.prenom) &&
        !lod.isEmpty(user.nom) &&
        !lod.isEmpty(user.bipper);

      return userSet;
    } else {
      const userSet =
        !lod.isEmpty(user.prenom) &&
        !lod.isEmpty(user.nom) &&
        !lod.isEmpty(user.telephone) &&
        !lod.isElement(user.email) &&
        !lod.isEmpty(user.mode);

      if (!userSet) {
        return false;
      }

      const deliveryServices = (window as any).deliveryServices || [];
      if (!deliveryServices.indexOf(user.moe)) {
        return false;
      }

      if (user.mode !== "livraison") {
        return true;
      } else {
        return (
          !lod.isEmpty(user.adresse) &&
          !lod.isEmpty(user.codepostal) &&
          !lod.isEmpty(user.ville)
        );
      }
    }
  }
);

export const menuListSelector = (state: any) => state.menu_list;

export const selectedMenu = (state: any) => state.go_to;

export const subCategoriesSelector = createSelector(
  menuListSelector,
  selectedMenu,  
  (list, menu) => {
    if (menu && menu.has_children) {
      return list.filter(m => m.parent_id === menu.id);
    }

    return [];
  }
);
