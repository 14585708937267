


import { is_redux_init, UPDATE_DATA } from './../actions/index';
import { calculate_cart } from './../libs/cart.calculator';


export default function (state: any, action: any) {
  if (is_redux_init(action)) { return calculate_cart([], 'usual_command_cart_list') }
  switch (action.type) {
    case UPDATE_DATA:
      if (action.payload.for_reducer === 'usual_command_cart_list') {
        // console.log(action.payload)
        // console.log(calculate_cart(action.payload.data, 'usual_command_cart_list'));
        const newState = calculate_cart(action.payload.data, 'usual_command_cart_list');
        console.log('as-usual-reducer new state: ', newState);
        return newState;
      }
      return calculate_cart(state, 'usual_command_cart_list');
    default:
      return state;
  }
}
