

class CloserTimerClass {
  constructor() { }
  timeout = undefined;
  new_action() {
    if (this.timeout !== undefined) {
      clearTimeout(this.timeout);
    }

    const waitTime = (window as any).prod ? 60 * 1000 : 360 * 1000;

    this.timeout = setTimeout(() => {
      if (window.location.pathname === '/' || window.location.pathname === '/order/accepted_payement/') { return; }
      // -> Ne pas afficher cette popin à un endroit qui n'est pas adapter.
      (window as any).components.TimerPopinComponent?.open();
    }, waitTime);
  }

  block_timer() {
    clearTimeout(this.timeout);
  }
}

// -> Rétablir le code avec le gestionnaires de cliques.
window.onclick = function () {
  console.log('*** onclick *** ')
  CloserTimer.new_action();
  window.ontouchmove = function () {
    console.log('*** touchmove *** ')
    CloserTimer.new_action();
  }
}

setInterval(() => {
  window.ontouchmove = function () {
    console.log('*** touchmove *** ')
    CloserTimer.new_action();
  }
}, 1 * 1000);

/*
action: "popin"
closed: true
id: "suggestion"
*/

// document.addEventListener('touchstart', () => {
//   console.log('touchstart')
//   // alert('touchstart')
//
//   return false;
// }, false);
// document.addEventListener('touchmove', () => {
//   console.log('touchstart')
//   // alert('touchstart')
//   CloserTimer.new_action();
//   return false;
// }, false);



const CloserTimer = new CloserTimerClass();
CloserTimer.new_action();
export default CloserTimer;
