import { combineReducers } from 'redux';
import AddedToCart from './added_to_cart.reducer';
import AsUsualCommand from './as_usual_command.reducer';
import Authentification from './authentification.reducer';
import CartList from './cart_list.reducer';
import Client from './client.reducer';
import Config from './config.reducer';
import GoTo from './go_to.reducer';
import HanddicapVersion from './handdicap_version.reducer';
import MenuList from './menu_list.reducer';
import ProductList from './product_list.reducer';
import Shop from './shop.reducer';
import userReducer from './user.reducer';


const rootReducer = combineReducers({
  go_to: GoTo,
  product_list: ProductList,
  menu_list: MenuList,
  cart: CartList,
  handdicap_version: HanddicapVersion,
  added_to_cart: AddedToCart,
  authentification: Authentification,
  as_usual_command: AsUsualCommand,
  shop: Shop,
  client: Client,
  config: Config,
  user: userReducer
});
export default rootReducer;
