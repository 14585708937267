
import { EDIT_CART_ITEM, SET_CART_LIST, UPDATE_CART_ITEM } from './../actions/index';

interface CartState {
  list: any[];
  edited?: {
    product: any;
    index: number;
  };
}

const INITIAL_STATE: CartState = {
  list: [
    {
      'cart_config': true,
      'total_price': 0,
      'for_type': 'cart_list',
      'product_quantity': 0,
      'open': true,
      'qr_code': '',
    }
  ],
  edited: undefined
}

// const list: any = [];

export default function (state: CartState = INITIAL_STATE, action: any) {
  switch (action.type) {
    // case UPDATE_DATA:
    //   return action.payload;
    case SET_CART_LIST:
      console.log('NEW STATE: ', action.payload);
      return action.payload;
    case EDIT_CART_ITEM:
      return {
        ...state,
        edited: action.payload ? { ...action.payload } : undefined
      }

    case UPDATE_CART_ITEM:
      const { index, product: cartItem } = action.payload;
      const { list: items } = state;
      items.splice(index, 1, cartItem);

      return {
        list: items,
        edited: {
          index,
          product: cartItem
        }
      };

    default:
      return state;
  }
}
