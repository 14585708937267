import React, { FC, useEffect, useState } from 'react';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { add_00_in_end } from '../../../libs';
import { cartCategoryMapper, cartIsEmpty, hasUserAndDeliveryModeSet } from '../../../selectors';
import { goToPath } from './../../../actions/index';

interface CartConfirmProps {
  close?: () => void;
}

export const CartConfirm: FC<CartConfirmProps> = ({ close, ...props }) => {
  (window as any).react_history = (props as any).history;

  const { config, categories } = useSelector(cartCategoryMapper);
  const userAndDeliveryModeSet = useSelector(hasUserAndDeliveryModeSet);
  const isCartEmpty = useSelector(cartIsEmpty);

  const dispatch = useDispatch();

  console.log("Cart categories: ", categories);

  const goNext = () => {
    const paymentsServices = (window as any).paymentsServices || [];
    if (paymentsServices.length === 1) {
      if (paymentsServices[0] === 'payement_cart') {
        dispatch(goToPath({
          'action': 'history',
          'id': '/order/checkout/credit_card_payment/',
          'from': 'cart_confirm'
        }));
      } else if (paymentsServices[0] === 'payement_caisse') {
        dispatch(goToPath({
          'action': 'history',
          'id': '/order/accepted_payement/',
          'from': 'cart_confirm'
        }));
      } else {
        alert("No payment provider configured");
      }
    } else {
      dispatch(goToPath({
        'action': 'history',
        'id': '/order/select_payment_type/',
        'from': 'cart_confirm'
      }));
    }
  }

  const continueOrder = () => {
    if (close) {
      close();
    } else {
      dispatch(goToPath({
        'action': 'history',
        'id': '/order/',
        'from': 'cart_confirm'
      }));
    }
  }

  useEffect(() => {
    console.log('userAndDeliveryModeSet ==> ', userAndDeliveryModeSet);

    setTimeout(() => {
      if (!userAndDeliveryModeSet) {
        dispatch(goToPath({
          'action': 'history',
          'id': '/user/'
        }));
      } else if (isCartEmpty) {
        dispatch(goToPath({
          'action': 'history',
          'id': '/order/'
        }));
      }
    })
  });

  return (
    <>
      <div key="suggestion_container_header" className="header">
        <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
        </div>
      </div>
      <div className="cart-confirm-container">
        <div className="cart-confirm-header">
          <h1>Votre commande est-elle exacte ?</h1>
        </div>
        <div className="cart-confirm-categories">
          {categories && categories.length > 0 && categories.map(cat => <CartCategory name={cat.name} items={cat.items} />)}
        </div>
        <div className="cart-confirm-config">
          <span>
            <p>TOTAL {add_00_in_end(config.total_price)} €</p>
          </span>
        </div>
        <div className="cart-confirm-config">
          <span>
            <p>FRAIS DE SERVICE {add_00_in_end((window as any).feesAmount)} €</p>
          </span>
        </div>
        <div className="cart-confirm-config">
          <span>
            <p>TOTAL A PAYER {add_00_in_end(config.total_price + (window as any).feesAmount)} €</p>
          </span>
        </div>
        <div className="cart-confirm-footer">
          <button className="continue button_grey_on_white" onClick={continueOrder}>CONTINUER MA COMMANDE</button>
          <button className="continue button_yellow_on_white" onClick={goNext}>FINALISER MA COMMANDE</button>
        </div>
      </div>
    </>
  );
};

const NextArrow: FC<{ onClick: () => void; disabled?: boolean }> = ({ onClick, disabled }) => <AiFillRightCircle onClick={!disabled && onClick} />;
const PrevArrow: FC<{ onClick: () => void; disabled?: boolean }> = ({ onClick, disabled }) => <AiFillLeftCircle onClick={!disabled && onClick} />;

interface CartCategoryProps {
  name: string;
  items: any[]
}

const CartCategory: FC<CartCategoryProps> = ({ name, items }) => {
  let slider: Slider;

  const next = () => {
    const { currentSlide, slideCount } = slider.innerSlider;
    console.log('Slider: ', { currentSlide, slideCount });
    slider.slickNext();
  }

  const prev = () => {
    const { currentSlide, slideCount } = slider.innerSlider;
    console.log('Slider: ', { currentSlide, slideCount });
    slider.slickPrev();
  }

  const [activeSlide, setActiveSlide] = useState(0);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    afterChange: current => {
      console.log('Active slide: ', current)
      setActiveSlide(current)
    }
  };

  const mobileSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => {
      console.log('Active slide: ', current)
      setActiveSlide(current)
    }
  };

  return (
    <div className="cart-category">
      <h1 className="cart-category-title">{name}</h1>
      {window.innerWidth > 420 ?
        (
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
            <div className={`scroll-arrow ${activeSlide === 0 ? 'disabled' : ''}`}>
              <PrevArrow onClick={prev} disabled={activeSlide === 0} />
            </div>
            <div style={{ width: 'calc(100% - 64px)' }}>
              <Slider ref={c => slider = c} {...sliderSettings}>
                {items && items.length > 0 && items.map(it => <CartCategoryItem item={it} />)}
              </Slider>
            </div>
            <div className={`scroll-arrow ${(activeSlide + 1) * 3 > items.length ? 'disabled' : ''}`}>
              <NextArrow onClick={next} disabled={(activeSlide + 1) * 3 > items.length} />
            </div>
          </div>
        ) : (
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
            <div className={`scroll-arrow ${activeSlide === 0 ? 'disabled' : ''}`}>
              <PrevArrow onClick={prev} disabled={activeSlide === 0} />
            </div>
            <div style={{ width: 'calc(100% - 64px)' }}>
              <Slider ref={c => slider = c} {...mobileSliderSettings}>
                {items && items.length > 0 && items.map(it => <CartCategoryItem item={it} />)}
              </Slider>
            </div>
            <div className={`scroll-arrow ${(activeSlide + 1) >= items.length ? 'disabled' : ''}`}>
              <NextArrow onClick={next} disabled={(activeSlide + 1) >= items.length} />
            </div>
          </div>
        )}
    </div >
  );
};

interface CartCategoryItemProps {
  item: any
}

const CartCategoryItem: FC<CartCategoryItemProps> = ({ item }) => {
  return (
    <div className="cart-item-wrapper">
      <div className="cart-item-quantity">
        <p>X{item.quantity}</p>
      </div>
      <div className="cart-item-desc">
        <p>{item.name}</p>
        {item.complement && item.complement.length > 0 && item.complement.map(c => <p>{c.name}</p>)}
      </div>
    </div>
  )
};
