
import $ from 'jquery';
import { words } from "lodash";
import { addDocumentToIndex, createIndex } from "ndx";
import { query } from "ndx-query";
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addToCart } from './../../../actions/index';
import { isGastronomicRestaurant } from './../../../libs/index';

function find_product_with_id_only(id) {
  for (let key in (window as any).data.product_list) {
    for (let product of (window as any).data.product_list[key]) {
      if (product.id === id) {
        product.go_to = { 'action': 'category', 'id': parseInt(key) };
        return product;
      }
    }
  }
}

function termFilter(term) {
  return term.toLowerCase();
}
function createDocumentIndex(fields) {
  const index = createIndex(fields.length);
  const fieldAccessors = fields.map((f) => (doc) => doc[f.name]);
  const fieldBoostFactors = fields.map(() => 1);

  return {
    add: (doc) => {
      addDocumentToIndex(
        index,
        fieldAccessors,
        // Tokenizer is a function that breaks text into words, phrases, symbols, or other meaningful elements
        // called tokens.
        // Lodash function `words()` splits string into an array of its words, see https://lodash.com/docs/#words for
        // details.
        words,
        // Filter is a function that processes tokens and returns terms, terms are used in Inverted Index to
        // index documents.
        termFilter,
        // Document key, it can be a unique document id or a refernce to a document if you want to store all documents
        // in memory.
        doc.id,
        // Document.
        doc,
      );
    },
    // `search()` function will be used to perform queries.
    search: (q) => query(
      index,
      fieldBoostFactors,
      // BM25 ranking function constants:
      1.2,  // BM25 k1 constant, controls non-linear term frequency normalization (saturation).
      0.75, // BM25 b constant, controls to what degree document length normalizes tf values.
      words,
      termFilter,
      // Set of removed documents, in this example we don't want to support removing documents from the index,
      // so we can ignore it by specifying this set as `undefined` value.
      undefined,
      q,
    ),
  };
}


let this_component: any;
interface Props {
  addToCart: Function
}
class HeaderComponentComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);

    let list_of_id = [];

    for (let categ_key in (window as any).data.product_list) {
      let category = (window as any).data.category.filter((x) => x.id == parseInt(categ_key))
      if (category.length === 0) { continue }
      if (category[0].display === 'none') { continue }
      for (let product of (window as any).data.product_list[categ_key]) {
        if (list_of_id.indexOf(product.id) !== -1) { continue }
        console.log(list_of_id);
        list_of_id.push(product.id);
        this.state.search.list.push({
          id: product.id,
          content: product.name,
        });

      }
    }
    // Create a document index that will index `content` field.
    this.state.search.index = createDocumentIndex([{ name: "content" }]);
    this.state.search.list.forEach((d) => { this.state.search.index.add(d); });
  }
  // index:any = undefined;
  state: any = {
    number_of_product_in_cart: 0,
    search: {
      opened: false,
      result: [],
      list: [],
      index: undefined,
    },
    server: {
      opened: false,

    },
  }

  isOpenedProductPage() {
    return window.location.pathname === "/product_all_list/opened_product/";
  }
  // ####################################################### [CLICK-EVENT]
  openOrCloseSearch() {
    this.state.search.opened = !this.state.search.opened;
    this.setState({
      search: this.state.search
    })
  }

  openOrCloseModalCallServer() {
    this.state.server.opened = !this.state.server.opened;
    this.setState({
      server: this.state.server
    });
    // -> Fermer automatiquemement la modal afficher précédements.
    if (!this.state.server.opened) { return }
    setTimeout(() => {
      this.openOrCloseModalCallServer();
    }, 1.5 * 1000);
  }
  // ####################################################### [RENDER]
  renderButtonLeft() {
    if (!isGastronomicRestaurant()) { return (<></>) }
    if (this.isOpenedProductPage()) {
      return (
        <>
          <button className="precedent" onClick={() => (window as any).react_history.push('/order/')}></button>
        </>
      )
    }
    return (
      <>
        <button className="table">
          <p>Table</p>
          {(window as any).data.number_of_table}
        </button>
        <button onClick={() => this.openOrCloseSearch()} className="search"></button>
      </>
    )
  }

  renderButtonRight(number_of_product_in_cart) {
    if (this.isOpenedProductPage()) { return (<></>) }
    return (
      <>
        <button className="plat_suivant"
          style={{ visibility: this.state.number_of_product_in_cart === 0 ? 'hidden' : 'initial' }}
          onClick={() => (window as any).react_history.push('/order/my_command/')}>
          <div className="bulle"
            style={{ display: this.state.number_of_product_in_cart === 0 ? 'none' : 'block' }}>{number_of_product_in_cart}</div>
        </button>
        <button onClick={() => this.openOrCloseModalCallServer()} className="call_server"></button>
      </>
    )
  }

  HiItems(items) {
    console.log(items)
  }

  inputSearchInit(input) {
    // console.log('inputSearchInit', input)
    if (input === null) { return; }
    input.focus();
  }

  // searchHandleKeyUp(event) {
  //   console.log(event.target.value);

  // }
  // {onKeyUp={this.searchHandleKeyUp.bind(this)}}

  renderSearchContainer() {
    if (!isGastronomicRestaurant()) { return (<></>) }
    return (
      <div key={'4ed51d4d21b24f4b919e28509bf109f4'}
        className="search_container"
        style={{ 'display': this.state.search.opened ? 'block' : 'none' }}>
        <div onClick={() => this.openOrCloseSearch()}
          className="close">X</div>
        <input type="text"
          className="search_input"
          ref={(input) => { this.inputSearchInit(input) }} />
        <ul>
          {this.renderResultSearch()}
        </ul>
      </div>
    )
  }

  renderCallServer() {
    if (!isGastronomicRestaurant()) { return (<></>) }
    if (!this.state.server.opened) { return (<></>); }
    return (
      <div key={'ae205ee75253415890e537113dbbaef7'} className="server_calling">
        Un serveur va passer répondre à votre demande !
      </div>
    );
  }

  clickOnProduct(product) {
    product = find_product_with_id_only(product.id)
    if (product.path.length === 0) {
      this.props.addToCart({ 'product': product, 'number': 1 });
      this.openOrCloseSearch()
    } else {
      // console.log(product)
      // console.log(`/product_all_list/opened_product/?product_id=${product.id}&category_id=${product.go_to.id}`)
      (window as any).react_history.push(`/product_all_list/opened_product/?product_id=${product.id}&category_id=${product.go_to.id}`)
    }

  }

  renderResultSearch() {
    let rendered = [];
    for (let product of this.state.search.result) {
      rendered.push((
        <li onClick={() => this.clickOnProduct(product)}>
          {product.content}
        </li>
      ))
    }
    return rendered;
  }

  render(): Array<JSX.Element> {
    this_component = this;
    return [
      (<>{this.renderSearchContainer()}</>),
      (<>{this.renderCallServer()}</>),
      (
        <div key={'0e751f834e2e4c17a4de217b0fb0cad4'} className="header">
          <div style={{ backgroundImage: 'url("' + '/public/img/headers/header1052x256.jpg' + '")' }}>
            <div className="button_for_right">
              {this.renderButtonRight(this.state.number_of_product_in_cart)}
            </div>
            <div className="button_for_left">
              {this.renderButtonLeft()}
            </div>
          </div>
        </div>
      )
    ];
  }
  componentDidMount() {
    $('.search_container .search_input').keyup((e) => {
      let value = $('.search_container .search_input').val();
      let result = [];
      for (let res of this.state.search.index.search(value)) {
        let result_line = this.state.search.list.filter((l) => l.id == res.key)[0];
        if (result_line === undefined) {
          continue
        }
        result.push(result_line);
      }
      this.state.search.result = result;
      this.setState({
        search: this.state.search
      })
    });
  }
}

function calculateNumberOfProductInCart(cart_list) {
  let number = 0;
  console.log('#'.repeat(45))
  console.log(cart_list.slice(1,));
  for (let pdt of cart_list.slice(1,)) {
    number += pdt.quantity;
  }
  return number;
}

function mapStateToProps(state: any) {
  if (this_component !== undefined) {
    setTimeout(() => {
      console.log('########################### [calculateNumberOfProductInCart}')
      console.log(state.cart_list)
      this_component.setState({
        number_of_product_in_cart: calculateNumberOfProductInCart(state.cart_list)
      });
    }, 150);
  }
  return {
    handdicap_version: state.handdicap_version,
    cart_list: state.cart_list,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    addToCart
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponentComponent);
