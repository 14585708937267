import QRCode from 'qrcode.react';
import React from 'react';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineDownload, AiOutlineUndo } from 'react-icons/ai';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import accents from 'remove-accents';
import * as lod from 'lodash';
import { cartItemsSelector } from '../../../../selectors';
import { LottieLoading } from '../../../lottie-loading/LottieLoading';
import { logout } from './../../../../actions/index';
import { add_more_configuration_information_to_cart } from './../../../../libs/cart.calculator';
import { command_system, is_electron_browser, LOCAL_SERVER } from './../../../../libs/index';
import Tracker from './../../../../libs/tracker';

pdfjs.GlobalWorkerOptions.workerSrc = '/public/js/pdf.worker.js';

interface Props {
  logout: Function;
  client: any;
  cart_list: any;
  user: any;
  shop: any;
}

interface AcceptedPayementComponentState {
  command_number?: string;
  commandid?: string;
  pdfNumPages?: number;
  pdfPageIndex: number;
}

class AcceptedPayementComponent extends React.Component<Props, AcceptedPayementComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      command_number: undefined,
      commandid: undefined,
      pdfNumPages: null,
      pdfPageIndex: 1
    };
  }

  displayQrCode(commandid) {
    if (is_electron_browser()) { return; }
    // TODO -> Chargée le qr_code.
    return (<QRCode value={commandid} />)
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      pdfNumPages: numPages
    })
  }

  nextPage = () => {
    const { pdfPageIndex, pdfNumPages } = this.state;
    if (pdfPageIndex < pdfNumPages) {
      this.setState({
        pdfPageIndex: pdfPageIndex + 1
      });
    }
  }

  previousPage = () => {
    const { pdfPageIndex } = this.state;
    if (pdfPageIndex > 1) {
      this.setState({
        pdfPageIndex: pdfPageIndex - 1
      });
    }
  }

  render(): Array<JSX.Element> {
    const { cart_list } = this.props;
    console.log(JSON.stringify(cart_list.slice(1, 1000)));
    (window as any).react_history = (this.props as any).history;

    // let title = "VOTRE PAIEMENT À ÉTÉ ACCEPTÉ";
    // let sub_title = "VOUS POUVEZ ALLER RÉCUPÉRER VOTRE COMMANDE";

    // if (!cart_list[0].payment_type && CONFIGURATION_PAGE.payement_caisse || cart_list[0].payment_type === 'cash') {
    //   title = "VOTRE COMMANDE À ÉTÉ PRISE EN COMPTE"
    //   sub_title = "PASSEZ À LA CAISSE POUR POUVOIR LA RÉGLER"
    // }

    const { command_number, commandid } = this.state;

    if (command_number !== undefined) {
      console.log('Rendering PDF ticket');

      const { pdfNumPages, pdfPageIndex } = this.state;
      const pdfUrl = `${LOCAL_SERVER}/public/pdf/${commandid}.pdf`;

      console.log('Ticket PDF file: ', pdfUrl);

      return [
        <>
          <div className="header">
            <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
            </div>
          </div>
          <div className="paid-ticket-container">
            <div className="paid-ticket">
              <Document
                file={pdfUrl}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pdfPageIndex} />
              </Document>
            </div>
            <div className="paid-ticket-pager">
              {(pdfPageIndex > 1) && <span><AiOutlineCaretLeft onClick={this.previousPage} /></span>}
              {(pdfNumPages > 0) && <span className="paid-ticket-page">Page {pdfPageIndex} / {pdfNumPages}</span>}
              {(pdfPageIndex < pdfNumPages) && <span><AiOutlineCaretRight onClick={this.nextPage} /></span>}
              <span style={{ paddingLeft: 30 }}>
                <a href={pdfUrl} download>
                  <button className="dwld button_grey_on_white back_button box_shadow_light">
                    <AiOutlineDownload />
                  </button>
                </a>
              </span>
              <span style={{ paddingLeft: 10 }}>
                <button
                  className="restart button_yellow_on_white back_button box_shadow_light"
                  onClick={() => Tracker.redirect_to_begin()}
                >
                  <AiOutlineUndo />
                </button>
              </span>
            </div>
          </div>
        </>
      ];

      // return [
      //   (
      //     <div key={'accepted_payement_container'} className="container-fluid accepted_payement_container">
      //       <div className={(this.props as any).handdicap_version.activated ? "div_airbag_10percent" : "div_airbag_20percent"}></div>
      //       <div className="container">
      //         <div className='row justify-content-center'>
      //           <h2 className="col-12 text_align_center">{title}</h2>
      //           <p className="sub_title">{sub_title}</p>
      //         </div>
      //         <div className="row justify-content-center">
      //           <div className="col-10 col-lg-7 box_shadow_light command_box">
      //             {this.displayQrCode(commandid)}
      //             <h1>COMMANDE <br />
      //               {command_number}</h1>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   )
      // ];

    }

    return [(
      <>
        <div className="header">
          <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
          </div>
        </div>
        <div className="loading-container">
          <p>Nous préparons votre commande ...</p>
          <LottieLoading />
        </div>
      </>
    )];
  }

  goToErrorPage() {
    (window as any).command_error = 'accepted_payement';
    setTimeout(() => {
      (this.props as any).history.push("/order/select_payment_type/");
    }, 100);
  }

  returnToHomeAfterTimeout(timeout = 5) {
    setTimeout(() => {
      Tracker.redirect_to_begin();
    }, timeout * 1000);
  }

  componentDidMount() {
    if (!lod.isNil(this.props.cart_list) && this.props.cart_list.length > 1) {
      add_more_configuration_information_to_cart(this.props.cart_list, this.props.client);
      const { cart_list } = this.props;
      let command_number;
      if (cart_list[0].hasOwnProperty('command_number')) {
        command_number = cart_list[0].command_number;
      }

      if (command_number !== undefined) {
        this.returnToHomeAfterTimeout();
        this.setState({ command_number: command_number });
        return;
      }

      let remove_accent = accents.remove(JSON.stringify({
        cart_list: this.props.cart_list,
        command_number: command_number,
        client: this.props.client,
        shop: this.props.shop,
        user: this.props.user,
        title: "",
        sub_title: "",
      }));

      command_system('accepted_command', remove_accent).then((e: any) => {
        console.log("#######  PAYMENT RESPONSE:: ", e.data);
        const { success, numero, ticketId } = e.data;
        console.log('Success : ', success);
        console.log('Numero : ', numero);
        console.log('Ticket ID : ', ticketId);

        if (!success) { this.goToErrorPage(); return; }
        // this.returnToHomeAfterTimeout();
        this.setState({
          command_number: numero,
          commandid: ticketId || 'undefined',
        });
      }).catch((e) => {
        this.goToErrorPage();
      });
    } else {
      this.returnToHomeAfterTimeout(0);
    }
  }
}

function mapStateToProps(state: any) {
  return {
    cart_list: cartItemsSelector(state),
    client: state.client,
    shop: state.shop,
    user: state.user,
    handdicap_version: state.handdicap_version
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    logout: logout,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AcceptedPayementComponent);
