
import axios from 'axios';
import { BACKEND_SERVER } from '.';

export const has_fidelity_product = (product: any) => {
  if (product == undefined) { return false; }
  return (product.hasOwnProperty('fidelity_quantity'));
}

export const load_as_usual_command = (qr_code, siret, props) => {
  // axios.post('http://admin-borne-preprod.splash360.fr/order/usual_command/', { qr_code: qr_code, siret: siret }).then((e) => {
  axios.post(BACKEND_SERVER + '/order/usual_command/', { qr_code: qr_code, siret: siret }).then((e) => {
    if (e.data.success) {
      console.log(props);
      props.update_data('usual_command_cart_list', e.data.usual_cart_list);
      console.log(e.data);
    } else {
      console.log('load_as_usual_command:error ');
    }
  }).catch((e) => {
    console.log('load_as_usual_command:catch ');
    console.log(e);
  });
}
