
import { is_redux_init } from './../actions/index';

export default function (state: any, action: any) {
  if (is_redux_init(action)) {
    return {
      'enabled_payement': {
        'credit_card': true, // -> Indique qu'il faille sautée.
        'cash': true,       // -> Payement en cash.
      },
      'open_and_close_time': [

      ],
    };
  }

  switch (action.type) {
    default:
      return state;
  }
}
