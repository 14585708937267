

// const poke_roll_menu_list = [
//   {"action": "category", "id": 101, "ico": "/public/img/VISUELS_PRODUITS/SIGNATURES/poke-hot-salmon-1000x1000px.png", "name": "Poke a composer", "title": "Plateaux", "selected": false},
//   {"action": "category", "id": 16, "ico": "/public/img/VISUELS_PRODUITS/SIGNATURES/poke-hot-salmon-1000x1000px.png", "name": "Signature", "title": "Signature", "selected": false},
//   {"action": "category", "id": 9, "ico": "/public/img/VISUELS_PRODUITS/PLATEAUX/plateau-gourmand-1000x1000px.png", "name": "Plateaux", "title": "Plateaux", "selected": false},
//   {"action": "category", "id": 20, "ico": "/public/img/VISUELS_PRODUITS/BOX/box-sashimi-1000x1000px.png", "name": "Box", "title": "Votre box", "selected": false},
//
//   {"action": "category", "id": 2, "ico": "/public/img/VISUELS_PRODUITS/BROCHETTES/brochettes-boeuf-fromage.png", "name": "Brochettes", "title": "Brochettes", "selected": false},
//   {"action": "category", "id": 3, "ico": "/public/img/VISUELS_PRODUITS/CALIFORNIA/california-tenpura-concombre-1000x1000px.png", "name": "California", "title": "California", "selected": false},
//   {"action": "category", "id": 6, "ico": "/public/img/VISUELS_PRODUITS/FLOCON/flocon-concombre.png", "name": "Flocon", "title": "Flocon", "selected": false},
//   {"action": "category", "id": 7, "ico": "/public/img/VISUELS_PRODUITS/KRYSPI/kryspi-saumon-avocat-1000x1000px.png", "name": "Krypsi", "title": "Krypsi", "selected": false},
//   {"action": "category", "id": 8, "ico": "/public/img/VISUELS_PRODUITS/MAKI/maki-saumon.png", "name": "Maki", "title": "Maki", "selected": false},
//
//
//   {"action": "category", "id": 15, "ico": "/public/img/VISUELS_PRODUITS/ROLL/roll-saumon-boursin-1000x1000px.png", "name": "Roll", "title": "Roll", "selected": false},
//   // {"action": "category", "id": 17, "ico": "/public/img/VISUELS_PRODUITS/SOBA/soba-poulet.png", "name": "Soba", "title": "Soba", "selected": false},
//   {"action": "category", "id": 18, "ico": "/public/img/VISUELS_PRODUITS/SUMMER/summer-saumon-avocat-1000x1000px.png", "name": "Summer", "title": "Summer", "selected": false},
//   {"action": "category", "id": 19, "ico": "/public/img//VISUELS_PRODUITS/SUSHI/sushi-crevette-1000x1000px.png", "name": "Sushi", "title": "Sushi", "selected": false},
//
//
//   {"action": "category", "id": 5, "ico": "/public/img/VISUELS_PRODUITS/ENTREE/salade-chou-1000x1000px.png", "name": "Entrée", "title": "Entrée", "selected": false},
//   {"action": "category", "id": 4, "ico": "/public/img/VISUELS_PRODUITS/DESSERTS/dessert-fondant-chocolat.png", "name": "Dessert", "title": "Dessert", "selected": false},
//   {"action": "category", "id": 1, "ico": "/public/img/VISUELS_PRODUITS/BOISSONS/coca-cola.png", "name": "Boissons", "title": "Boissons", "selected": false},
//
//     // -----------------------
//   {"action": "category", "id": 100, "ico": "/public/img/test/hamburger_test.png", "name": "Poke a composer", "title": "", "selected": false, "display": "none"},
//   {"action": "category", "id": 10, "ico": "/public/img/test/hamburger_test.png", "name": "Crunch", "title": "Crunch", "selected": false,"display": "none"},
//   {"action": "category", "id": 11, "ico": "/public/img/test/hamburger_test.png", "name": "Fruit et légumes", "title": "Fruit et légumes", "selected": false,"display": "none"},
//   {"action": "category", "id": 12, "ico": "/public/img/test/hamburger_test.png", "name": "Protéines", "title": "Protéines", "selected": false,"display": "none"},
//   {"action": "category", "id": 13, "ico": "/public/img/test/hamburger_test.png", "name": "Sauce", "title": "Sauce", "selected": false, "display": "none"},
//   {"action": "category", "id": 14, "ico": "/public/img/test/hamburger_test.png", "name": "Topping", "title": "Topping", "selected": false, "display": "none"},
// ]
//
// // /public/img/VISUELS_PRODUITS/POKE_A_COMPOSER/Fruits_et_Legumes/Ananas.png
// const chamas_tacos = [
// ]

// ###########################################################################################
export const menu_list = (window as any).data.category;
  // ################################################################################################ CHICKEN STREET  
  // {
  // 		"action": "category",
  // 		"id": 15,
  // 		"category_id": "cat15",
  // 		"ico": "/public/img/enterprise_name/picture_category/menu_naan_CURRY.png",
  // 		"name": "MENU NAAN",
  // 		"title": "MENU NAAN",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 16,
  // 		"category_id": "cat16",
  // 		"ico": "/public/img/enterprise_name/picture_category/menu_burger_2.png",
  // 		"name": "MENU BURGER",
  // 		"title": "MENU BURGER",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 17,
  // 		"category_id": "cat17",
  // 		"ico": "/public/img/enterprise_name/picture_category/menu_wrap2.png",
  // 		"name": "MENU WRAP",
  // 		"title": "MENU WRAP",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 18,
  // 		"category_id": "cat18",
  // 		"ico": "/public/img/enterprise_name/picture_category/duo1_2_epuphF6.png",
  // 		"name": "MENU DUO",
  // 		"title": "MENU DUO",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 19,
  // 		"category_id": "cat19",
  // 		"ico": "/public/img/enterprise_name/picture_category/maxifamily.png",
  // 		"name": "MENU FAMILY",
  // 		"title": "MENU FAMILY",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 20,
  // 		"category_id": "cat20",
  // 		"ico": "/public/img/enterprise_name/picture_category/naanfarmer_otFHTWv.png",
  // 		"name": "NAAN",
  // 		"title": "NAAN",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 21,
  // 		"category_id": "cat21",
  // 		"ico": "/public/img/enterprise_name/picture_category/quattro.png",
  // 		"name": "BURGER",
  // 		"title": "BURGER",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 22,
  // 		"category_id": "cat22",
  // 		"ico": "/public/img/enterprise_name/picture_category/wrap__street_egyNYBl.png",
  // 		"name": "WRAP",
  // 		"title": "WRAP",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 23,
  // 		"category_id": "cat23",
  // 		"ico": "/public/img/enterprise_name/picture_category/wings3soft.fw.png",
  // 		"name": "WINGS",
  // 		"title": "WINGS",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 24,
  // 		"category_id": "cat24",
  // 		"ico": "/public/img/enterprise_name/picture_category/TENDER.png",
  // 		"name": "TENDERS",
  // 		"title": "TENDERS",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 25,
  // 		"category_id": "cat25",
  // 		"ico": "/public/img/enterprise_name/picture_category/FRITES.png",
  // 		"name": "FRITES",
  // 		"title": "FRITES",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 26,
  // 		"category_id": "cat26",
  // 		"ico": "/public/img/enterprise_name/picture_category/BUCKET.png",
  // 		"name": "FRIED CHICKEN",
  // 		"title": "FRIED CHICKEN",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 27,
  // 		"category_id": "cat27",
  // 		"ico": "/public/img/enterprise_name/picture_category/salade_2.png",
  // 		"name": "SALADE",
  // 		"title": "SALADE",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 28,
  // 		"category_id": "cat28",
  // 		"ico": "/public/img/enterprise_name/picture_category/menu_enfant_burgeer.png",
  // 		"name": "MENU ENFANT",
  // 		"title": "MENU ENFANT",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 29,
  // 		"category_id": "cat29",
  // 		"ico": "/public/img/enterprise_name/picture_category/tiramisu_street.png",
  // 		"name": "DESSERTS",
  // 		"title": "DESSERTS",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 30,
  // 		"category_id": "cat30",
  // 		"ico": "/public/img/enterprise_name/picture_category/PEPSI.png",
  // 		"name": "BOISSONS",
  // 		"title": "BOISSONS",
  // 		"selected": false,
  //
  // 	}, {
  // 		"action": "category",
  // 		"id": 31,
  // 		"category_id": "cat31",
  // 		"ico": "/public/img/enterprise_name/picture_category/mayonnaise_street.png",
  // 		"name": "SAUCES",
  // 		"title": "Choisissez jusqu'\u00e0 2 sauces",
  // 		"selected": false,
  // 		"display": "none"
  // 	}, {
  // 		"action": "category",
  // 		"id": 32,
  // 		"category_id": "cat32",
  // 		"ico": "/public/img/enterprise_name/",
  // 		"name": "COMPOSITIONS",
  // 		"title": "Ajoutez votre composition",
  // 		"selected": false,
  // 		"display": "none"
  // 	}, {
  // 		"action": "category",
  // 		"id": 33,
  // 		"category_id": "cat33",
  // 		"ico": "/public/img/enterprise_name/picture_category/PEPSI_GINGER_-_Copie.png",
  // 		"name": "BOISSONS OFFERTES",
  // 		"title": "Choisissez votre boisson",
  // 		"selected": false,
  // 		"display": "none"
  // 	}, {
  // 		"action": "category",
  // 		"id": 34,
  // 		"category_id": "cat34",
  // 		"ico": "/public/img/enterprise_name/picture_category/STEAK.png",
  // 		"name": "SUPPLEMENTS",
  // 		"title": "Souhaitez vous des suppl\u00e9ments",
  // 		"selected": false,
  // 		"display": "none"
  // 	}, {
  // 		"action": "category",
  // 		"id": 35,
  // 		"category_id": "cat35",
  // 		"ico": "/public/img/enterprise_name/picture_category/cheese_2.png",
  // 		"name": "EXTRA",
  // 		"title": "Voulez vous un extra",
  // 		"selected": false,
  // 		"display": "none"
  // 	}, {
  // 		"action": "category",
  // 		"id": 36,
  // 		"category_id": "cat36",
  // 		"ico": "/public/img/enterprise_name/picture_category/pepsi_1.5l.png",
  // 		"name": "BOISSON 1,5 OFFERT",
  // 		"title": "Choisissez vos 2 bouteilles",
  // 		"selected": false,
  // 		"display": "none"
  // 	}, {
  // 		"action": "category",
  // 		"id": 37,
  // 		"category_id": "cat37",
  // 		"ico": "/public/img/enterprise_name/picture_category/NAAN_FROMAGE.png",
  // 		"name": "NAAN FROMAGE",
  // 		"title": "Souhaitez vous 1 Naan au fromage",
  // 		"selected": false,
  // 		"display": "none"
  // 	}

export const load_menu_list = () => {};
