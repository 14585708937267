import React, { FC } from 'react';
import { IoIosCheckmarkCircle } from "react-icons/io";

export interface RadioItem {
  value: any;
  title: string;
  icon: React.ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
}

export interface CustomRadioProps {
  id?: string;
  name?: string;
  value?: any;
  items: RadioItem[];
  onChange: (value: any) => void;
}

interface RadioItemProps extends RadioItem {
  selected: boolean;
  onSelect: () => void;
}

const RadioItemComponent: FC<RadioItemProps> = ({ title, icon, selected, onSelect }) => {

  return (
    <div className={selected ? 'radio-item item-selected' : 'radio-item'} onClick={onSelect}>
      <div>
        {selected ? <span className='radio-item-check'>
          <IoIosCheckmarkCircle size={20} />
        </span> : null}
        <span className='radio-item-icon'>
          {icon}
        </span>
      </div>
      <div className='radio-item-title'>{title}</div>
    </div>
  );
};

export const CustomRadio: FC<CustomRadioProps> = ({ value, items, onChange }) => (
  <ul className='cnc-custom-radio'>
    {items.map(it => <li key={`${it.value}`}>
      <RadioItemComponent
        {...it}
        onSelect={() => {
          console.log('Selected value: ', it.value);
          onChange(it.value);
        }}
        selected={value === it.value}
      />
    </li>)}
  </ul>
);
