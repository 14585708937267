import React from 'react';
import BinaryChoiceComponent from './../../../components/nav/binary_choice/binary_choice.component';

interface Props {
}
class HasFidelityCardComponent extends React.Component<Props> {
  yellow_choice = {
    'name': 'OUI',
    'url': '/users/auth/identification_mode/',
    'className': 'has_fidelity_button_authentification_mode',
    'ico': {
      'style': {
        'fontSize': '68px',
      }
    }
  }
  basic_choice = {
    'name': 'NON',
    'url': '/order/',
    'className': "has_fidelity_button_not",
    'ico': {
      'style': {
        'fontSize': '68px',
      }
    }
  }

  render(): JSX.Element {
    (window as any).react_history = (this.props as any).history;
    return (
      <BinaryChoiceComponent
        identification_mode={false}
        history={(this.props as any).history}
        title="VOULEZ-VOUS PROFITER DE NOTRE PROGRAMME DE FIDÉLITÉ"
        yellow_choice={this.yellow_choice}
        basic_choice={this.basic_choice}></BinaryChoiceComponent>
    );
  }
}


export default HasFidelityCardComponent;
