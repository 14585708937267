// import { AiCaretLeftOutlined, AiCaretRightOutlined, AiDownloadOutlined, AiUndoOutlined } from 'react-icons/ai';
import axios from 'axios';
import React from 'react';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineDownload, AiOutlineUndo } from 'react-icons/ai';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import accents from 'remove-accents';
import { cartItemsSelector } from '../../../../selectors';
import { LottieLoading } from '../../../lottie-loading/LottieLoading';
import { goToPath } from './../../../../actions/index';
import { add_more_configuration_information_to_cart } from './../../../../libs/cart.calculator';
import { has_fidelity_product } from './../../../../libs/fidelity';
import { command_system, is_electron_browser, LOCAL_SERVER, syncOrder } from './../../../../libs/index';
import CloserTimer from './../../../../libs/time';
import Tracker from './../../../../libs/tracker';
import { you_fid_client } from './../../../../libs/you_fid_api';

pdfjs.GlobalWorkerOptions.workerSrc = '/public/js/pdf.worker.js';

function burn_point(client, cart_list) {
  if (!client.is_authenticated()) { return; }
  let burn_products: any = '[';
  cart_list = cart_list.slice(1,);
  for (let product of cart_list) {
    if (!has_fidelity_product(product)) { continue; }
    burn_products += (JSON.stringify(product.youfid_product) + ',').repeat(product.quantity);
  }

  try {
    console.log(burn_products);
    burn_products = JSON.parse(burn_products.slice(0, -1) + ']');
  } catch (e) {
    return;
  }

  if (Object.keys(burn_products).length === 0) { return; }
  you_fid_client.burn_point(client.usr_id, burn_products).then((e) => { });
}

function add_point_to_the_customer(client, shop, cart_list) {
  if (!client.is_authenticated()) {
    return Promise.resolve({ 'data': { 'won_pts': 0 } });
  }

  return you_fid_client.add_point_to_the_customer(client.qr_code, shop.attribution_pts, cart_list[0].total_price);
}

interface Props {
  goToPath: Function,
  cart_list: any,
  shop: any,
  client: any,
  user: any
}
class CreditCardPaymentComponent extends React.Component<Props> {
  background = {
    'ico': {
      'src': '/public/img/nav/binary_choice/scan_background.png',
      'style': {
        'backgroundImage': 'url(/public/img/order/payment_page/background.png)',
        'backgroundPosition': '103% 92%',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': '91%',
        'height': '100%',
      },
      'style_pmr': {
        'backgroundImage': 'url(/public/img/order/payment_page/background.png)',
        'backgroundPosition': '50% 61%',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': '44%',
        'height': '100%',
      }
    }
  };

  state: any = {
    wait_payline_api: true,
    token: undefined,
    pdfNumPages: null,
    pdfPageIndex: 1,
    displayPdf: false,
    transactionId: undefined,
    syncingOrder: true,
    ticketId: undefined
  }

  mercureEventSource: EventSource = undefined;

  wait_credit_card_payment() {
    let params = {
      cart_list: this.props.cart_list,
      client: this.props.client,
      shop: this.props.shop,
    }

    let remove_accent = accents.remove(JSON.stringify(params));
    // ****************** DEBUT

    CloserTimer.block_timer();
    command_system('payement_cart', JSON.parse(remove_accent)).then((e) => {
      if (!e.data.success) {
        CloserTimer.new_action();
        if (e.data.hasOwnProperty('tpa_error') || e.data.hasOwnProperty('tpa_status')) {
          (this.props as any).history.push("/order/select_payment_type/");
          return;
        }

        return;
      }

      add_point_to_the_customer(this.props.client, this.props.shop, this.props.cart_list)
        .then((e) => {
          this.props.cart_list[0].won_pts = e.data.won_pts;
        });

      // - Bruler les points necessaires.
      burn_point(this.props.client, this.props.cart_list);
      Tracker.set_go_to({
        'action': 'GO_TO_PATH',
        'payload': {
          'action': 'history',
          'id': '/order/accepted_payement/',
        }
      });

      this.props.cart_list[0].command_number = e.data.command_number;
      (this.props as any).history.push('/order/accepted_payement/');
      return;
    }).catch(() => {
      (this.props as any).history.push("/order/select_payment_type/");
    });

    // ******************** FIN
  }

  payout() {
    alert("La fonction de payement par carte à été désactiver durant le salon. \n Merci de votre compréhension.");
    this.props.goToPath({
      'action': 'history',
      'id': '/order/accepted_payement/'
    });
  }

  call_api() {
    add_more_configuration_information_to_cart(this.props.cart_list, this.props.client);
    let params = {
      cart_list: this.props.cart_list,
      client: this.props.client,
      shop: this.props.shop,
      user: this.props.user,
      LOCAL_SERVER: LOCAL_SERVER,
    };

    syncOrder(params).then(res => {
      console.log('Syncing order result: ', res);

      if (res.data.success) {
        this.setState({
          ticketId: res.data.ticketId
        });

        axios.post(`${LOCAL_SERVER}/payement/web/`, params).then((e) => {
          console.log('Payment result: ', e);

          if (!e.data.success) {
            console.error(e);
            this.props.goToPath({
              'action': 'history',
              'id': '/order/select_payment_type/'
            });
            return;
          }

          console.log('Showing payment page ...');
          this.setState({
            wait_payline_api: false,
            token: e.data.result.token
          });
        }).catch((e) => {
          console.error(e);
          this.props.goToPath({
            'action': 'history',
            'id': '/order/select_payment_type/'
          });
        })
      } else {
        console.log("Syncing order failed: " + res.data.success);
        this.props.goToPath({
          'action': 'history',
          'id': '/order/select_payment_type/'
        });
      }
    }).catch((e) => {
      console.error(e);
      this.props.goToPath({
        'action': 'history',
        'id': '/order/select_payment_type/'
      });
    }).finally(() => {
      this.setState({
        syncingOrder: false
      })
    });
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      pdfNumPages: numPages
    })
  }

  nextPage = () => {
    const { pdfPageIndex, pdfNumPages } = this.state;
    if (pdfPageIndex < pdfNumPages) {
      this.setState({
        pdfPageIndex: pdfPageIndex + 1
      });
    }
  }

  previousPage = () => {
    const { pdfPageIndex } = this.state;
    if (pdfPageIndex > 1) {
      this.setState({
        pdfPageIndex: pdfPageIndex - 1
      });
    }
  }

  renderPayline() {
    console.log('Payment rendering ...');
    const { wait_payline_api } = this.state;
    if (wait_payline_api) {
      return [(
        <div className="loading-container">
          <p>Nous préparons votre commande ...</p>
          <LottieLoading />
        </div>
      )];
    }

    console.log('Rendering payline iframe ...');
    return [(
      <iframe id="iframe_id" src={`${LOCAL_SERVER}/payement/web_payline/?token=${this.state.token}`}></iframe>
    )];
  }

  paymentFinished = (transactionId, ticketId) => {
    if (transactionId) {
      console.log('Transaction succeeded ...');

      this.setState({ transactionId }, () => {
        let params = {
          cart_list: this.props.cart_list,
          client: this.props.client,
          shop: this.props.shop,
          LOCAL_SERVER: LOCAL_SERVER,
          transactionId,
          ticketId,
          user: this.props.user
        };

        axios.post(`${LOCAL_SERVER}/payment/sync_paid_order/`, params).then((e) => {
          if (!e.data.success) {
            console.error(e);
            return;
          }

          this.setState({
            displayPdf: true
          });

          add_point_to_the_customer(this.props.client, this.props.shop, this.props.cart_list)
            .then((e) => {
              this.props.cart_list[0].won_pts = e.data.won_pts;
            });

          // - Bruler les points necessaires.
          burn_point(this.props.client, this.props.cart_list);
        }).catch((e) => {
          console.error("syncing order failed", e);
        })
      });
    } else {
      console.log('Payment failed');
      setTimeout(() => {
        // this.props.goToPath({
        //   'action': 'history',
        //   'id': '/order/select_payment_type/'
        // });
        (this.props as any).history.push('/order/select_payment_type/');
      }, 10);
    }
  }

  goBack = () => {
    this.props.goToPath({
      'action': 'history',
      'id': '/order/cart_confirm/',
      'from': 'suggestion'
    });
  }

  componentDidMount() {
    window.onmessage = (message) => {
      console.log('Received data from iframe: ', message.data);
      if (message.data?.action === 'payment') {
        this.paymentFinished(message.data.transactionId, this.state.ticketId);
      }
    };

    if (is_electron_browser()) {
      add_more_configuration_information_to_cart(this.props.cart_list, this.props.client);
      this.wait_credit_card_payment();
    } else {
      this.mercureEventSource = new EventSource((window as any).mercureTopic);
      this.mercureEventSource.onmessage = ({ data }) => {
        console.log('*** Got from mercure: ', data);
        const message = JSON.parse(data);
        console.log("Parsed data: ", message);
      };

      this.call_api();
    }
  }

  componentWillUnmount() {
    window.onmessage = undefined;
    this.mercureEventSource.close();
  }

  render(): JSX.Element {
    const { syncingOrder } = this.state;

    (window as any).react_history = (this.props as any).history;
    if (is_electron_browser()) {
      let style = (this.props as any).handdicap_version.activated ? this.background.ico.style_pmr : this.background.ico.style;
      return (
        <div className="container-fluid credit_card_payment_container"
          style={style}>
          <div className={(this.props as any).handdicap_version.activated ? "div_airbag_10percent" : "div_airbag_20percent"}></div>
          <div className="container">
            <div className='row justify-content-center'>
              <h2 className="col-12 text_align_center">
                VEUILLEZ SUIVRE LES INSTRUCTIONS DE PAIEMENT
              </h2>
              <p className="sub_title">AFFICHÉS DANS LE LECTEUR CI-DESSOUS</p>
            </div>
          </div>
        </div>
      );
    } else if (syncingOrder) {
      return (
        <>
          <div className="header">
            <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
            </div>
          </div>
          <div className="loading-container">
            <p>Nous préparons votre commande ...</p>
            <LottieLoading />
          </div>
        </>
      );
    } else if (!this.state.displayPdf) {
      return (
        <>
          <div className="header">
            <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
            </div>
          </div>
          {this.renderPayline()}
          <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center'
          }}>
            <div
              className="continue button_grey_on_white"
              onClick={this.goBack}
            >
              RETOUR
            </div>
          </div>
        </>
      )
    } else {
      console.log('Rendering PDF ticket');

      const { pdfNumPages, pdfPageIndex, ticketId } = this.state;
      const pdfUrl = `${LOCAL_SERVER}/public/pdf/${ticketId}.pdf`;

      console.log('Ticket PDF file: ', pdfUrl);

      return (
        <>
          <div className="header">
            <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
            </div>
          </div>
          <div className="paid-ticket-container">
            <div className="paid-ticket">
              <Document
                file={pdfUrl}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pdfPageIndex} />
              </Document>
            </div>
            <div className="paid-ticket-pager">
              {(pdfPageIndex > 1) && <span><AiOutlineCaretLeft onClick={this.previousPage} /></span>}
              {(pdfNumPages > 0) && <span className="paid-ticket-page">Page {pdfPageIndex} / {pdfNumPages}</span>}
              {(pdfPageIndex < pdfNumPages) && <span><AiOutlineCaretRight onClick={this.nextPage} /></span>}
              <span style={{ paddingLeft: 30 }}>
                <a href={pdfUrl} download>
                  <button className="dwld button_grey_on_white back_button box_shadow_light">
                    <AiOutlineDownload />
                  </button>
                </a>
              </span>
              <span style={{ paddingLeft: 10 }}>
                <button
                  className="restart button_yellow_on_white back_button box_shadow_light"
                  onClick={() => Tracker.redirect_to_begin()}
                >
                  <AiOutlineUndo />
                </button>
              </span>
            </div>
          </div>
        </>
      );
    }
  }
}

function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version,
    cart_list: cartItemsSelector(state),
    client: state.client,
    shop: state.shop,
    user: state.user
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(
  CreditCardPaymentComponent
);
