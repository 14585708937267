import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addToCart, goToPath, reduceCart, update_data } from './../../../actions/index';
import HeaderNavigationComponent from './../../../components/nav/header_navigation/header_navigation.component';
import CartComponent from './../../../components/order/cart/cart.component';
import { CART_HEIGHT, HEADER_NAVIGATION_HEIGHT, is_electron_browser, staticFile } from './../../../libs/index';

let this_component: any;
interface Props {
  shop: any,
  client: any,
  goToPath: Function,
  addToCart: Function,
  reduceCart: Function,
  update_data: Function,
  as_usual_command: any
}
class ProfileComponent extends React.Component<Props> {
  state: any = {
    fidelity_list_product: [
    ],
    position_in_lists: [],
    container_style: {},
    as_usual_command: [],
  }
  unselect_all_product_list(product_list: any) {
    product_list.filter((item: any) => item.selected = false)
  }
  moove(event, base_index: any, index: any, direction: any) {
    event.preventDefault();
    event.stopPropagation();
    let product_list = this.state.fidelity_list_product[base_index];
    this.unselect_all_product_list(product_list);

    if (direction === +1) {
      if (product_list.length - 1 == index) {
        index = 0;
      } else {
        index++;
      }
    }

    if (direction === -1) {
      if (index === 0) {
        index = product_list.length - 1;
      } else {
        index--;
      }
    }

    product_list[index].selected = true;
    this.setState({
      'fidelity_list_product[base_index]': product_list,
    });
  }
  have_point_for_this(product: any) {
    return (product.point_price <= this.state.client.fidelity_point);
  }
  add_to_cart(product: any, base_index: any, index: any) {
    if (!this.have_point_for_this(product)) { return; }
    (this.props as any).addToCart({ 'product': product, 'number': 1 });
  }
  reduce_cart(product: any, base_index: any, index: any) {
    if (product.fidelity_quantity <= 0) { return; }
    (this.props as any).reduceCart({ 'product': product, 'number': 1 });
  }
  map_fidelity_product(fidelity_product: any, base_index: any) {
    let i = 0;
    for (let product of fidelity_product) {
      // console.log('Mapping product: ', product);
      if (product.selected) {
        return (
          <div key={'product_' + base_index}
            className="fidelity_product"
            style={{ opacity: this.have_point_for_this(product) ? '1' : '0.5' }}>
            <div key={"fidelity_product_" + base_index + "_" + product.id}
              className="product_visual"
              onClick={() => this.add_to_cart(product, base_index, i)}
              style={{ backgroundImage: 'url("' + staticFile(product.ico) + '")' }}>
              <div className="moove">
                <div className="box_shadow moove_right" onClick={(event) => this.moove(event, base_index, i, -1)}></div>
                <div className="box_shadow moove_left" onClick={(event) => this.moove(event, base_index, i, +1)}></div>
              </div>
              <div className="fidelity_name">{product.name}</div>
              <div className='point_price'>{product.point_price} pts</div>
            </div>
            {/* <div style={{margin: 'auto', width: '148px'}}>
              <ul className="button_more_and_less">
                <li onClick={(event) => this.reduce_cart(product, base_index, i)} className='less'></li>
                <li className="quantity">{product.fidelity_quantity}</li>
                <li  className='more box_shadow_light'></li>
              </ul>
            </div> */}
          </div>
        );
      }
      i += 1;
    }
  }
  map_fidelity_list_product(fidelity_list_product: any) {
    // console.log('Usual list products: ', fidelity_list_product);
    let mapped: Array<JSX.Element> = [];
    let i = 0;
    for (let product_list of fidelity_list_product) {
      mapped.push(this.map_fidelity_product(product_list, i))
      i++;
    }
    return mapped;
  }

  padding_bottom(handdicap_version_activated) {
    if (!is_electron_browser()) {
      return '146px';
    }
    if (handdicap_version_activated) {
      return '272px';
    }
    return '0px';
  }

  display_as_usual_command() {
    console.log(this.state.as_usual_command);
    if (this.state.as_usual_command.length <= 1) { return; }
    this.props.goToPath({
      'action': 'popin',
      'id': 'as_usual_command'
    });
  }

  render() {
    // console.log('Profile state: ', this.state);

    this_component = this;
    (window as any).react_history = (this.props as any).history;
    this.state.fidelity_list_product = (this.props as any).shop.fidelity_list_product;
    this.state.authentification = (this.props as any).authentification;
    this.state.client = (this.props as any).client;
    this.state.as_usual_command = (this.props as any).as_usual_command;
    let mapped = [
      (<HeaderNavigationComponent history={(this.props as any).history}></HeaderNavigationComponent>),
      (
        <div className="profile_container container-fluid"
          style={{
            paddingBottom: this.padding_bottom((this.props as any).handdicap_version.activated),
          }}>
          <div className='row justify-content-center fidelity_container'>
            <div className="col-11">
              <h1>Bonjour, {this.state.client.full_name}</h1>
              <p className="you_have">Tu as:</p>
              <div className="row justify-content-center">
                <div className='fidelity_score_block col-7 col-lg-4 col-sm-4 box_shadow_extended'>
                  <h1>{this.state.client.fidelity_point} <span style={{ fontWeight: 'normal' }}>Points</span></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row fidelity_product_container">
            {this.map_fidelity_list_product(this.state.fidelity_list_product)}
          </div>
          <div className="row redirect_container">
            <div className="col-12 white_line">
              <div className="row justify-content-center">
                {/* Comme d'habitude desactiv� */}
                {/* <button className="btn col-5 box_shadow_extended button_grey_on_white"
                  style={{ opacity: this.state.as_usual_command.length <= 1 ? 0.65 : 1.0 }}
                  onClick={() => this.display_as_usual_command()}
                >
                  Comme d'habitude ?
                </button> */}
                <button className="btn col-5 box_shadow_extended button_yellow_on_white"
                  onClick={() => this.props.goToPath({
                    'action': 'history',
                    'id': '/order/',
                  })}>
                  Nouvelle commande
                </button>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      ),
    ];

    if (is_electron_browser()) {
      mapped.push((
        <CartComponent></CartComponent>
      ));
    }
    return mapped;
  }
  updateDimensions() {
    let paddingBottom = CART_HEIGHT + 'px'
    let height = undefined;
    if (is_electron_browser()) {
      height = (window.innerHeight - (274 + 107 + 60))
    } else {
      height = window.innerHeight - HEADER_NAVIGATION_HEIGHT;
    }
    this.setState({
      container_style: {
        height: height,
        paddingBottom: paddingBottom,
      }
    });
  }
  componentDidMount() {
    this.updateDimensions.bind(this);
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
}

function mapStateToProps(state: any) {
  if (this_component !== undefined) {
    this_component.setState({
      'fidelity_list_product': state.shop.fidelity_list_product,
      'client': state.client,
      'authentification': state.authentification,
      'as_usual_command': state.as_usual_command
    });
  }

  return {
    authentification: state.authentification,
    handdicap_version: state.handdicap_version,
    shop: state.shop,
    client: state.client,
    as_usual_command: state.as_usual_command,
  };
}
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
    addToCart: addToCart,
    reduceCart: reduceCart,
    update_data: update_data,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
