import { is_electron_browser, staticFile } from './index';


export const choicePicture = (dict) => {
  if (is_electron_browser() || !dict.hasOwnProperty('ico_mobile')) {
    return dict.ico;
  }

  if (dict.ico_mobile.slice(0, 8) === '/public/') {
    return dict.ico_mobile;
  }

  return dict.ico;
}
