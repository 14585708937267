import React from 'react';
import { connect } from 'react-redux';
// import { is_electron_browser, staticFile } from './../../../libs/index';
import { bindActionCreators } from 'redux';
import PopInBinaryChoiceComponent from './../../../components/nav/pop_in_binary_choice/pop_in_binary_choice.component';
let this_component = undefined;
interface Props { }

class FirstPageComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  state: any = {
  }
  data = {
    'type': 'select_payment_type',
    'title_first_part': "COMMANDEZ EN LIGNE",
    'title_second_part': "",
    'choices': [
      {
        'text': 'SUR PLACE',
        'style': {
          'backgroundImage': 'url(/public/img/order/payment_type/bank_card.png)',
        },
        'go_to': { 'action': 'history', 'id': '/click_and_collect/adress/' },
        'add_to_cart_config': {
          'payment_type': 'bank_card',
        }
      },
      {
        'text': 'A EMPORTER',
        'style': {
          'backgroundImage': 'url(/public/img/order/payment_type/au_comptoire.png)',
        },
        'go_to': { 'action': 'history', 'id': '/click_and_collect/adress/' },
        'add_to_cart_config': {
          'payment_type': 'cash',
        }
      }
    ]
  }

  render(): JSX.Element {
    this_component = this;
    (window as any).react_history = (this.props as any).history;
    return (
      <div>
        <PopInBinaryChoiceComponent popin_this={undefined} type="basic" data={this.data}></PopInBinaryChoiceComponent>
      </div>
    );
  }
}


function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version,
  };
}


function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstPageComponent);
