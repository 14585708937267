import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPath } from './../../../actions/index';
import { is_electron_browser } from './../../../libs/index';
import HeaderNavigationComponent from './../header_navigation/header_navigation.component';
import PmrAccessComponent from './../pmr_access/pmr_access.component';

let this_component: any;
interface Props {
  title: any,
  yellow_choice: any,
  basic_choice: any,
  history: any,
  identification_mode: boolean,
  goToPath: Function,
}
class BinaryChoiceComponent extends React.Component<Props> {
  render(): Array<JSX.Element> {
    if (!is_electron_browser() && window.location.pathname === '/fidelity/has_fidelity_card/') {
      // this.props.basic_choice.url = '/profile/create/';
    }
    return [
      (
        <div key="order_header_component" className="header terminal-hidden-block">
          <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
          </div>
        </div>
      ),
      (
        <HeaderNavigationComponent key="order_header_navigation_component" history={(this.props as any).history}></HeaderNavigationComponent>
      ),
      (
        <div className="binary_choice_container">
          <div className={(this.props as any).handdicap_version.activated ? "div_airbag_10percent" : "div_airbag_20percent"}></div>
          <div className="container">
            <div className='row justify-content-center'>
              <h2 className="col-12 text_align_center">
                {this.props.title}
              </h2>
            </div>
          </div>
          <div className={(this.props as any).handdicap_version.activated ? "div_airbag_10percent" : "div_airbag_16percent"}></div>
          <div className="container">
            <div className="row justify-content-center binary_choice">
              <div className="col-6 yellow_choice">
                <div className={"white_border box_shadow_light " + this.props.yellow_choice.className}
                  onClick={() => this.props.goToPath({
                    'action': 'history',
                    'id': this.props.yellow_choice.url
                  })}
                  style={this.props.yellow_choice.ico.style}>
                  {this.props.yellow_choice.name}
                </div>
              </div>
              <div className="col-6 basic_choice"
                onClick={() => this.props.goToPath({
                  'action': 'history',
                  'id': this.props.basic_choice.url
                })}>
                <div className={"white_border box_shadow_light " + this.props.basic_choice.className}
                  style={this.props.basic_choice.ico.style}>
                  {this.props.basic_choice.name}
                </div>
              </div>
            </div>
          </div>
          <div style={{ 'display': this.props.identification_mode ? 'flex' : 'none' }}
            className="container-fluid row justify-content-center">
            <div className="col-lg-5 col-7 col-sm-5">
              <button onClick={() => this.props.goToPath({
                'action': 'history',
                'id': '/fidelity/has_fidelity_card/',
              })}
                className="button_grey_on_white back_button box_shadow_light"
                style={{ 'outline': 'none' }}>RETOUR</button>
            </div>
          </div>
          <div className='pmr_container row justify-content-center'>
            <PmrAccessComponent type='big'></PmrAccessComponent>
          </div>
        </div>
      )];
  }
}

function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version
  };
}
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BinaryChoiceComponent);
