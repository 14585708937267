import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { add_00_in_end } from './../../../libs/index';
import { Modal, Button } from 'react-bootstrap';
import FooterNavigationComponent from './../../../components/nav/footer_navigation/footer_navigation.component';
import  CartContainer  from './../../../components/order/cart_container/cart_container.component';

let this_component:any;
interface Props {
  as_usual_command:any
}
class AsUsualCommandComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  state: any = {
    as_usual_command: []
  }
  render():Array<JSX.Element> {
    this_component = this;
    this.state.as_usual_command = this.props.as_usual_command;
    return [
      (
        <div className="as_usual_command_container">
          <h1>Comme d'habitude ? </h1>

          <p className="sub_title">Voici votre dernière commande</p>
          <p className="your_command">Votre commande</p><p className="total">Total: {add_00_in_end(this.state.as_usual_command[0].total_price)} €</p>
          <div className="container-fluid grey_ribbon product_order_list">
              <CartContainer type_cart_container="usual_command_cart_list"></CartContainer>
          </div>
        </div>
      ),
      (
        <Modal.Footer>
          <FooterNavigationComponent type="as_usual_command" history={(window as any).react_history}></FooterNavigationComponent>
        </Modal.Footer>
      )
    ];
  }
}
function mapStateToProps(state:any) {
    if(this_component!==undefined) {
      this_component.setState({
        'as_usual_command': state.as_usual_command,
      })
    }
    return {
      as_usual_command: state.as_usual_command,
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    // goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps)(AsUsualCommandComponent);
