
// - unselect menu elements
// - select the new elements
import { GO_TO_PATH } from './../actions/index';
import { menu_list } from './request/menu_list';

function unselect_selected_item(): void {
  menu_list.filter((item: any) => item.selected = false)
}

function select_item(payload: any): void {
  let go_to = payload.action;
  let item: any = menu_list.filter((item: any) => (item.id == payload.id));
  if (item.length !== 0) {
    item[0].selected = true;
  }
}

export default function (state: any, action: any) {
  switch (action.type) {
    case GO_TO_PATH:
      if (!(action.payload.action === 'category')) {
        return state;
      }
      unselect_selected_item();
      select_item(action.payload);
      return menu_list;
    case 'RELOAD_DATA_BASE':
      return (window as any).data.category;
    default:
      return menu_list;
  }
}
