

import Tracker from './../libs/tracker';
export function is_redux_init(action: any) {
  return (action as any).type.substring(0, 8) == "@@redux/" || (action as any).type.substring(0, 6) == "@@INIT";
}


export const RELOAD_DATA_BASE = 'RELOAD_DATA_BASE';
export function reloadDataBase(path: any) {
  return {
    'type': RELOAD_DATA_BASE,
    'payload': {},
  }
}

export const UPDATE_DATA = 'UPDATE_DATA';
export function update_data(for_reducer: any, data: any) {
  // console.log('-------------');
  console.log('UPDATE_DATA:', for_reducer, data);
  // console.log('-------------');
  // CloserTimer.new_action();
  return {
    'type': UPDATE_DATA,
    'payload': {
      'for_reducer': for_reducer,
      'data': data
    },
  }
}

export const GO_TO_PATH = 'GO_TO_PATH';
export function goToPath(path: any) {
  // console.log('-------------');
  // console.log('GO_TO_PATH:', path);
  // console.log('-------------');
  // CloserTimer.new_action();
  const action = {
    'type': GO_TO_PATH,
    'payload': path,
  };

  console.log('Goto Action: ', action);
  return action;
}

export const ADD_TO_CART = 'ADD_TO_CART';
export function addToCart(product: any) {
  // console.log('-------------')
  // console.log('ADD_TO_CART:', product);
  // console.log('-------------')
  // CloserTimer.new_action();
  return {
    'type': ADD_TO_CART,
    'payload': product,
  };
}

export const REDUCE_CART = 'REDUCE_CART';
export function reduceCart(product: any) {
  // console.log('-------------')
  // console.log('drop_to_cart:', product);
  // console.log('-------------')
  // CloserTimer.new_action();
  return {
    'type': REDUCE_CART,
    'payload': product,
  }
}

export const CLEAN_CART = 'CLEAN_CART';
export function cleanCart(product: any) {
  // console.log('-------------')
  // console.log('drop_to_cart:', product);
  // console.log('-------------')
  // CloserTimer.new_action();
  return {
    'type': CLEAN_CART,
    'payload': product,
  }
}

export const ACTIVE_HANDICAPPED_VERSION = 'ACTIVE_HANDICAPPED_VERSION';
export function activeHandicappedVersion(params: any) {
  // console.log('-------------')
  // console.log('active handicap version:', params);
  // console.log('-------------')
  // CloserTimer.new_action();
  return {
    'type': ACTIVE_HANDICAPPED_VERSION,
    'payload': params,
  }
}

export const AUTHENTIFICATION = 'AUTHENTIFICATION';
export function authentification(params: any) {
  // console.log('-------------')
  // console.log('authentification:', params);
  // console.log('-------------')
  // CloserTimer.new_action();
  return {
    'type': AUTHENTIFICATION,
    'payload': params,
  }
}

export const LOGOUT = 'LOGOUT';
export function logout(params: any) {
  // console.log('-------------')
  // console.log('logout:', params);
  // console.log('-------------')
  // CloserTimer.new_action();
  Tracker.redirect_to_begin();
  return {
    'type': LOGOUT,
    'payload': params,
  }
}


export const LOAD_CUSTOMER = 'LOAD_CUSTOMER';
export function load_customer(params: any) {
  // console.log('-------------')
  console.log('Load customer data:', params);
  // console.log('-------------')
  // CloserTimer.new_action();
  return {
    'type': LOAD_CUSTOMER,
    'payload': params,
  }
}

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export function update_product(product) {
  return {
    type: UPDATE_PRODUCT,
    payload: product
  }
}

export const SET_STORE_OPEN = 'SET_STORE_OPEN';
export function setStoreOpen(status) {
  return {
    type: SET_STORE_OPEN,
    payload: status
  }
}

export const EDIT_CART_ITEM = 'EDIT_CART_ITEM';
export function editCartItem(item) {
  return {
    type: EDIT_CART_ITEM,
    payload: item
  };
}

export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export function updateCartItem(item) {
  return {
    type: UPDATE_CART_ITEM,
    payload: item
  };
}

export const SET_USER = 'SET_USER';
export function setUser(user: any) {
  return {
    type: SET_USER,
    payload: user
  }
}

export const SET_CART_LIST = 'SET_CART_LIST';
export function setCartList(list: any) {
  return {
    type: SET_CART_LIST,
    payload: list
  }
}

export type ActionType = 
  | 'ADD_TO_CART'
  | 'REDUCE_CART'
  | 'CLEAN_CART'
  | 'SET_CART_LIST'
;