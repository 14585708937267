import * as lod from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCartItem } from '../../../actions/';
import { staticFile } from '../../../libs';
import { editedCartItemSelector } from '../../../selectors';
// import './styles.scss';

interface CartEditProps {
  close: () => void
}

export const CartEdit: FC<CartEditProps> = ({ close }) => {
  const item = useSelector(editedCartItemSelector);
  const dispatch = useDispatch();

  const prefix = "SANS ";

  const [product, setProduct] = useState(undefined);

  const toogleCheckIngredientComment = (ing, commentIdx, comment) => {
    if (product) {
      if (comment.startsWith(prefix)) {
        ing.comments[commentIdx] = comment.replace(prefix, "");
      } else {
        ing.comments[commentIdx] = `${prefix}${comment}`;
      }

      setProduct({ ...product });
    }
  }

  const toogleCheckProductComment = (commentIdx, comment) => {
    if (product) {
      if (comment.startsWith(prefix)) {
        product.comments[commentIdx] = comment.replace(prefix, "");
      } else {
        product.comments[commentIdx] = `${prefix}${comment}`;
      }

      setProduct({ ...product });
    }
  }

  const updateItem = () => {
    dispatch(updateCartItem({
      ...item,
      product
    }));
    close();
  }

  useEffect(() => {
    if (item) {
      setProduct(lod.cloneDeep(item.product))
    }
  }, [item]);

  const customizableIngredients = product && product.ingredient_list ? product.ingredient_list
    .filter(ing => ing.comments && ing.comments.length) : [];

  if (product) {
    return (
      <div className="edit-container">
        <div className="edit-body">
          <div className="edit-product">
            <ProductImage picto={product.ico} />
            <div className="card-body">
              <h2 className="card-title">{product.name}</h2>
              <p className="card-text">{product.description}</p>
            </div>
          </div>
          {((customizableIngredients && customizableIngredients.length > 0) || (product.comments && product.comments.length > 0)) && <h2 className="customize-title">PERSONALISEZ</h2>}
          {product.comments && product.comments.length > 0 && <ProductCommentsView comments={product.comments} prefix={prefix} toogleCheck={toogleCheckProductComment} />}
          {
            customizableIngredients && customizableIngredients.length > 0 &&
            <div className="ingredients">
              {customizableIngredients.map((ing) => <IngredientCommentsView ingredient={ing} prefix={prefix} toogleCheck={toogleCheckIngredientComment} />)}
            </div>
          }
        </div>
        <div className="edit-footer">
          <button className="continue button_grey_on_white" onClick={close}>RETOUR</button>
          <button className="continue button_yellow_on_white" onClick={updateItem}>VALIDER</button>
        </div>
      </div>
    )
  } else {
    return <div></div>;
  }
};

const ProductImage = ({ picto }) => (
  <div className="product-image"
    style={{ backgroundImage: 'url("' + staticFile(picto) + '")' }}
  ></div>
);

interface IngredientCommentsViewProps {
  ingredient: any;
  prefix: string;
  toogleCheck: (item: any, commentIdx: number, comment: string) => void;
}

const IngredientCommentsView: FC<IngredientCommentsViewProps> = ({ ingredient, prefix, toogleCheck }) => (
  <div className="ingredient-item" style={{ backgroundImage: 'url("' + staticFile(ingredient.picto) + '")' }}>
    {ingredient.name && <h3>{ingredient.name}</h3>}
    <ul>
      {ingredient.comments.map((c, commentIdx) => (
        <li>
          <label><input type="checkbox" checked={!c.startsWith(prefix)} onClick={() => toogleCheck(ingredient, commentIdx, c)} /> {c}</label>
        </li>
      ))}
    </ul>
  </div>
);

interface ProductCommentsViewProps {
  prefix: string;
  comments: Array<string>;
  toogleCheck: (commentIdx: number, comment: string) => void;
}

const ProductCommentsView: FC<ProductCommentsViewProps> = ({ prefix, comments, toogleCheck }) => (
  <div className="product-comments">
    {/*name && <h3>{name}</h3>*/}
    <ul>
      {comments.map((c, commentIdx) => (
        <li>
          <label><input type="checkbox" checked={!c.startsWith(prefix)} onClick={() => toogleCheck(commentIdx, c)} /> {c}</label>
        </li>
      ))}
    </ul>
  </div>
);
