
import { ADD_TO_CART, is_redux_init, LOAD_CUSTOMER, REDUCE_CART } from './../actions/index';
import { has_fidelity_product } from './../libs/fidelity';


export default function (state: any, action: any) {
  if (is_redux_init(action)) {
    return {
      usr_id: 0,
      qr_code: '',
      full_name: '',
      fidelity_point: 0,
      fidelity_point_init: 0,
      expire_to: '03/05/2021',
      adress: {
        'street': '',
        'postal_code': '',
        'city': '',
        'complement': ''
      },
      phone_numnber: {
        'mobile': '',
        'landline_telephone': '',
      },
      is_authenticated() {
        console.log(this);
        return this.usr_id !== 0;
      }
    }
  }
  let number: any;
  switch (action.type) {
    case ADD_TO_CART:
      if (!has_fidelity_product(action.payload.product)) { return state; }
      number = action.payload.product.quantity;
      if (action.payload.hasOwnProperty('number')) {
        number = action.payload.number;
      }
      state.fidelity_point -= (action.payload.product.point_price * number)
      return state;
    case REDUCE_CART:
      if (!has_fidelity_product(action.payload.product)) { return state; }
      number = action.payload.product.quantity;
      if (action.payload.hasOwnProperty('number')) {
        number = action.payload.number;
      }
      state.fidelity_point += (action.payload.product.point_price * number)
      return state;
    case LOAD_CUSTOMER:
      state.full_name = `${action.payload.nom || ''} ${action.payload.prnom || ''}`.trim();
      state.fidelity_point = parseInt(action.payload.total_points || '0', 10);
      state.fidelity_point_init = parseInt(action.payload.total_points || '0', 10);
      state.usr_id = action.payload.usr_id;
      if (!action.payload.hasOwnProperty('qr_code')) {
        alert('send the qr_code to the element.')
      }
      state.qr_code = action.payload.qr_code;
      console.log(state);
      return state;
    default:
      return state
  }
  return state
}
