import React from 'react';
import { connect } from 'react-redux';
import { Swipeable } from "react-swipeable";
import { cartItemsSelector } from '../../../selectors';
import FooterNavigationComponent from './../../../components/nav/footer_navigation/footer_navigation.component';
import { add_00_in_end, is_electron_browser } from './../../../libs/index';
import CartContainer from './../cart_container/cart_container.component';

let this_component: any;
interface Props {
  cart_list: any,
  handdicap_version: any,

}
class CartComponent extends React.Component<Props> {
  state = {
    cart_list: [],
    window_size: {},
    cart_opened: undefined,
    first_run: true,
    handdicap_version: undefined,
  };

  reduce_cart() {
    console.log('reduce cart')
    // if (this.is_keep_big()) { return; }
    this.setState({
      cart_opened: false,
    });
  }

  enlarge_cart() {
    console.log('enlarge cart')
    this.setState({
      cart_opened: true,
    });
  }

  is_keep_big() {
    return false;
    // return (is_electron_browser() && !this.props.handdicap_version.activated)
  }

  is_reduce_in_pmr_mode() {
    return false;
    // return (this.props.handdicap_version.activated && window.location.pathname === '/profile/')
  }

  send_action_to_infinite_menu(action) {
    if ((window as any).menu_left_component !== undefined) {
      (window as any).menu_left_component.reduce_enlarge_cart_footer(action);
    }
  }

  cart_action(cart_opened, handdicap_version) {
    console.log('function::cart_action');
    console.log('cart_opened', cart_opened);

    // console.log('handdicap_version', handdicap_version);
    // if (cart_opened === undefined) {
    //   if (this.is_keep_big()) {
    //     this.send_action_to_infinite_menu(true);
    //     this.state.cart_opened = true;
    //     return;
    //   } else {
    //     this.send_action_to_infinite_menu(false);
    //     this.state.cart_opened = false;
    //     return;
    //   }
    // }

    let open = cart_opened || false;
    // if (cart_opened)

    // console.log('--------------------- cart_action')
    // if (is_electron_browser()) {
    //   if (this.is_keep_big()) {
    //     return;
    //   } else if (this.props.handdicap_version.activated) {
    //     this.send_action_to_infinite_menu(this.state.cart_opened);
    //     return (this.state.cart_opened ? 'cart_activate' : 'cart_disactivate')
    //   }
    // } else {

    if (this.state.cart_opened !== open) {
      this.setState({
        cart_opened: open
      });
    }

    this.send_action_to_infinite_menu(open);
    return (open ? 'cart_activate' : 'cart_disactivate');
    // }

    // return ('cart_disactivate');
  }

  swipeCartWhiteLine(event) {
    let action = event === 'TOP' ? this.enlarge_cart() : this.reduce_cart();
  }

  render(): Array<JSX.Element> {
    this_component = this;
    this.state.cart_list = this.props.cart_list;
    this.state.handdicap_version = this.props.handdicap_version;
    this.cart_action(this.state.cart_opened, this.state.handdicap_version);
    const config = {
      onSwipedUp: () => this.swipeCartWhiteLine('TOP'),
      onSwipedDown: () => this.swipeCartWhiteLine('BOTTOM'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    };

    return [
      // -> [MOBILE-VERSION] Il s'agit ici du font swippeable dans la version mobile.
      (
        <Swipeable {...config} className="container-fluid no-border">
          <div className="transparent_background terminal-hidden-block-no_important"
            style={{
              display: this.state.cart_opened ? 'block' : 'none'
            }}
            onClick={() => this.reduce_cart()}>

          </div>
        </Swipeable>
      ),
      (
        <div className='container-fluid order_footer'>
          <div className={this.cart_action(this.state.cart_opened, this.state.handdicap_version) + ' row cart_container'}>
            <Swipeable {...config} className="container-fluid no-border">
              <div style={this.state.window_size} className={"col-lg-12 white_line mobile-hidden"}
                onClick={() => this.state.cart_opened ? this.reduce_cart() : this.enlarge_cart()}
              >
                <div className="col-4 total">
                  <div>
                    TOTAL : {add_00_in_end(this.props.cart_list[0].total_price)}  €
                  </div>
                </div>
                <h2 style={{ display: this.state.cart_opened ? 'flex' : 'none' }}>VOTRE COMMANDE</h2>
                <h2 style={{ display: this.state.cart_opened ? 'none' : 'flex' }}>PANIER ({this.props.cart_list[0].product_quantity})</h2>
              </div>
              <div className="col-lg-12 white_line terminal-hidden-block"
                style={{
                  borderRadius: this.state.cart_opened ? '20px' : 'unset',
                  height: '15px',
                }}
                onClick={() => this.state.cart_opened ? this.reduce_cart() : this.enlarge_cart()}>
                <div style={{
                  width: '100px',
                  height: '4px',
                  borderRadius: '51px',
                  margin: 'auto',
                  backgroundColor: '#d3d7dd'
                }}></div>
              </div>
            </Swipeable>
            <CartContainer type_cart_container={'cart_list'}></CartContainer>
          </div>
          <Swipeable {...config} className="container-fluid no-border">
            <div className="terminal-hidden-block"
              style={{
                height: '70px',
                fontSize: '17px',
                fontWeight: 'bold',
                paddingLeft: '25px',
                paddingRight: '25px',
              }}>

              <span>Total:</span>
              <span style={{
                float: 'right',
              }}>{add_00_in_end(this.props.cart_list[0].total_price)} €</span>
            </div>
            <FooterNavigationComponent type="normal" history={(this.props as any).history}></FooterNavigationComponent>
          </Swipeable>
        </div>
      )
    ];
  }

  componentDidMountForMobile() {
    if (is_electron_browser()) { return; }
    // TODO -> Ajouter ici la prise en charge de l'accroissements de la taille du paniers.
  }

  componentDidMount() {
    this.componentDidMountForMobile();
    window.addEventListener("resize", this.componentDidMountForMobile.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.componentDidMountForMobile.bind(this));
  }
}

function reduce_cart_list_when_handicap_version_is_activated(state) {
  if (!is_electron_browser()) { return; }
  if (this_component.state.handdicap_version.activated !== state.handdicap_version.activated) {
    this_component.state.cart_opened = this_component.state.handdicap_version.activated;
    this_component.send_action_to_infinite_menu(this_component.state.handdicap_version.activated);
  }
}

function mapStateToProps(state: any) {
  const cart_list = cartItemsSelector(state);
  if (this_component !== undefined) {
    reduce_cart_list_when_handicap_version_is_activated(state);
    this_component.setState({
      'cart_list': cart_list,
      'handdicap_version': state.handdicap_version,
    });
  }

  return {
    cart_list: cart_list,
    handdicap_version: state.handdicap_version,
  };
}

export default connect(mapStateToProps)(CartComponent);
