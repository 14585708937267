
import { GO_TO_PATH, is_redux_init, UPDATE_DATA } from './../actions/index';
import Tracker from './../libs/tracker';
import { menu_list } from './request/menu_list';

// -> Récupérer l'éléments à renvoyer en premier lieux


export default function (state: any, action: any) {
  // console.log((window as any).data)
  if (is_redux_init(action)) {
    return (window as any).data.category.length !== 0 ? (window as any).data.category[0] : {};
  }

  if ([GO_TO_PATH].indexOf(action.type) !== -1) {
    Tracker.set_go_to(action);
  }

  switch (action.type) {
    case GO_TO_PATH:
      if (action.payload.action === 'history') {
        if ((window as any).react_history === undefined) {
          alert("integrate react history in this page");
          alert("(window as any).react_history = (this.props as any).history;");
        }

        setTimeout(() => {
          (window as any).react_history.push(action.payload.id);
          if (action.payload.id === '/order/') {
            return (window as any).data.category.length !== 0 ? (window as any).data.category[0] : {};
          }
        }, 10);

        return action.payload;
      }

      if (action.payload.action) {
        let menu_line = menu_list.filter((x) => x.id === action.payload.id);
        if (menu_line.length !== 0) {
          action.payload.title = menu_line[0].title;
        }
      }
      return action.payload;
    case UPDATE_DATA:
      return state;
    default:
      return state;
  }
}
