import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CONFIGURATION_PAGE } from './../../../libs/config';
import { goToPath } from './../../../actions/index';

let this_component:any;
interface Props {
  goToPath: Function
}
class TableNumberComponentComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  state:any = {
      'number_of_table': '',    
  } 
  keyboard_first_part = ['1', '2', '3', '4', '5', '6'];
  keyboard_second_part = ['7', '8', '9', '0', 'return'];
  letsGo() {  
      if (this.state.number_of_table.length===0) { return; }
      (window as any).data.number_of_table = this.state.number_of_table;
      if (CONFIGURATION_PAGE.youfid_login) {
        this.props.goToPath({
          'action': 'history',
          'id': '/fidelity/has_fidelity_card/',
        });
      } else {
        this.props.goToPath({
          'action': 'history',
          'id': '/order/',
        });
      }
  }

  clickInkeyBoardTouch(touch) {
    console.log(this.state);
    let number_of_table = (this.state as any).number_of_table;
    if (touch==='return') {
      number_of_table = number_of_table.slice(0, -1);
    } else if (number_of_table.length < 3) {
      number_of_table += touch;
    }
    this.setState({number_of_table});
  } 

  renderKeyboard(keyboard, className) {
    let rendered = [];
    for (let touch of keyboard) {
      console.log(touch);
      rendered.push((
        <div className={"keyboard_touch_" + touch} onClick={() => this.clickInkeyBoardTouch(touch)} >{touch}</div>
      ));
    }
    console.log(rendered);
    return (
      <div className={className}>
        {rendered}
       </div>
     );
  }

  render():Array<JSX.Element> {
    this_component = this;
    (window as any).react_history = (this.props as any).history;
    return [
      (
        <div key="order_header_component" className="header terminal-hidden-block">
          <div style={{backgroundImage: 'url(/public/img/headers/header1052x256.jpg)'}}>
          </div>
        </div>
      ),
      (
        <div className="table_number_container">
          <h1>Pour commander, <br/> saissisez votre  <br/> numéro de table</h1>
          <div className="input_text_number">

            {this.state.number_of_table}
          </div>
          <div className="keyboard">
            {this.renderKeyboard(this.keyboard_first_part, 'keyboard_first_part')}
            {this.renderKeyboard(this.keyboard_second_part, 'keyboard_second_part')}
          </div>
          <button onClick={() => this.letsGo()} style={{'opacity': this.state.number_of_table.length===0 ? '0.5' : '1.0' }}></button>
        </div>
      )
    ];
  }
}
function mapStateToProps(state:any) {
    if(this_component!==undefined) {

    }
    return {
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TableNumberComponentComponent);
