import $ from 'jquery';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CartConfirm } from '../../order/cart_confirm/cart_confirm.component';
import { CartEdit } from '../../order/cart_edit/cart_edit.component';
import CancelOrderComponent from './../../../components/order/cancel_order/cancel_order.component';
import CommandTypeComponent from './../../../components/order/checkout/command_type/command_type.component';
import SelectPaymentType from './../../../components/order/checkout/select_payment_type/select_payment_type.component';
import SuggestionComponent from './../../../components/order/checkout/suggestion/suggestion.component';
import ProductDescriptionComponent from './../../../components/products/product_description/product_description.component';
import AsUsualCommandComponent from './../../../components/users/as_usual_command/as_usual_command.component';
import { is_electron_browser } from './../../../libs/index';

let this_component: any;
interface Props {
  go_to: any,
  handdicap_version: any
}
class PopInComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    (window as any).components.PopInComponent = this;
    this.onHide = this.onHide.bind(this);
  }
  state: any = {
    'go_to': {},
    'popin_style': { 'height': '1800px' },
    'show': false,
    'className': '',
  };
  pop_in_component: any = {
    'command_type': { 'modal_body': (<CommandTypeComponent popin_this={this} key={2}></CommandTypeComponent>) },
    'payement_type': { 'modal_body': (<SelectPaymentType key={1} popin_this={this}></SelectPaymentType>) },
    'cancel_order': { 'modal_body': (<CancelOrderComponent popin_this={this} key={3}></CancelOrderComponent>) },
    'as_usual_command': { 'modal_body': (<AsUsualCommandComponent key={4}></AsUsualCommandComponent>) },
    'product_description': { 'modal_body': (<ProductDescriptionComponent popin_this={this} key={5}></ProductDescriptionComponent>) },
    'suggestion': { 'modal_body': (<SuggestionComponent key={6} popin_this={this}></SuggestionComponent>) },
    'product_edit': { 'modal_body': (<CartEdit close={() => this.onHide()} />) },
    'cart_confirm': { 'modal_body': (<CartConfirm close={() => this.onHide()} />) },
    'empty': { 'modal_body': (<div key="empty"></div>) },
  };
  open(go_to: any): JSX.Element {
    this.setState({ 'go_to': go_to })
    if (go_to.action !== 'popin') {
      this.onHide();
      return this.pop_in_component.empty;
    }

    if (go_to.hasOwnProperty('closed')) {
      return;
    }

    this.setState({ 'show': true });
  }
  onHide() {
    this.state.go_to.closed = true;
    this.setState({
      'show': false,
    });
  }

  whats_pop_in_is_selected() {
    this.state.className = '';
    if (this.props.go_to.action !== 'popin' || this.props.go_to.id == 'empty') {
      return (this.pop_in_component['empty']['modal_body']);
    }
    this.state.className = this.props.go_to.id
    return (this.pop_in_component[this.props.go_to.id]['modal_body']);
  }

  render(): any {
    this.state.handdicap_version = this.props.handdicap_version;
    // this.modify_overflow_for_handicapp_mode();
    this_component = this;
    return [
      (
        <div key={'modulable_popin'}>
          <Modal
            {...this.props}
            size="lg"
            show={this.state.show}
            className={((this.props as any).handdicap_version.activated ? 'handdicap_version' : '') + ' ' + this.props.go_to.id}
            aria-labelledby="contained-modal-title-vcenter modal-90w"
            centered
            scrollable={false}>
            <Modal.Header>
              <div className="close_container">
                <div onClick={() => this.onHide()}
                  className="close_button"
                  style={{
                    'color': '#4a4a4a',
                    'borderRadius': '50px',
                    'paddingTop': '10px',
                    'boxShadow': '-2px 2px 0.7em #d2d2d2',
                    'backgroundColor': 'white',
                    'backgroundImage': 'url(/public/img/close.png)',
                    'backgroundSize': 'contain',
                    'marginRight': '-30px',
                    'marginTop': '-25px',
                    'height': '47px',
                    'width': '47px',
                    'fontSize': '1px',
                    'position': 'absolute',
                    'top': '0',
                    'right': '2%',
                    'display': 'none'
                  }}></div>
              </div>
            </Modal.Header>
            <Modal.Body>
              {this.whats_pop_in_is_selected()}
            </Modal.Body>
          </Modal>
        </div>
      )];
  }
  componentDidMount() {
    if (!is_electron_browser()) { return; }
    console.log(this.state.handdicap_version.activated ? 'overflow_y_auto' : 'overflow_y_hidden');
    $('.modal-open .modal').addClass(this.state.handdicap_version.activated ? 'overflow_y_auto' : 'overflow_y_hidden');
  }
}

function mapStateToProps(state: any) {
  if (this_component) {
    this_component.open(state.go_to);
    this_component.setState({
      'go_to': state.go_to,
      'handdicap_version': state.handdicap_version,
    });
  }
  return {
    go_to: state.go_to,
    handdicap_version: state.handdicap_version,
  };
}

export default connect(mapStateToProps)(PopInComponent);
