import React from 'react';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { bindActionCreators } from 'redux';
import { cartIsEmpty, cartItemsSelector, hasUserAndDeliveryModeSet } from '../../../../selectors';
import { addToCart, goToPath, reduceCart } from './../../../../actions/index';
import HeaderNavigationComponent from './../../../../components/nav/header_navigation/header_navigation.component';
import { add_00_in_end, is_electron_browser, staticFile } from './../../../../libs/index';
import { menu_list } from './../../../../reducers/request/menu_list';
import { all_product_categories } from './../../../../reducers/request/product_list';

function find_categories_for_this_product(product: any) {
  for (let key in all_product_categories) {
    let product_index = all_product_categories[key].filter((x) => x.id === product.id);
    if (product_index.length === 0) { continue }
    return key;
  }
}

interface Props {
  cart_list: any,
  goToPath: Function,
  addToCart: Function,
  reduceCart: Function,
  popin_this: any,
}
class SuggestionComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  state: any = {
    fidelity_list_product: [],
  }

  continue() {
    if (is_electron_browser()) {
      this.props.goToPath({
        'action': 'popin',
        'id': 'cart_confirm',
        'from': 'suggestion'
      });
    } else {
      this.props.goToPath({
        'action': 'history',
        'id': '/order/cart_confirm/',
      });
    }
  }

  add_to_cart(product: any, base_index: any, index: any) {
    (this.props as any).addToCart({ 'product': product, 'number': 1 });
    product.fidelity_quantity += 1;
    this.setState({
      'fidelity_list_product': this.state.fidelity_list_product,
    });
  }

  reduce_cart(product: any, base_index: any, index: any) {
    if (product.fidelity_quantity <= 0) { return; }
    (this.props as any).reduceCart({ 'product': product, 'number': 1 });
    product.fidelity_quantity -= 1;
    this.setState({
      'fidelity_list_product': this.state.fidelity_list_product,
    });
  }

  unselect_all_product_list(product_list: any) {
    product_list.filter((item: any) => item.selected = false)
  }

  moove(event, base_index: any, index: any, direction: any) {
    event.preventDefault();
    event.stopPropagation();
    let product_list = this.state.fidelity_list_product[base_index];
    this.unselect_all_product_list(product_list);

    if (direction === +1) {
      if (product_list.length - 1 == index) {
        index = 0;
      } else {
        index++;
      }
    }

    if (direction === -1) {
      if (index === 0) {
        index = product_list.length - 1;
      } else {
        index--;
      }
    }

    product_list[index].selected = true;
    this.setState({
      'fidelity_list_product[base_index]': product_list,
    });
  }

  generate_suggestion_list_product(category_list: any) {
    console.log('*'.repeat(500))
    console.log(this.state.fidelity_list_product);
    try {
      for (let category_id in category_list) {
        console.log(category_id);
        console.log(category_list);
        if (this.state.fidelity_list_product.length >= 4) { break; }
        if (category_list[category_id]) { continue; }
        for (let product of all_product_categories[category_id]) {
          console.log(product);
          if (product.path === undefined) { continue }
          if (product.hasOwnProperty('path') && product.path.length !== 0) { continue }
          product.selected = false;
          product.fidelity_quantity = 0;
        }
        all_product_categories[category_id][0].selected = true;
        this.state.fidelity_list_product.push(all_product_categories[category_id]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  addToCart(product) {
    if (!product.hasOwnProperty('suggestion_quantity')) {
      product.suggestion_quantity = 0;
    }
    product.suggestion_quantity += 1;
    (this as any).setState({ fidelity_list_product: this.state.fidelity_list_product })
    setTimeout(() => {
      (this.props as any).addToCart({ 'product': product, 'number': 1 })
    }, 100);
  }

  dropToCart(event, product) {
    product.suggestion_quantity -= 1;
    (this as any).setState({ fidelity_list_product: this.state.fidelity_list_product })
    setTimeout(() => {
      (this.props as any).reduceCart({ 'product': product, 'number': 1 });
    }, 100);
    event.preventDefault();
    event.stopPropagation();
  }

  map_product_list(product_list) {
    let mapped: Array<JSX.Element> = [];
    for (let product of product_list) {
      mapped.push((
        <div key="">
          <div className="suggestion_product">
            <div onClick={() => this.addToCart(product)} className="text_container">
              <div className="product_ico" style={{ backgroundImage: 'url("' + staticFile(product.ico) + '")' }}>
                <p className="menos"
                  style={{ display: product.hasOwnProperty('suggestion_quantity') && product.suggestion_quantity > 0 ? 'block' : 'none' }}
                  onClick={(event) => this.dropToCart(event, product)}>-</p>
                <p className="quantity"
                  style={{ display: product.hasOwnProperty('suggestion_quantity') && product.suggestion_quantity > 0 ? 'block' : 'none' }}>{product.suggestion_quantity}</p>
              </div>

              <p>{product.name}</p>
              <div className="price">{add_00_in_end(product.unit_price)} €</div>
            </div>
          </div>
        </div>
      ))
    }

    return mapped;
  }

  map_product(product_list, base_index: any) {
    const sliderSetings = {
      nextArrow: <AiFillRightCircle />,
      prevArrow: <AiFillLeftCircle />,
      slidesToScroll: 1,
      adaptiveHeight: true
    };

    return (
      <div className="slide_container">
        <Slider {...sliderSetings} interval={3600 * 1000}>
          {this.map_product_list(product_list)}
        </Slider>
      </div>
    );
  }

  map_fidelity_product_list(fidelity_list_product) {
    let base_index = 0;
    let mapped: Array<JSX.Element> = [];
    for (let product_list of fidelity_list_product) {
      mapped.push(this.map_product(product_list.filter(p => !p.out_of_stock), base_index));
      // Wifi order, show only two categories
      if (mapped.length == 2) {
        break;
      }

      base_index++;
    }

    return mapped;
  }

  goBack = () => {
    if (this.props.popin_this && this.props.popin_this.onHide) {
      this.props.popin_this.onHide()
    } else {
      this.props.goToPath({
        'action': 'history',
        'id': '/order/',
        'from': 'suggestion'
      });
    }
  }

  componentDidMount(): void {
    const props = this.props as any;

    console.log('User and delivery set: ', props.userAndDeliveryModeSet);
    if (!props.userAndDeliveryModeSet) {
      props.goToPath({
        'action': 'history',
        'id': '/user/'
      });
    } else if (props.cartIsEmpty) {
      props.goToPath({
        'action': 'history',
        'id': '/order/'
      });
    }
  }

  first_run: any = true;
  render(): Array<JSX.Element> {
    (window as any).react_history = (this.props as any).history;
    let elem_of_cart_list = (window as any).data.suggestion_config ? (window as any).data.suggestion_config : {};
    let elem_of_menu = {};
    for (let category_id in all_product_categories) {
      if (elem_of_cart_list.hasOwnProperty(category_id)) { continue }
      let menu_category = menu_list.filter((x) => x.id == parseInt(category_id))[0]
      if (menu_category !== undefined && menu_category.hasOwnProperty('display') && menu_category.display === 'none') {
        continue;
      }

      // TODO -> Regarder s'il y a un path
      let has_path = false;
      for (let product of all_product_categories[category_id]) {
        if (!product.hasOwnProperty('path')) { continue }
        if (product.path.length !== 0) {
          has_path = true;
          break;
        }
      }

      if (has_path) { continue }
      elem_of_menu[category_id] = false;
    }

    // TODO -> Ici il faut observer si oui ou non il est possible de proposer quelque chose
    for (let product of this.props.cart_list.slice(1, 1000)) {
      let category_of_product = find_categories_for_this_product(product);
      if (elem_of_cart_list.hasOwnProperty(category_of_product)) {
        elem_of_cart_list[category_of_product] = true;
        continue;
      }
      if (elem_of_menu.hasOwnProperty(category_of_product)) {
        elem_of_menu[category_of_product] = true;
      }
    }

    this.generate_suggestion_list_product(elem_of_cart_list);
    this.generate_suggestion_list_product(elem_of_menu);

    if (this.state.fidelity_list_product.length === 0) {
      console.log("Popin instance: ", this.props.popin_this);
      if (this.props.popin_this && this.props.popin_this.props.go_to && this.props.popin_this.props.go_to.from === 'command_type') {
        this.props.popin_this.onHide();
      } else {
        setTimeout(() => {
          this.continue();
        });
      }
    }

    let mapped: Array<JSX.Element> = [];
    if (!is_electron_browser()) {
      mapped.push((
        <div key="suggestion_container_header" className="header">
          <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
          </div>
        </div>
      ));
      mapped.push((<HeaderNavigationComponent key="suggestion_container_header_navigation" history={(this.props as any).history}></HeaderNavigationComponent>));
    }

    // *********** Permet de régler le bug de doublons lors du clique
    let id_list = [];
    let fidelity_list_product = [];
    for (let fidelity of this.state.fidelity_list_product) {
      if (id_list.indexOf(fidelity[0].id) === -1) {
        fidelity_list_product.push(fidelity);
        id_list.push(fidelity[0].id);
        if (this.first_run) {
          for (let fidelity_product of fidelity) {
            fidelity_product.suggestion_quantity = 0;
          }
        }
      }
    }

    const count = this.state.fidelity_list_product.reduce((prev, array) => array.reduce((old, p) => p.suggestion_quantity + old, prev), 0);

    console.log('Selected count: ', count);

    // alert(this.first_run)
    this.first_run = false;
    this.state.fidelity_list_product = fidelity_list_product;
    mapped.push((
      <div key={'suggestion_container_key'} className="suggestion_container">
        <h1>PRENDREZ VOUS AUTRE CHOSE ?</h1>
        <div className="">
          {this.map_fidelity_product_list(this.state.fidelity_list_product)}
        </div>
        <div className="button_container">
          <div className="continue button_grey_on_white" onClick={this.goBack}>RETOUR</div>
          <div className="continue button_yellow_on_white" onClick={() => this.continue()}>{count > 0 ? 'SUIVANT' : 'NON, MERCI'}</div>
        </div>
      </div>
    ))
    return mapped;
  }
}

function mapStateToProps(state: any) {
  return {
    cart_list: cartItemsSelector(state),
    userAndDeliveryModeSet: hasUserAndDeliveryModeSet(state),
    cartIsEmpty: cartIsEmpty(state)
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
    addToCart: addToCart,
    reduceCart: reduceCart,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SuggestionComponent);
