import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPath } from './../../../actions/index';
import { is_electron_browser, staticFile } from './../../../libs/index';


let this_component: any;
interface Props {
  menu_list: any,  // TODO -> add the good type
  goToPath: Function,
}
class MenuLeftComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  animate_menu_list: any = [];
  state: any = {
    'menu_list': [{}],
    'infinite_menu_style': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    'menu_size': {
      'marginTop': '',
      'height': '',
    },
  };
  dom: any = null;
  infinite_menu: any = {
    'height': undefined,
    'width': undefined,
  };
  max: any = {
    'background': '#000000',
    'width': 228,
    'height': 133,
    'pos': { 'x': 0, 'y': 0, 'w': 228, 'h': 133 },
    'margin': 10,
  };
  min: any = {
    'width': 138,
    'height': 81,
    'margin': -13,
  };
  // position minimal
  min_pos: any = {
    'h': 81,
    'w': 138,
    'y': 0,
    'x': 0
  }

  send_go_to(menu_line) {
    menu_line.from = 'menu_left';
    this.props.goToPath(menu_line);
  }

  // refresh_size_page() {
  //   this.calculate_dimension_for_menu(this.state.menu_conf.cart_action);
  // }

  // ####################################################################################################

  calculate_dimension_for_menu(action) {
    console.log('calculate_dimension_for_menu: ', action);

    function ggouzigouza(number) {
      return parseInt(String(number).split('.')[0])
    }

    let marginTop = '';
    let height = '';
    let number_line = 0;
    // if (!is_electron_browser()) {
    /*
    let footer_height = action ? (36 + 285 + 46) :
      (36 + 46);
    let disponible_size = (window.innerHeight - (93 + 34 + footer_height));
    number_line = ggouzigouza(disponible_size / 66);
    if (number_line <= 1) {
      number_line = 7;
    }

    marginTop = ggouzigouza(((disponible_size % 66) / 2)) + 'px';
    height = (number_line * 66) + 'px';
    */
    // } else {
    let footer_height = action ? 187 + 60 + 237 :
      187 + 60;
    let header_height = action ? 274 + 96 : 848 + 96;
    let disponible_size = (window.innerHeight - (header_height + footer_height));

    number_line = (ggouzigouza(disponible_size / 137));
    if (number_line <= 1) {
      number_line = 7;
    }
    marginTop = (ggouzigouza(ggouzigouza(disponible_size % 137) / 2)) + 'px'
    height = (number_line * 137) + 'px';
    // }

    this.setState({
      menu_conf: {
        number_line: number_line,
        cart_action: action,
      },
      menu_size: {
        marginTop: marginTop,
        height: height,
      }
    });
  }

  first_run: boolean = true;
  reduce_enlarge_cart_footer(action: boolean) {
    this.calculate_dimension_for_menu(action);
    // if (action) {
    // } else {
    //   this.calculate_dimension_for_menu(action);
    // }
  }

  map_menu(menu_list: any) {
    let mapped = [];
    let i = 0;
    for (let menu_line of menu_list) {
      if (menu_line.hasOwnProperty('display')) {
        continue;
      }
      mapped.push((
        <li className="box_shadow_light"
          onClick={() => this.send_go_to(menu_line)}
          style={{ backgroundImage: 'url("' + staticFile(menu_line.ico) + '")' }}
          key={'category_' + i}>
          {menu_line.name}
        </li>
      ))
      i++;
    }
    return (
      <ul>
        {mapped}
      </ul>
    )
  }
  menu_list_length = 0;
  render(): Array<JSX.Element> {
    if (!is_electron_browser()) {
      return [(<div></div>)]
    }
    this_component = this;
    (window as any).menu_left_component = this_component;
    this.state.menu_list = this.props.menu_list;
    if (this.menu_list_length === 0) {
      this.menu_list_length = this.state.menu_list.length;
      let i = 0;
      while (i <= 10) {
        this.state.menu_list.push.apply(
          this.state.menu_list,
          this.state.menu_list.slice(0, this.menu_list_length)
        );
        // console.log(this.state.menu_list.length)
        i++;
      }
    }
    let style = {};
    if (!(this.props as any).handdicap_version.activated) {
      style = {
        height: '53%',
        margin: '32px',
      };
    } else if ((this.props as any).handdicap_version.activated) {
      style = {
        height: '46%',
        margin: '0px',
      }
    } else if (!is_electron_browser()) {
      // TODO >> Il serait bon d'avoir la dimension de la page auquel j'intégre un coups de mou vers le bas et vers le haut.
      // - pour la taille de l'éléments il serait bon de regarder si tout est en régles de manieres générale
      style = {

      }
    }

    return [
      (
        <>
          <div id="infinite_menu_button_haut" onClick={() => this.moovewithButton('top')}></div>
          <div id="infinite_menu_container"
            key={"infinite_menu_container"}
            style={this.state.menu_size}>
            {this.map_menu(this.state.menu_list)}
          </div>
          <div id="infinite_menu_button_bas" onClick={() => this.moovewithButton('bottom')}></div>
        </>
      )
    ];
  }

  moovewithButton(direction) {
    let position_scroll = $('#infinite_menu_container').scrollTop();
    let new_position = position_scroll + (122 * 2);
    if (direction === 'top') {
      new_position = position_scroll - (122 * 2);
    }
    $('#infinite_menu_container').animate({
      scrollTop: new_position
    }, 500);
  }

  first_run_componentDidMount = true;
  componentDidMount() {
    let infinite_menu_container = $('#infinite_menu_container');    // alert((this.menu_list_length * 5) * 25)
    setTimeout(() => {
      infinite_menu_container.scrollTop(((this.menu_list_length * 10) * 25) + infinite_menu_container.scrollTop())
      this.first_run_componentDidMount = false;
    }, 100);
    infinite_menu_container.scroll((e) => {
      // -> 122 + 15
      // if (this.first_run_componentDidMount) {
      //   infinite_menu_container.scrollTop(((this.menu_list_length * 10) * 25) + infinite_menu_container.scrollTop() )
      //   this.first_run_componentDidMount = false;
      //   return;
      // }
      let scrollTop = infinite_menu_container.scrollTop();
      let endOfScroll = 0;
      if (is_electron_browser()) {
        endOfScroll = this.state.menu_list.length * 10;
      } else {
        endOfScroll = this.state.menu_list.length * 25;
      }

      // 1. Que l'ont n'arrive en haut ou en bas ont copie 2 fois la listes d'éléments sur elle même.
      // 2. Ensuite selon que l'ont soit soit
      let menu_list = this.state.menu_list;
      if (scrollTop <= 5) {
        menu_list.unshift.apply(menu_list, menu_list.slice(0, this.menu_list_length - 1))
        infinite_menu_container.scrollTop(menu_list.slice(0, this.menu_list_length - 1).length * 52)
        // else {
        //   infinite_menu_container.scrollTop((menu_list.slice(0, this.menu_list_length-1).length * 25) - 450)
        // }
        this.setState({
          menu_list: menu_list
        });
      } else if (scrollTop >= endOfScroll - 50) {
        // TODO > Comme il faudra enlever un element
        menu_list.push.apply(menu_list, menu_list.slice(0, this.menu_list_length));
        // menu_list.splice(0, this.menu_list_length);
        // menu_list.splice(0, 1);
        this.setState({
          menu_list: menu_list
        });
      }
    });
  }
  componentWillUnmount() {
    $(window).off('resize');
    $('#infinite_menu_container').off('scroll');
  }
}

function mapStateToProps(state: any) {
  if (this_component) {
  }
  return {
    handdicap_version: state.handdicap_version,
    menu_list: state.menu_list,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeftComponent);
