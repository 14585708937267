import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPath } from './../../../actions/index';
import { is_electron_browser, staticFile } from './../../../libs/index';
import { choicePicture } from './../../../libs/picture';

// https://www.cssscript.com/infinite-list-scrolling-effect-in-javascript-and-css/
let this_component: any;
interface Props {
  menu_list: any,
  goToPath: Function,
}

class MenuTopComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  state = {
    menu_list: [],
  };
  send_go_to(category) {
    category.from = 'menu_left';
    this.props.goToPath(category);
  }

  map_menu_list(menu_list: any): Array<JSX.Element> {
    let mapped_menu: Array<JSX.Element> = [];
    for (let category of menu_list) {
      if (category.hasOwnProperty('display') && category.display === 'none') { continue; }
      mapped_menu.push((
        <div className={"item " + (category.selected ? 'selected' : '')}
          style={{
            backgroundImage: 'url("' + staticFile(choicePicture(category)) + '")'
          }}
          onClick={() => this.send_go_to(category)}
        >
          {category.name}
        </div>
      ))
    }
    return mapped_menu;
  }
  render(): Array<JSX.Element> {
    this_component = this;
    if (is_electron_browser()) { return [(<div></div>)] }
    this.state.menu_list = this.props.menu_list;
    return [
      (
        <div className="menu_top_container">
          <div className="my_wrap-container" id="my_wrap-scroll">
            <div id="my_ul-scroll">
              {this.map_menu_list(this.state.menu_list)}
            </div>
          </div>
        </div>
      ),
    ];
  }

  componentDidMount() {

  }
}
function mapStateToProps(state: any) {
  if (this_component !== undefined) {
    this_component.setState({
      menu_list: state.menu_list,
    });
  }
  return {
    menu_list: state.menu_list,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuTopComponent);
