import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPath } from '../../actions';
import Tracker from '../../libs/tracker';
import { cartItemsSelector, hasUserAndDeliveryModeSet } from '../../selectors';
import HeaderNavigationComponent from './../../components/nav/header_navigation/header_navigation.component';
import { is_electron_browser } from './../../libs/index';
import CartComponent from './cart/cart.component';
import MenuLeftComponent from './menu_left/menu_left.component';
import MenuTopComponent from './menu_top/menu_top.component';
import ProductListComponent from './product_list/product_list.component';

const backlistener = (e) => {
  e.preventDefault();
  console.log('Back pressed ...');
  Tracker.redirect_to_begin();
};

class OrderComponent extends React.Component {
  ref: any;
  constructor(props: any) {
    super(props);
  }

  state = {
    header_title: "",
    cart_list: [],
  }
  render(): Array<JSX.Element> {
    document.documentElement.style.overflow = 'hidden';
    (window as any).react_history = (this.props as any).history;
    return [
      // (
      //   <div key="order_header_component" className="header mobile-hidden" style={{'display': (this.props as any).handdicap_version.activated ? 'none': 'block' }}>
      //     <div style={{backgroundImage: 'url("' + staticFile('/public/img/headers/header1052x256.jpg') + '")'}}>
      //     </div>
      //   </div>
      // ),
      (
        <HeaderNavigationComponent key="order_header_navigation_component" history={(this.props as any).history}></HeaderNavigationComponent>
      ),
      (
        <MenuTopComponent key="menu_top_component"></MenuTopComponent>
      ),
      (
        <MenuLeftComponent key="order_menu_left_component"></MenuLeftComponent>
      ),
      (<ProductListComponent key={"order_product_list_component"}></ProductListComponent>),
      (
        <CartComponent key="order_cart_component"></CartComponent>
      ),
    ]
  }

  componentDidMount() {
    const props = this.props as any;

    console.log('User and delivery set: ', props.userAndDeliveryModeSet);
    if (!props.userAndDeliveryModeSet) {
      props.goToPath({
        'action': 'history',
        'id': '/user/'
      });

      return;
    }

    window.onpopstate = backlistener;

    // console.log('if the window is scrolled set top 0')
    $(window).scroll(() => {
      $(window).scrollTop(0);
    });
  }

  componentWillUnmount() {
    console.log("Order will unmount ...");
    if (is_electron_browser()) {
      return;
    }
    ($(window) as any).off('scroll');
  }
}

function mapStateToProps(state: any) {
  return {
    cart_list: cartItemsSelector(state),
    handdicap_version: state.handdicap_version,
    userAndDeliveryModeSet: hasUserAndDeliveryModeSet(state)
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderComponent);
