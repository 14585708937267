import React from 'react';
import BinaryChoiceComponent from './../../../../components/nav/binary_choice/binary_choice.component';

interface Props {}
class IdentificationModeComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  title = "QUEL MODE D'IDENTIFICATION ?";
  yellow_choice = {
    'name': 'Scan',
    'url': '/fidelity/scan_fidelity_card/',
    'className': 'identification_mode_button_scan_fidelity_card',
    'ico': {
      'src': '/public/img/nav/binary_choice/scan.png',
      'style': {
          'backgroundImage': 'url(/public/img/nav/binary_choice/scan.png)',
       },
    },
  }
  basic_choice = {
    'name': 'Manuel',
    'url': '/users/auth/identification_page/',
    'className': 'identification_mode_button_scan_identification_page',
    'ico': {
      'src': '/public/img/nav/binary_choice/manual.png',
      'style': {
        'backgroundImage': 'url(/public/img/nav/binary_choice/manual.png)',

      },
    }
  }

  render():JSX.Element {
    (window as any).react_history = (this.props as any).history;
    return (
      <BinaryChoiceComponent identification_mode={true} history={(this.props as any).history}
          title={this.title}
          yellow_choice={this.yellow_choice}
          basic_choice={this.basic_choice}></BinaryChoiceComponent>
    );
  }
}
export default IdentificationModeComponent;
