import { SET_USER } from "../actions";

export default function (state: any = {}, action: any) {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
