
import axios from 'axios';

export const BACKEND_SERVER = 'http://viteunveto.com:5757';
export const LOCAL_SERVER = window.location.protocol + '//' + window.location.host.split(':')[0];
// export const LOCAL_SERVER = window.location.protocol + '//' + window.location.host.split(':')[0] + (['127.0.0.1', 'localhost'].indexOf(window.location.host.split(':')[0]) !== -1 ? ':80' : '');
// export const LOCAL_SERVER = '';
// export const LOCAL_SERVER = 'http://127.0.0.1:3000';
// export const LOCAL_SERVER = window.location.protocol + '//' +  + ':3000';
export const CART_HEIGHT = 93; // TODO >>> Taille du cart qui peut être utiliser en padding.
export const HEADER_HEIGHT = 93; // TODO >>> Il s'agit de la taille du header.
export const HEADER_NAVIGATION_HEIGHT = 34; // TODO >> prendre en compte la ttaille du header

export const FOOTER_NAVIGATION_HEIGHT = 46; // TODO >> il s'agit de la taille du footer
export const REDUCED_CART_HEIGHT = 36; // TODO >> Il s'agit de la taille du cart réduit au maximum


export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function copy_list_without_reference(list: any) {
  let new_list = [];
  for (let item of list) {
    item.pos = Object.assign({}, item.pos);
    let new_item = Object.assign({}, item);

    new_list.push(new_item);
  }
  return new_list;
}


export function add_00_in_end(number) {
  number = Math.round(number * 100) / 100;
  let string_number = number.toString()
  let length_0 = string_number.split('.');
  if (length_0.length == 1)
    string_number = string_number + '.00'
  else if (length_0[1].length == 1) {
    string_number = string_number + '0'
  }
  return string_number.replace('.', ',');
}


export function is_electron_browser() {
  return navigator.userAgent.includes('Electron');
}

// - Vérifier d'une navigations par borne.
export function is_terminal_browsing() {
  return window.innerHeight === 1920 && window.innerWidth === 1080;
}

export function syncOrder(order) {
  return axios.post(LOCAL_SERVER + '/payment/sync_order', order);
}

export function command_system(cmd, json_params) {
  if (typeof (json_params) !== 'string') {
    json_params = JSON.stringify(json_params);
  }

  return axios.post(LOCAL_SERVER + '/command_system/', { cmd: cmd, json_params: json_params });
}

export function toggleFullScreen() {
  setTimeout(() => {
    if (window.innerHeight === window.outerHeight) { return; }
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || (docEl as any).mozRequestFullScreen || (docEl as any).webkitRequestFullScreen || (docEl as any).msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || (doc as any).mozCancelFullScreen || (doc as any).webkitExitFullscreen || (doc as any).msExitFullscreen;

    if (!doc.fullscreenElement && !(doc as any).mozFullScreenElement && !(doc as any).webkitFullscreenElement && !(doc as any).msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }, 120);
}

export function staticFile(url) {
  // if (!is_electron_browser()) {
  return window.location.protocol + '//' + window.location.host + url;
  // }
  // let baseDir = window.location['baseDir'].replace('&amp;', '&');

  // return baseDir + '/public' + url;
}

export function isGastronomicRestaurant() {
  return (window as any).data.server_config.APP_TYPE === "GASTRONOMIC_RESTAURANT";
}
export function isClickAndCollect() {
  return (window as any).data.server_config.APP_TYPE === "CLICK_AND_COLLECT";
}
export function isBasicWifiOrder() {
  return (window as any).data.server_config.APP_TYPE === "BASIC";
}

export function extractGetArguments() {
  let getComposant = window.location.href.split('?');
  if (getComposant.length === 1) { return {}; }
  getComposant = getComposant[1].split('&');
  const response = {};
  getComposant.forEach((keyValue: any) => {
    keyValue = keyValue.split('=');
    const key = keyValue[0];
    const value = keyValue[1];
    response[key] = value;
  });
  return response;
}

//
export function proxyPost(url, args) {
  args.url_to_access = url
  return axios.post(`${LOCAL_SERVER}/proxy/`, args);
}
