import React from 'react';
import { connect } from 'react-redux';
import { activeHandicappedVersion } from './../../../actions/index'
import { is_electron_browser, staticFile } from './../../../libs/index';

import { bindActionCreators } from 'redux';

interface Props {
  // TODO -> type little or type big.
  type:string,
  activeHandicappedVersion:Function
}
class PmrAccessComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  state:any = {
    is_electron_browser: is_electron_browser(),
  }
  render():JSX.Element {
    return (
      <div className="pmr_access_container"
           onClick={() => this.props.activeHandicappedVersion({'activated': true}) }
           style={{
             'display': (this.props as any).handdicap_version.activated  || this.state.is_electron_browser === false ? 'none': 'inline-block',
           }}
           >
        <div className={this.props.type + ' white_border'}>
          <img src={staticFile("/public/img/nav/pmr_access.png")}/>
          ACCES
        </div>
      </div>
    );
  }
}


function mapStateToProps(state:any) {
  return {
    handdicap_version: state.handdicap_version,
  };
}


function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PmrAccessComponent);
