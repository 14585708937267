import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cartItemsSelector } from '../../../selectors';
import { addToCart, goToPath } from './../../../actions/index';
import HeaderNavigationComponent from './../../../components/nav/header_navigation/header_navigation.component';
import { is_electron_browser } from './../../../libs/index';

let this_component: any;
interface Props {
  data: any,
  type: any,
  goToPath: Function,
  addToCart: Function,
  cart_list: any,
  config: any,
  popin_this: any,
}

class PopInBinaryChoiceComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  click_in_choice(choice) {
    Object.assign(this.props.cart_list[0], choice.add_to_cart_config);
    let enabled_payement = this.props.config.enabled_payement;
    switch (this.props.data.type) {
      case 'command_type':
        if (enabled_payement.credit_card && enabled_payement.cash) {
          return this.props.goToPath(choice.go_to);
        } else if (enabled_payement.cash === false) {
          if (is_electron_browser()) { this.props.popin_this.onHide() }
          (window as any).react_history.push('/order/checkout/credit_card_payment/')
        } else if (enabled_payement.credit_card === false) {
          this.props.goToPath({
            'action': 'history',
            'id': '/order/accepted_payement/',
          });
        }
        break;
      default:
        this.props.goToPath(choice.go_to);
    }
  }

  map_choices() {
    let mapped = [];
    let i = 0;;
    for (let choice of this.props.data.choices) {
      mapped.push((
        <div key={'pop_in_binary_choice_' + i}
          onClick={() => this.click_in_choice(choice)}
          style={choice.style}
          className="choice box_shadow_light">
          {choice.text}
        </div>
      ));
      i++;
    }
    return mapped;
  }

  renderReturnButton() {
    if (["/order/select_payment_type/"].indexOf(window.location.pathname) !== -1) {
      return (
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center'
        }}>
          <div
            className="continue button_grey_on_white"
            onClick={() => this.props.goToPath({ 'action': 'history', 'id': '/order/', from: 'command_type' })}
          >
            RETOUR
          </div>
        </div>
      );
    } else {
      return (
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center'
        }}>
          <div
            className="continue button_grey_on_white"
            onClick={() => this.props.goToPath({ 'action': 'popin', 'id': 'suggestion', from: 'command_type' })}
          >
            RETOUR
          </div>
        </div>
      );
    }
  }

  render(): Array<JSX.Element> {
    this_component = this;
    let render_list = [];
    if (!is_electron_browser()) {
      render_list.push((
        <div key={'pop_in_binary_choice_header'}
          className="header"
          style={{ 'display': (this.props as any).handdicap_version.activated ? 'none' : 'block' }}>
          <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
          </div>
        </div>
      ));
      render_list.push(
        <HeaderNavigationComponent history={(this.props as any).history}></HeaderNavigationComponent>
      )
    }

    render_list.push((
      <div key={'pop_in_binary_choice_map_choice'}
        className="pop_in_content_general pop_in_binary_choice">
        <h1 className="title_first_part">{this.props.data.title_first_part}</h1>
        <h1 className="title_second_part">{this.props.data.title_second_part}</h1>
        <div className="container-fluid row justify-content-center">
          {this.map_choices()}
        </div>
        {this.renderReturnButton()}
      </div>
    ));
    return render_list;
  }
}
function mapStateToProps(state: any) {

  return {
    cart_list: cartItemsSelector(state),
    handdicap_version: state.handdicap_version,
    config: state.config
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
    addToCart: addToCart,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopInBinaryChoiceComponent);
