import { useFormik } from "formik";
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import { IoBagSharp } from "react-icons/io5";
import { MdDeliveryDining, MdOutlineRestaurantMenu } from "react-icons/md";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Yup from 'yup';
import { goToPath, setUser } from "../../actions";
import { CustomRadio } from "./CustomRadioComponent";
import { OnsiteUserComponent } from './OnsiteUser';

const SignupSchema = Yup.object().shape({
  prenom: Yup.string().required('Merci de saisir votre prenom'),
  nom: Yup.string().required('Merci de saisir votre nom'),
  telephone: Yup.string()
    .test('telephone', 'Numero de téléphone non valide', value => {
      if (!value) {
        return true;
      }

      return isValidPhoneNumber(value, 'FR');
    })
    .required('Merci de saisir votre n° de téléphone'),
  email: Yup.string()
    .email('E-mail non valide')
    .required('Merci de saisir votre e-mail'),
  mode: Yup.string().required('Merci de choisir le mode livraison'),
  adresse: Yup.string().ensure().when('mode', {
    is: 'livraison',
    then: Yup.string().required('Merci de saisir votre adresse')
  }),
  codepostal: Yup.string().ensure().when('mode', {
    is: 'livraison',
    then: Yup.string().required('Merci de saisir le code postal').min(5, 'Minimum 5 caractères').max(5, 'Maximumn 5 caractères')
  }),
  ville: Yup.string().ensure().when('mode', {
    is: 'livraison',
    then: Yup.string().required('Merci de saisir la ville')
  })
});

const UserComponent = (props) => {

  const deliveryServices = (window as any).deliveryServices || [];
  const mode = deliveryServices.length === 1 ? deliveryServices[0] : "";
  const sectors = (window as any).sectors || {};
  const zipOptions = Object.keys(sectors).map(key => ({
    value: key,
    label: key
  }));

  const allDeliveryOptions = {
    livraison: { icon: <MdDeliveryDining size={60} />, title: 'Livraison', value: 'livraison' },
    emporter: { icon: <IoBagSharp size={60} />, title: 'A emporter', value: 'emporter' },
    surplace: { icon: <MdOutlineRestaurantMenu size={60} />, title: 'Sur place', value: 'surplace' }
  };

  const deliveryOptions = deliveryServices.map(opt => allDeliveryOptions[opt]).filter(it => it !== undefined);
  const { user } = props;
  const [selectedZip, setSelectedZip] = useState<Array<{ value: string, label: string }>>([]);
  const [zipTowns, setZipTowns] = useState<Array<{ value: string, label: string }>>([]);
  const [selectedTown, setSelectedTown] = useState<Array<{ value: string, label: string }>>([]);

  const changeZipTowns = (zip) => {
    console.log('Change zip towns: ', zip);
    let towns = [];
    if (zip && zip !== '') {
      towns = sectors[zip].map(t => ({
        value: t,
        label: t
      }));
    }

    setZipTowns(towns);
    if (towns.length === 1) {
      setFieldValue('ville', towns[0].value);
      setSelectedTown(towns);
    } else {
      setSelectedTown([]);
    }
  };

  useEffect(() => {
    if (user?.codepostal) {
      const zipFound = zipOptions.filter(z => z.value === user.codepostal);
      if (zipFound.length) {
        setTimeout(() => {
          setSelectedZip(zipFound);
          if (user?.ville) {
            const towns = sectors[zipFound[0].value].map(t => ({
              value: t,
              label: t
            }));

            setZipTowns(towns);
            const townFound = towns.filter(t => t.value === user?.ville);
            if (townFound.length) {
              setSelectedTown(townFound);
            } else if (towns.length === 1) {
              setSelectedTown(towns);
            } else {
              setSelectedTown([]);
            }
          }
        });
      }
    }
  }, []);

  const { handleSubmit, errors, values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      prenom: user.prenom || "",
      nom: user.nom || "",
      telephone: user.telephone || "",
      email: user.email || "",
      mode: deliveryServices.indexOf(user.mode) !== -1 ? user.mode : mode,
      adresse: user.adresse || "",
      adresse2: user.adresse2 || "",
      batiment: user.batiment || "",
      etage: user.etage || "",
      codepostal: user.codepostal || "",
      ville: user.ville || "",
      commentaire: user.commentaire || ""
    },
    validationSchema: SignupSchema,
    onSubmit: (val) => {
      console.log("Form values: ", val);
      console.log("Form errors: ", errors);
      props.setUser(val);
      setTimeout(() => {
        // props.goToPath({
        //   action: 'history',
        //   id: '/order/'
        // });
        const { location } = window;
        const newUrl = `${location.protocol}//${location.hostname}/order/`;
        window.location.replace(newUrl);
      }, 100);
    }
  });

  (window as any).react_history = props.history;
  // console.log("Form Values: ", values);

  return (
    <div className="user-form">
      <div className="header">
        <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
        </div>
      </div>

      <div className="container">
        <h2 className="title">IDENTIFIEZ-VOUS !</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <label htmlFor="prenom">Prénom</label>
              <input
                id="prenom"
                name="prenom"
                type="text"
                onChange={handleChange}
                value={values.prenom}
              />
              {errors.prenom && <div className="error_text">{errors.prenom}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="nom">Nom</label>
              <input
                id="nom"
                name="nom"
                type="text"
                onChange={handleChange}
                value={values.nom}
              />
              {errors.nom && <div className="error_text">{errors.nom}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="telephone">Téléphone</label>
              <input
                id="telephone"
                name="telephone"
                type="text"
                onChange={handleChange}
                value={values.telephone}
              />
              {errors.telephone && <div className="error_text">{errors.telephone}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="email">Adresse email</label>
              <input
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                value={values.email}
              />
              {errors.email && <div className="error_text">{errors.email}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Service</label>
              <CustomRadio
                id="mode"
                name="mode"
                items={deliveryOptions}
                onChange={value => setFieldValue('mode', value)}
                value={values.mode}
              />
              {errors.mode && <div className="error_text">{errors.mode}</div>}
            </div>
          </div>
          {values.mode === 'livraison' &&
            <div>
              <div className="row">
                <div className="col">
                  <label>Adresse</label>
                  <input
                    id="adresse"
                    name="adresse"
                    type="text"
                    onChange={handleChange}
                    value={values.adresse}
                  />
                  {errors.adresse && <div className="error_text">{errors.adresse}</div>}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Complément d'adresse</label>
                  <input
                    id="adresse2"
                    name="adresse2"
                    type="text"
                    onChange={handleChange}
                    value={values.adresse2}
                  />
                  {errors.adresse2 && <div className="error_text">{errors.adresse2}</div>}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Batiment</label>
                  <input
                    id="batiment"
                    name="batiment"
                    type="text"
                    onChange={handleChange}
                    value={values.batiment}
                  />
                  {errors.batiment && <div className="error_text">{errors.batiment}</div>}
                </div>
                <div className="col">
                  <label>Etage</label>
                  <input
                    id="etage"
                    name="etage"
                    type="text"
                    onChange={handleChange}
                    value={values.etage}
                  />
                  {errors.etage && <div className="error_text">{errors.etage}</div>}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Code postal</label>
                  {/* <input
                    id="codepostal"
                    name="codepostal"
                    type="text"
                    onChange={handleChange}
                    value={values.codepostal}
                  /> */}
                  <Select
                    name="codepostal"
                    searchable
                    onChange={selectedValues => {
                      console.log('Selected zip code: ', selectedValues);
                      if (selectedValues && selectedValues.length) {
                        setFieldValue('codepostal', selectedValues[0].value);
                        setFieldValue('ville', '');
                        changeZipTowns(selectedValues[0].value);
                      } else {
                        setFieldValue('codepostal', '');
                        changeZipTowns(undefined);
                      }

                      setSelectedZip(selectedValues);
                    }}
                    values={selectedZip}
                    options={zipOptions}
                    labelField="label"
                    valueField="value"
                  />
                  {errors.codepostal && <div className="error_text">{errors.codepostal}</div>}
                </div>
                <div className="col">
                  <label>Ville</label>
                  {/* <input
                    id="ville"
                    name="ville"
                    type="text"
                    onChange={handleChange}
                    value={values.ville}
                  /> */}
                  <Select
                    name="ville"
                    searchable
                    multi={false}
                    onChange={selectedValues => {
                      console.log('Selected ville: ', selectedValues);
                      if (selectedValues && selectedValues.length) {
                        setFieldValue('ville', selectedValues[0].value);
                      } else {
                        setFieldValue('ville', '');
                      }
                      setSelectedTown(selectedValues);
                    }}
                    values={selectedTown}
                    options={zipTowns}
                    labelField="label"
                    valueField="value"
                  />
                  {errors.ville && <div className="error_text">{errors.ville}</div>}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Commentaire</label>
                  <textarea
                    id="commentaire"
                    name="commentaire"
                    onChange={handleChange}
                    value={values.commentaire}
                    rows={2}
                  ></textarea>
                  {errors.commentaire && <div className="error_text">{errors.commentaire}</div>}
                </div>
              </div>
            </div>
          }
          <div className="row">
            <div className="col">
              <button className="btn button_yellow_on_white box_shadow_light" type="submit">Continuer</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setUser,
  goToPath
}, dispatch);

const Component = (window as any).cncMode === 'onsite' ? OnsiteUserComponent : UserComponent;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
