import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { LOCAL_SERVER } from './../../../libs/index';

let this_component:any;
interface Props {

}
class MaintenanceComponentComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  state = {
    desactivate_maintenance: true
  };
  render():Array<JSX.Element> {
    this_component = this;
    if (window.location.hash==='#redirect_to_begin' && this.state.desactivate_maintenance===true) {
      return [(<div></div>)];
    }
    return [
      (
        <div className="maintenance_container">
          <h1>Cette borne est hors services </h1>
          <h2>Veuillez nous excusez de ce désagrément.</h2>
        </div>
      )
    ];
  }

  number_call_server = 0;
  call_server() {
    setTimeout(() => {
      // console.log(this.number_call_server);
      if (this.number_call_server===10) {
        this.setState({desactivate_maintenance: false});
      }
      this.number_call_server ++;
      console.log(10 * (this.number_call_server + this.number_call_server));
      axios.get(LOCAL_SERVER + '/').then((e) => {
      // axios.get('http://qsdqsdqsd.com/').then((e) => {
        window.location.pathname = '/';
      }).catch((e) => {
        this.call_server();
      });
    }, 10 * (this.number_call_server + this.number_call_server));
  }
  componentDidMount() {
    if (this.number_call_server!==0) { return; }
    this.call_server()
  }
}
function mapStateToProps(state:any) {
    if(this_component!==undefined) {

    }
    return {
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    // goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceComponentComponent);
