
import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { bindActionCreators } from 'redux';
import { CONFIGURATION_PAGE } from '../../libs/config';
import { goToPath } from './../../actions/index';
import { isBasicWifiOrder, isGastronomicRestaurant, is_electron_browser, staticFile } from './../../libs/index';
var createReactClass = require('create-react-class');

// https://stackoverflow.com/questions/40673131/react-slick-not-working-on-first-render
interface Props {
  goToPath: Function,
}

function media_type(image, config, index_position) {
  let media_type = config.ico_size.mime.split('/')[0]
  if (media_type === 'image') {
    return (<img src={image} />)
  } else if (media_type === 'video') {
    return (
      <video
        data-video-id={"4093372393001" + index_position}
        data-account={"1507807800001"}
        data-player={"ry3j6rsze" + index_position}
        data-embed="default"
        data-application-id
        loop={slider_config.fileList.length === 1}
        className={'home_slide_video' + index_position}
        id={'home_slide_video' + index_position}
        width={config.ico_size.x}
        height={config.ico_size.y}>
        <source src={image}
          type={config.ico_size.mime} />
      </video>
    );
  }
}

var Item = createReactClass({
  render: function () {
    return (
      <div {...this.props}
        key={'slide' + this.props.index} className="slide_div">
        {media_type(this.props.image, this.props.config, this.props.index_position)}
      </div>
    )
  }
});

function generateSlider(slider_config) {
  let extracted = [];
  for (let file of slider_config.fileList) {
    extracted.push(staticFile(file.ico));
  }
  return extracted;
}

function getNiceSliderConfig() {
  if (is_electron_browser()) {
    slider = (window as any).data.design_slider.FOR_BORNE;
  } else {
    slider = (window as any).data.design_slider.FOR_MOBILE;
  }
  return slider;
}

let slider_config = getNiceSliderConfig();
let slider: any = generateSlider(slider_config);
class ReactSlickDemo extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      swipeToSlide: false,
      touchMove: false,
      accessibility: false,
      cssEase: "linear",
    };
    let map_slide: Array<JSX.Element> = [];
    let i = 0;
    for (let slide_image of slider) {
      console.log(slider_config)
      console.log(slider_config.fileList[i])
      map_slide.push((<div key={i}><Item image={slide_image} index={i} config={slider_config.fileList[i]} index_position={i} /></div>));
      i++;
    }

    return (
      <div className="container-fluid">
        <Slider {...settings} key="function_slide">
          {map_slide}
        </Slider>
      </div>
    );
  }
}

class HomeComponent extends React.Component<Props> {
  state: any = {
    show_slide: false,
  }
  lets_go() {
    document.documentElement.style.overflow = 'auto';
    if (isGastronomicRestaurant()) {
      this.props.goToPath({
        'action': 'history',
        'id': '/table/number/',
      });
      return;
    }
    if (CONFIGURATION_PAGE.youfid_login) {
      this.props.goToPath({
        'action': 'history',
        'id': '/fidelity/has_fidelity_card/',
      });
    } else {
      this.props.goToPath({
        'action': 'history',
        'id': '/order/',
      });
    }
  }

  show_slide(show_slide: boolean) {
    if (show_slide) {
      return (<ReactSlickDemo />)
    }
  }

  go_to_click_and_collect() {
    // if (!isClickAndCollect()) { return false; }
    setTimeout(() => {
      this.props.goToPath({
        'action': 'history',
        'id': '/user/',
      });
    });

    return true;
  }

  go_to_order_or_youfid() {
    if (!isBasicWifiOrder()) { return false; }
    if ((window as any).data.configuration_page.youfid_login) {
      this.props.goToPath({
        'action': 'history',
        'id': '/fidelity/has_fidelity_card/',
      });
      return true;
    }
    this.props.goToPath({
      'action': 'history',
      'id': '/order/',
    });
    return true;
  }

  render() {
    (window as any).react_history = (this.props as any).history;
    if (this.go_to_click_and_collect()) { return (<></>); }
    if (isGastronomicRestaurant()) {
      this.props.goToPath({
        'action': 'history',
        'id': '/table/number/',
      });
      return (<></>);
    }
    document.documentElement.style.overflow = 'hidden';
    return (
      <div className="fluid-container home_container" onClick={() => this.lets_go()}>
        {this.show_slide(this.state.show_slide)}

        <div className="row justify-content-center">
          <div className="button_yellow_on_white">
            TOUCHEZ POUR COMMANDER
          </div>
        </div>
      </div>

    );
  }

  resizeElementForMobile() {
    $(document).ready(() => {
      let innerHeight = window.innerHeight;
      let innerWidth = window.innerWidth;
      if (slider_config.fileList.length === 0) { return; }
      let image_size = slider_config.fileList[0].ico_size;

      $('.home_container .container-fluid img').css('height', 'unset');
      $('.home_container .container-fluid img').css('width', innerWidth + 'px');

      let div_height = image_size.y * innerWidth / image_size.x;
      let space_for_button = innerHeight - div_height;
      if (space_for_button < 0) { return; }
      if (space_for_button < 122) {
        $('.home_container .button_yellow_on_white').css('color', 'transparent');
      }
      $('.home_container .button_yellow_on_white').css('bottom', '-' + div_height + 'px');
      $('.home_container .button_yellow_on_white').css('display', 'block');
    });
  }

  resizeElementForTerminal() {
    $(document).ready(() => {
      if (slider_config.fileList.length === 0) { return; }
      let image_size = slider_config.fileList[0].ico_size;
      if (image_size.x !== 1080 && image_size.y !== 1920) {
        $('.home_container .button_yellow_on_white').css('display', 'block');
      } else {
        $('.home_container .button_yellow_on_white').css('display', 'none');
      }
    });
  }

  componentDidMount() {
    if (is_electron_browser()) {
      this.resizeElementForTerminal();
    } else {
      this.resizeElementForMobile();
    }
    setTimeout(() => {
      this.setState({ 'show_slide': true });
    }, 0 * 1000);
    $(document).ready(() => {
      this.autoplay();
    });
  }

  position_cursor = -window.innerWidth;
  slide_autoplay = undefined;

  play_media_player() {
    let position = (this.position_cursor / window.innerWidth * -1) - 2;
    if (slider_config.fileList[position + 1] === undefined) {
      position = 0;
    } else {
      position += 1;
    }
    let config = slider_config.fileList[position];
    if (config.ico_size.mime.split('/')[0] !== 'video') {
      return 5;
    }
    ($('.home_slide_video' + position) as any).trigger('play');
    return parseFloat(config.ico_size.duration)
  }

  get_duration() {
    if (slider_config.fileList[0] === undefined) { return 5 }
    return parseFloat(slider_config.fileList[0].ico_size.duration);
  }

  code_manual_slide() {
    if (window.location.pathname !== '/') { return; }
    // if (window.location.pathname!=='/') {
    //   clearInterval(this.slide_autoplay);
    //   return;
    // }
    this.position_cursor = this.position_cursor - window.innerWidth;
    let duration = this.play_media_player();

    $('.slick-track').css('transform', 'translate3d(' + this.position_cursor + 'px, 0px, 0px)')
    $('.slick-track').css('transition', '-webkit-transform 500ms linear');

    if (this.position_cursor < -(window.innerWidth * slider.length)) {
      setTimeout(() => {
        this.position_cursor = -window.innerWidth;
        $('.slick-track').css('transform', 'translate3d(' + this.position_cursor + 'px, 0px, 0px)')
        $('.slick-track').css('transition', 'unset');
      }, 1000);
    }
    setTimeout(() => {
      this.code_manual_slide();
    }, duration * 1000);
  }

  manual_slide() {
    let duration = this.get_duration();
    setTimeout(() => {
      this.code_manual_slide();
    }, duration * 1000);
    // }, 5 * 1000);
  }

  video_first_run() {
    let id = document.getElementById('home_slide_video0');
    if (id !== null) {
      (id as any).play();
    }
  }

  autoplay() {
    $(document).ready(() => {
      this.video_first_run();
      if (slider.length < 2) {
        return;
      }
      this.manual_slide();
    });
  }
}

function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    goToPath: goToPath,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
