
import { LOCAL_SERVER } from './index';

var CryptoJS = require("crypto-js");
var axios = require("axios").default;

function byteToHexString(uint8arr) {
  if (!uint8arr) {
    return "";
  }

  var hexStr = "";
  for (var i = 0; i < uint8arr.length; i++) {
    var hex = (uint8arr[i] & 0xff).toString(16);
    hex = hex.length === 1 ? "0" + hex : hex;
    hexStr += hex;
  }

  return hexStr.toUpperCase();
}

function hexStringToByte(str) {
  if (!str) {
    return new Uint8Array();
  }

  var a = [];
  for (var i = 0, len = str.length; i < len; i += 2) {
    a.push(parseInt(str.substr(i, 2), 16));
  }

  return new Uint8Array(a);
}

function generateNonce(length) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

class requestToYoufid {
  base_url: string | URL = 'https://api.youfid.fr/dev/service/';
  you_fid = null;
  oauth = null;

  timestamp = undefined;
  nonce = undefined
  constructor(YouFiD) {
    this.you_fid = YouFiD;
  }

  get_service_url(service_path_url): any {
    return this.base_url + service_path_url;
  }

  Authorization(reqBody = undefined) {
    let merchant = this.you_fid.merchant;
    // Premiere requêtes
    if (merchant === undefined) { return ''; }
    // console.log("Init response", merchant);
    let sessionKey = merchant.session_key;
    let clientId = merchant.merchant_id;
    // console.log("Key Hex: ", sessionKey);

    let keyBytes = hexStringToByte(sessionKey);
    let key = new TextDecoder("utf-8").decode(keyBytes);
    // console.log("Key: ", key);

    let nonce = "123234";
    let timestamp = Math.floor(Date.now() / 1000);

    let dataToSign = JSON.stringify(reqBody) + clientId + nonce + timestamp;
    // console.log("Data to sign: ", dataToSign);
    let signature = CryptoJS.HmacSHA256(dataToSign, key).toString(CryptoJS.enc.Hex);

    let authHeader = `fid_client_id=${clientId},nonce=${nonce},timestamp=${timestamp},sign_algo=HMAC_SHA256,sign=${signature}`;
    // console.log("Auth header: ", authHeader);
    return authHeader;
  }

  Headers(reqBody = undefined) {
    let header = {
      'authorization': this.Authorization(reqBody),
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
    };
    // console.log(header)
    return header;
  }

  post(url, params = {}) {
    let authOptions = {
      method: 'POST',
      url: this.get_service_url(url),
      data: JSON.stringify(params),
      headers: this.Headers(params),
      json: true,
    }
    let paramsAxios = {
      method: 'POST',
      url: LOCAL_SERVER + '/you_fid_api/',
      data: { 'authOptions': JSON.stringify(authOptions) },
      json: true,
    }
    return axios(paramsAxios).catch((error) => {
      // console.log('error 404')
      // console.log(error)
    });
  }

  get(url) {
    let authOptions = {

    }
  }
}

class YouFiD {
  request = undefined;
  merchant = undefined;
  cc_merchant_detail = undefined;
  wait = undefined;
  constructor() {
    this.request = new requestToYoufid(this);
  }

  merchant_detail_is_loaded() {

  }
  merchant_is_authenticated() {
    if (this.merchant === undefined) {
      throw "You are not connected in you fid api run merchant_login(login, password).";
    }
    return true;
  }
  customer_is_authenticated() {

  }

  test_merchant_login(login = null, password = null) {
    return this.request.post('marchandInitMarchand.php', {
      login: login,
      password: password,
    });
  }

  merchant_login_test_connect = 0;
  merchant_login(login = null, password = null) {
    // -> Il faut apporter des améliorations dans le codes.
    this.request.post('marchandInitMarchand.php', {
      login: login,
      password: password,
    })
      .then((e) => {
        this.merchant = e['data'];
        this.merchant.merchant_id = parseInt(this.merchant.merchant_id);
      }).catch((e) => {
        console.log('reload code');
        setTimeout(function () {
          this.merchant_login_test_connect++;
          if (this.merchant_login_test_connect > 10) {
            // alert('Rediriger vers la pages de connections');
          }
          this.merchant_login(login, password);
        }, 100);
      });
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (this.merchant !== undefined) {
          resolve(this);
          clearInterval(interval);
        }
      }, 50);
    });
  }

  merchant_detail() {
    if (!this.merchant_is_authenticated()) { return; }
    // console.log({merchant_id: parseInt(this.merchant.merchant_id),})
    return this.request.post('marchandGetMerchantDetailsV2.php', {
      merchant_id: this.merchant.merchant_id,
    });
  }

  login_with_qr_code(qr_code) {
    if (!this.merchant_is_authenticated()) { return; }
    return this.request.post('testmarchandCheckScanUserIfExistsV2.php', {
      merchant_id: this.merchant.merchant_id,
      qr_code: qr_code
    });
  }

  login_with_mail(email) {
    if (!this.merchant_is_authenticated()) { return; }
    return this.request.post('testmarchandCheckScanUserIfExistsV2.php', {
      merchant_id: this.merchant.merchant_id,
      email: email,
    });
  }

  add_point_to_the_customer(qr_code, attribution_pts, amount = 0) {
    if (!this.merchant_is_authenticated()) { return; }
    let params: any = {
      merchant_id: this.merchant.merchant_id,
      qr_code: qr_code,
    };

    if (attribution_pts === '1') {
      params.amount = amount;
    }
    return this.request.post('marchandCheckScanUser.php', params);
  }

  find_user(usr_input) {
    return this.request.post('marchandListUsersV2.php', {
      merchant_id: parseInt(this.merchant.merchant_id),
      usr_input: usr_input,
    })
  }

  customer_exist(qr_code) {
    return this.request.post('testmarchandCheckScanUserIfExistsV2.php', {
      merchant_id: parseInt(this.merchant.merchant_id),
      qr_code: qr_code,
    });
  }

  synchronize_customer_table(qr_code) {
    return this.request.post('marchandSynchScan.php', {
      merchant_id: parseInt(this.merchant.merchant_id),
      qr_code: qr_code,
    });
  }

  register_customer(params) {
    if (!this.merchant_is_authenticated()) { return; }
    let base_request = {
      merchant_id: this.merchant.merchant_id,
      amount: 0,
      is_register: 1,
    };
    Object.assign(base_request, params);
    return this.request.post('marchandCheckScanUser.php', base_request)
  }

  burn_point(usr_id: any, products: Array<any>) {
    return this.request.post('transformToPresent.php', {
      merchant_id: parseInt(this.merchant.merchant_id),
      usr_id: usr_id,
      products: products,
    })
  }

  status_is_ok(req) {
    return (req.data.status === 'ok');
  }

  get_point_win(product_price) {
    let point_to = 10;
    return product_price * point_to;
  }

  logout_customer() {
    // TODO -> Effacer les données ayant à trait avec l'utilisateur.
  }

  logout_merchant() {
    // TODO -> Supprimer les données ayant à trait avec avec la partie marchande pour réinitiliser l'éléments.
  }
}

export const you_fid_client = new YouFiD();
