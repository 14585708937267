
import { ADD_TO_CART, is_redux_init, REDUCE_CART, SET_STORE_OPEN, UPDATE_DATA } from './../actions/index';
import { has_fidelity_product } from './../libs/fidelity';

const enterprise = (window as any).data.enterprise;
let shop = {
  tva_type: ['5.5', '10', '20'],
  message: {
    "for_ticket": enterprise.message_for_ticket
  },
  attribution_pts: undefined,
  name: enterprise.name,
  phone_numnber: {
    'mobile': enterprise.mobile_phone_number,
    'landline_telephone': enterprise.phone_number,
  },
  adress: {
    'street': enterprise.adress,
    'city': enterprise.city,
    'complement': enterprise.complement,
    'postal_code': enterprise.postal_code,
  },
  email: enterprise.email,
  site: enterprise.site,
  logo: { 'ico': '/public/src/donwload.png', 'before_ticket_print': true },
  pass_id: '889057',
  tva_number: enterprise.tva_intracommunautaire,
  rcs: enterprise.rcs,
  siret: enterprise.siret,
  fidelity_list_product: [],
  open_status: (window as any).storeOpen || 'open'
}

let fidelity_categoroy = {
  // -> Alors à quoi doit ressembler cette éléments
};

function find_product_in_fidelity_list(product: any): any | false {
  if (shop && shop.fidelity_list_product && shop.fidelity_list_product.length) {
    for (let fidelity_product_line of shop.fidelity_list_product) {
      let product_selected = fidelity_product_line.filter((x) => x.id == product.id)
      if (product_selected.length === 0) {
        continue;
      }
      return product_selected[0];
    }
  }

  return false;
}

export default function (state: any, action: any) {
  if (is_redux_init(action)) {
    return shop;
  }
  // console.log(action);
  switch (action.type) {
    case UPDATE_DATA:
      let newState;
      if (action.payload.for_reducer == "shop.fidelity_list_product") {
        newState = { ...state, fidelity_list_product: action.payload.data };
      } else if (action.payload.for_reducer === 'shop.init') {
        let data = action.payload.data;
        // state.fidelity_list_product = data.fidelity_list_product;
        // state.attribution_pts = data.attribution_pts;
        newState = { ...state, fidelity_list_product: data.fidelity_list_product, attribution_pts: data.attribution_pts };
        // console.log(state);
      }
      return newState;
    case ADD_TO_CART:
      if (!has_fidelity_product(action.payload.product)) { return state; }
      let product = find_product_in_fidelity_list(action.payload.product)
      if (product !== false) {
        let number = product.quantity;
        if (action.payload.hasOwnProperty('number')) {
          number = action.payload.number;
        }
        product.fidelity_quantity += number;
      }

      return state;
    case REDUCE_CART:
      if (!has_fidelity_product(action.payload.product)) { return state; }
      product = find_product_in_fidelity_list(action.payload.product)
      if (product !== false) {
        let number = product.quantity;
        if (action.payload.hasOwnProperty('number')) {
          number = action.payload.number;
        }
        product.fidelity_quantity -= number;
      }
      return state;
    case SET_STORE_OPEN:
      return { ...state, open_status: action.payload };

    default:
      return state
  }

}
