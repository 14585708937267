import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cartItemsSelector } from '../../../selectors';
import { addToCart, goToPath, logout } from './../../../actions/index';
import { is_electron_browser } from './../../../libs/index';
import PmrAccessComponent from './../../nav/pmr_access/pmr_access.component';

let this_component: any;
let this_component_usual_command: any;
interface Props {
  logout: Function,
  goToPath: Function,
  addToCart: Function,
  history: any,
  type: any
  cart_list: any,
  as_usual_command: any
  store_open_status: 'open' | 'close'
}
class FooterNavigationComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  state: any = {
    'cart_list': [],
  }
  checkout() {
    // Envoyer la commande seulement s'il y a un produit dans le panier
    if (this.state.cart_list.length - 1 != 0 && this.props.store_open_status === 'open') {
      if (this.props.type === 'as_usual_command') {
        this.props.addToCart({ 'product_list': this.state.cart_list });
      }

      if (is_electron_browser()) {
        this.props.goToPath({ 'action': 'popin', 'id': 'suggestion' });
      } else {
        this.props.goToPath({ 'action': 'history', 'id': '/order/suggestion/' });
      }
    }
  }
  render(): JSX.Element {
    if (this.props.type == 'as_usual_command') {
      this_component_usual_command = this;
      this.state.cart_list = this.props.as_usual_command;
    } else if (this.props.type == 'normal') {
      this_component = this;
      this.state.cart_list = this.props.cart_list;
    }
    return (
      <div className={(this.props.type == 'as_usual_command' ? '' : 'col-12') + " footer_navigation_container " + this.props.type}
        style={{ boxShadow: !is_electron_browser() ? 'unset' : '7px 0px 0.7em rgba(192, 192, 192, 0.57)' }}>

        <button style={{ 'display': this.props.type !== 'as_usual_command' ? 'inline-block' : 'none' }}
          className="btn cancel_button box_shadow_light mobile-hidden"
          onClick={() => this.props.goToPath({ 'action': 'popin', 'id': 'cancel_order' })}>
          ABANDONNER
        </button>
        <button style={{ 'display': this.props.type === 'as_usual_command' ? 'inline-block' : 'none' }}
          className="btn cancel_button box_shadow_light mobile-hidden"
          onClick={() => (window as any).components.PopInComponent.onHide()}>
          FERMER
        </button>
        <PmrAccessComponent type='little'></PmrAccessComponent>

        <button style={{ 'opacity': (this.state.cart_list.length - 1 != 0 && this.props.store_open_status === 'open') ? '1' : '0.5' }}
          className="btn  button_yellow_on_white box_shadow_light"
          onClick={() => this.checkout()}>
          {this.props.store_open_status === 'open' && 'VALIDER ET COMMANDER'}
          {this.props.store_open_status !== 'open' && 'SERVICE FERME'}
        </button>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const cart_list = cartItemsSelector(state);
  if (this_component !== undefined) {
    this_component.setState({ 'cart_list': cart_list });
  }

  if (this_component_usual_command !== undefined) {
    this_component_usual_command.setState({ 'cart_list': state.as_usual_command });
  }

  return {
    cart_list: cart_list,
    as_usual_command: state.as_usual_command,
    store_open_status: state.shop.open_status
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    logout: logout,
    goToPath: goToPath,
    addToCart: addToCart,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterNavigationComponent);
