import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import { AiFillForward } from 'react-icons/ai';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { bindActionCreators } from 'redux';
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { subCategoriesSelector } from '../../../selectors';
import { activeHandicappedVersion, addToCart, goToPath, reduceCart, update_data } from './../../../actions/index';
import { add_00_in_end, HEADER_HEIGHT, HEADER_NAVIGATION_HEIGHT, is_electron_browser, staticFile } from './../../../libs/index';
import { get_first_category } from './../../../reducers/request/product_list';

const default_conf = {
  'VERTICAL_SLICK': true,
};

const CONF = !(window as any).data.configuration_page.product_list ? default_conf : (window as any).data.configuration_page.product_list;

function show_suplement_price(is_big_product, product) {
  if (!is_big_product || product.unit_price === 0) { return false; }
  let selected_number = this_component.getStepSize(product.category);
  if (product.go_to && product.go_to.num_free_choice > selected_number) { return false; }
  return is_big_product && product.unit_price !== 0;
}

const Price = ({ price }) => {
  return price ? <div className="price">{add_00_in_end(price)} €</div> : null
};

const SupplementPrice = ({ price, display }) => {
  return price ? (
    <div className="suplement_price"
      style={{
        display: display ? 'block' : 'none'
      }}>{add_00_in_end(price)} €</div>
  ) : null;
};

function MapProduct(props: any): JSX.Element {

  return (
    <div className={
      [
        "product_choice " + ((props.is_big_product) ? 'without_price' : 'with_price'),
        props.product.hasOwnProperty('go_to') && props.product.go_to.max_choice !== 1 ? 'with_more_and_less' : 'without_more_and_less',
        props.product.id < 0 ? "visibility_hidden" : ""
      ].join(' ')
    }
      key={'product_' + props.product.id}>
      <div className={`product_choice_pack box_shadow_light ${props.product.out_of_stock ? 'out-of-stock' : ''}`}
        onClick={() => this_component.click_product(props.product)}>
        <div className="imageContainer"
          style={{ backgroundImage: 'url("' + staticFile(props.product.ico) + '")' }}>
          <i onClick={(event) => this_component.open_description_product(event, props.product)}></i>
          {/* <div className="point_win">+ {you_fid_client.get_point_win(props.product.unit_price)} pts</div> */}
          <SupplementPrice price={props.product.unit_price} display={show_suplement_price(props.is_big_product, props.product)} />
          {props.product.out_of_stock ? <div className="out-of-stock-banner"></div> : ''}
          <div className="quantity_bulle" style={{ display: props.is_big_product && props.quantity ? 'block' : 'none' }} >{props.quantity}</div>
        </div>
        <div className="product_text_container">
          <div className="product_name"><p>{props.product.name}</p></div>
          <Price price={props.product.unit_price} />
        </div>
      </div>
      <ul className="button_more_and_less">
        <li onClick={() => this_component.reduce_product_to_selection(props.product)}
          className='less_in_catalogue box_shadow_light'
          style={{ visibility: props.quantity !== 0 ? 'visible' : 'hidden' }}>-</li>
      </ul>
    </div>
  )
}

function map_product_list(product_list: any, is_big_product: boolean, selectedItems: Array<any>): Array<JSX.Element> {
  let break_point = 0;
  if (!is_big_product) {
    break_point = (2 * 2);
  } else {
    break_point = (3 * 2);
  }

  let base_point = 0;
  let i = 0;
  let map_carrousel: Array<JSX.Element> = [];
  while (base_point <= product_list.length - 1) {
    let new_items_list: Array<JSX.Element> = [];
    let product_part = Object.assign([], product_list.slice(base_point, base_point + break_point));
    if (product_part.length !== break_point) {
      for (let j = 0; (break_point - product_part.length) >= j; j++) {
        let virtual_product = Object.assign({}, product_part[0]);
        virtual_product.id = -(Math.random() * 0.1);
        product_part.push(virtual_product);
      }
    }
    for (let product of product_part) {
      const foundItems = selectedItems.filter(it => it.id === product.id);
      const quantity = foundItems && foundItems.length ? foundItems[0].quantity : 0;
      new_items_list.push(<MapProduct is_big_product={is_big_product} product={product} quantity={quantity} />)
    }
    map_carrousel.push((
      <div>
        {new_items_list}
      </div>
    ));
    i += 1;
    base_point += break_point;
  }

  return map_carrousel;
}

function defineSlideHeight(is_big_product) {
  if (is_electron_browser()) {
    return is_big_product ? '626px' : '835px';
  }
  // -> Ici ont calcule la dimensions de l'écran de l'utilisateur -
  // - Le header
  // - Le menu d'en haut
  // - la dimensions fermers de l'élément panier
  return is_big_product ? '150px' : '466px';
}

function ProductCarrousel(props) {
  // const [index, setIndex] = useState(0);
  // const [direction, setDirection] = useState(null);
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  //   setDirection(e.direction);
  // };
  var settings: any = {
    cssEase: "linear"
  };
  if (CONF.VERTICAL_SLICK) {
    settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: false,
      cssEase: "linear",
      dots: true,
      vertical: true,
      verticalSwiping: true,
    };
  }
  return (
    <Slider {...settings}
      style={{ height: defineSlideHeight(props.is_big_product) }}
      interval={3600 * 1000}
      className={[
        props.is_big_product !== true ? "product_with_price_container" : '',
        CONF.VERTICAL_SLICK ? 'vertical_slide' : 'horizontal_slide'
      ].join(' ')}>
      {map_product_list(props.product_list, props.is_big_product, props.selectedItems)}
    </Slider>
  );
}

// import { is_electron_browser } from ''
let this_component: any;
interface Props {
  product_list: Array<any>,
  goToPath: Function,
  addToCart: Function,
  update_data: Function,
  activeHandicappedVersion: Function,
  reduceCart: Function,
  subCategories: any[],
  go_to: any
};

/**
 * Products list components
 */
class ProductListComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    const defaultCategory = get_first_category();
    this.state = {
      'number_of_selection': 0,
      'size_list': [],
      'your_selection': [],
      'title': defaultCategory ? defaultCategory.name : '',
      'sub_title': '',
      'product_list': [],
      'selected_product': {},
      'container_size': {
        'width': undefined,
        'height': undefined,
      },
      nextProductPage: true,
      selectionPath: {},
      stepCount: 0,
      currentStep: 0
    };

    this.getStepSize = this.getStepSize.bind(this);
    this.getSelectedProducts = this.getSelectedProducts.bind(this);
    this.nextProductPage = this.nextProductPage.bind(this);
    this.gotoStep = this.gotoStep.bind(this);
  }

  state: any;
  path: Array<any> = [];
  pwd: any = 0;

  animate_scroll_to_end() {
    setTimeout(() => {
      $('.your_selection_rail').animate({
        scrollLeft: 5000
      }, 200);
    }, 10)
  }

  map_selection(selection: any, path_rule: any) {
    //console.log('function>>map_selection')
    let selection_copy = Object.assign({}, selection);
    Object.assign(selection_copy, {
      'go_to': this.state.go_to,
      'quantity': 1,
      'path_rule': path_rule,
    });
    return selection_copy;
  }

  click_to_selection(selection: any, step: number) {
    this.gotoStep(step);
  }

  get_category_in_your_selection(go_to) {
    //console.log('function>>get_category_in_your_selection')
    let category = [];
    for (let selection_line of this.state.your_selection) {
      if (selection_line.go_to.id === go_to.id) {
        category.push(selection_line);
      }
    }
    return category;
  }

  get_category_size_in_your_selection(go_to) {
    //console.log('function>>get_category_size_in_your_selection')
    let category = this.get_category_in_your_selection(go_to);
    let size = 0;
    for (let selection_line of category) {
      if (selection_line.hasOwnProperty('quantity')) {
        size += selection_line.quantity;
      }
    }
    return size;
  }

  push_in_your_selection(product) {
    console.log('function>>push_in_your_selection')
    let i = 0;
    let lock = false;
    // console.log(product);
    // console.log(this.state.your_selection);

    for (let selection_line of this.state.your_selection) {
      if (selection_line.name === 'return_in_command') {
        this.state.your_selection.splice(i, 0, product);
        return;
      } else if (selection_line.go_to.id === product.go_to.id) {
        lock = true;
        i++;
        continue
      } else if (lock) {
        this.state.your_selection.splice(i, 0, product);
        this.animate_scroll_to_end();
        return;
      }

      i++;
    }

    if (lock) {
      this.state.your_selection.splice(i + 1, 0, product);
      this.animate_scroll_to_end();
      return;
    }
  }

  reduce_product_to_selection(product: any) {

    const { selectionPath } = this.state;
    const stepItems = selectionPath[product.category].items;
    const index = stepItems.map(it => it.id).indexOf(product.id);

    if (index !== -1) {
      const item = stepItems[index];
      if (item.quantity > 1) {
        item.quantity--;
      } else {
        stepItems.splice(index, 1);
      }
    }

    this.setState({
      selectionPath: { ...selectionPath }
    });
  }

  im_in_your_selection_navigation() {
    //console.log('function>>im_in_your_selection_navigation')
    if (this.state.your_selection.length === 0) {
      return false;
    }
    return (this.state.your_selection.slice(-1)[0].name === 'return_in_command');
  }

  map_your_selection_line(selection_line: any, step) {
    // console.log('function>>map_your_selection')
    if (selection_line && selection_line.name == 'return_in_command') {
      return (
        <div onClick={() => this.gotoStep(step)}
          className='product_in_selection return_in_command'>
          <div className="number"></div>
          <div className="name">SUIVANT</div>
        </div>
      );
    }

    return (
      <div onClick={() => this.click_to_selection(selection_line, step)}
        className={'product_in_selection box_shadow_light ' + (selection_line && selection_line.selected ? 'selected' : '')}
        style={{
          backgroundImage: 'url("' + staticFile(selection_line?.ico) + '")',
          display: selection_line && selection_line.quantity === 0 ? 'none' : 'inline-block',
        }}>
        <div className="number"
          style={{ visibility: selection_line?.quantity === 1 ? 'hidden' : 'unset' }}>{selection_line?.quantity}</div>
        <div className="name">{selection_line?.name}</div>
      </div>
    );
  }

  get_your_selection_width(your_selection): string {
    let your_selection_real_length = 0;
    for (let selection_line of your_selection) {
      your_selection_real_length += selection_line.quantity !== 0 ? 1 : 0;
    }
    return is_electron_browser() ? ((your_selection_real_length + 1) * 161) + 'px' :
      ((your_selection_real_length + 2) * (69 + 7)) + 'px'
  }

  renderNextButton() {
    let stepSelection;
    let categoryId;
    let step;
    if (this.pwd > 0) {
      step = this.path[this.pwd - 1];
      categoryId = step.id;
      stepSelection = this.state.selectionPath[categoryId];
    }

    if (stepSelection) {
      const stepSize = this.getStepSize(categoryId);
      if (stepSize === 0) {
        return (
          <button
            className="continue button_yellow_on_white no-thanks-button"
            onClick={() => this.nextProductPage()}
            style={{ display: this.path && this.path.length && this.getStepRemaining() == 0 ? 'block' : 'none' }}
          >
            NON, MERCI
          </button>
        );
      } else {
        return (
          <button
            className="continue button_yellow_on_white no-thanks-button suivant"
            onClick={() => this.nextProductPage()}
            style={{ display: this.path && this.path.length && this.getStepRemaining() == 0 ? 'flex' : 'none' }}
          ><span style={{ paddingRight: '5px' }}>SUIVANT</span><AiFillForward /></button>
        );
      }
    } else {
      return (
        <button
          className="continue button_yellow_on_white no-thanks-button"
          onClick={() => this.nextProductPage()}
          style={{ display: this.path && this.path.length && this.getStepRemaining() == 0 ? 'block' : 'none' }}
        >
          NON, MERCI
        </button>
      );
    }
  }

  map_next_button(className = 'terminal-hidden-block') {
    // if (is_electron_browser()) {
    //   return (
    //     <div className={"nextProductPage moove " + className}>
    //       <button className="precedent"
    //         onClick={() => this_component.precentProductPage()}
    //         style={{ display: this.displayPrecedentProductPageButton() ? 'block' : 'none' }}
    //       >prec</button>
    //       {this.renderNextButton()}
    //     </div>
    //   );
    // }

    return (
      <span className={"nextProductPage moove " + className}>
        {this.renderNextButton()}
      </span>
    );
  }

  getSelectedProducts() {
    const { selectionPath } = this.state;
    const keys = Object.keys(selectionPath);
    let items = [];
    for (const k of keys) {
      if (selectionPath[k] && selectionPath[k].items && selectionPath[k].items.length) {
        items = [...items, ...selectionPath[k].items];
      }
    }

    return items;
  }

  getSelectionBoxSize() {
    const { selectionPath } = this.state;

    const keys = Object.keys(selectionPath);
    let count = 0;

    keys.forEach(k => {
      if (selectionPath[k] && selectionPath[k].items) {
        const { items } = selectionPath[k];
        count = items.reduce((prev, item) => {
          const actual = prev + (item.quantity ? 1 : 0);
          return actual;
        }, count);
      }
    });

    return `${count * 162}px`;
  }

  getStepRemaining() {
    let remaining = 0;
    if (this.pwd > 0) {
      const path = this.path[this.pwd - 1];
      if (path) {
        const stepSize = this.getStepSize(path.id);
        if (stepSize < path.min_choice) {
          remaining = path.min_choice - stepSize;
        }
      }
    }

    return remaining;
  }

  mapSelectedItems() {
    if (!this.product_without_price(this.state.selected_product)) {
      return;
    }

    const { selectionPath } = this.state;
    let mapped = [];
    const keys: string[] = Object.keys(selectionPath);
    const entries = keys.entries();

    for (const [i, key] of entries) {
      const { items, step } = selectionPath[key];
      if (i === 0) {
        mapped = [...mapped, this.map_your_selection_line(items[0], step)];
      } else {
        mapped = [...mapped, ...items.map(it => this.map_your_selection_line(it, step))];
      }
    }

    return [(
      <div
        className="your_selection">
        <p>Votre selection</p>
        <div className="your_selection_rail">
          <div style={{ width: this.getSelectionBoxSize() }}>
            {mapped}
          </div>
        </div>
      </div>
    ), (
      <div className="sub_title_container">
        <h2 className="subtitle">{this.state.sub_title}</h2>
        {this.map_next_button()}
      </div>
    )];
  }

  map_your_selection(selected_product: any, your_selection: any) {
    if (!this.product_without_price(selected_product)) { return; }
    let mapped = [];
    let i = 0;
    for (let selection_line of your_selection) {
      if (selection_line.ingredient && i !== 0) {
        continue;
      }
      mapped.push(this.map_your_selection_line(selection_line, 0));
      i++;
    }
    return [(
      <div
        className="your_selection">
        <p>Votre selection</p>
        <div className="your_selection_rail">
          <div style={{
            width: this.get_your_selection_width(your_selection),
          }}>
            {mapped}
          </div>
        </div>
      </div>
    ), (
      <div className="sub_title_container">
        <h2 className="subtitle">{this.state.sub_title}</h2>
        <h2 className="number_of_selection">{this.getStepRemaining()} Sélection(s)</h2>
        {this.map_next_button()}
      </div>
    )]
  }

  configureProduct() {
    const items = this.getSelectedProducts();
    const product = items[0];
    product.complement = [];
    for (let i = 1; i < items.length; i++) {
      if (!items[i].ingredient && items[i].quantity > 0) {
        product.complement.push(items[i]);
      }
    }

    const allProducts = [product];
    for (let i = 1; i < items.length; i++) {
      if (items[i].ingredient && items[i].quantity > 0) {
        allProducts.push(items[i]);
      }
    }

    return allProducts;
  }

  generate_product_with_complement() {
    let product = this.state.your_selection[0];
    product.complement = [];
    for (let complement_line of this.state.your_selection.slice(1, 1000)) {
      if (complement_line.ingredient || complement_line.quantity === 0) { continue; }
      product.complement.push(complement_line);
    }
    return product;
  }

  // --------------------------------------------------- Your Selection END;
  // ------------------------------------------------------------------- PATH BEGIN;
  add_sans_element(product) {
    // -> Commencer par transformer cette éléments en listes.
    if (product.ingredient_list === undefined) { return; }
    for (let ingredient of product.ingredient_list) {
      if (!ingredient.hasOwnProperty('ingredient_selected')) { continue; }
      if (ingredient.ingredient_selected) { continue; }
      if (!product.hasOwnProperty('complement')) {
        product.complement = [];
      }

      let new_ingredient: any = {};
      Object.assign(new_ingredient, ingredient);
      Object.assign(new_ingredient, {
        'unit_price': 0,
        'total_price': 0,
        'go_to': {
          'num_free_choice': 0,
        }
      });

      if (new_ingredient.name.slice(0, 4) !== 'SANS') {
        new_ingredient.name = 'SANS ' + new_ingredient.name;
      }

      product.complement.push(new_ingredient)
    }

    // -> Rétablir l'éléments
    for (let ingredient of product.ingredient_list) {
      ingredient.ingredient_selected = true;
    }
  }

  gotoStep(step: number) {
    if (step > this.path.length) {
      this.configureProduct().forEach(p => {
        this.add_sans_element(p);
        this.props.addToCart({ 'product': p });
      });

      const items = this.getSelectedProducts();
      const categoryId = items[0].category;
      this.reset();

      setTimeout(() => {
        this.props.goToPath({
          'action': 'category',
          'id': categoryId,
          'from': 'end_of_path'
        });
      }, 200);
    } else {
      this.setState({ currentStep: step }, () => {
        this.go_to(this.path[step - 1]);
        this.pwd = step;
      });
    }
  }

  have_path(product): boolean {
    return (product.hasOwnProperty('path') && product.path.length !== 0)
  }

  update_path(product): boolean {
    if (this.have_path(product) && this.path.length === 0) {
      //      // console.log('function: update_path is true')
      this.path = product.path;
      this.pwd = 0;
      product.category_id = this.state.go_to.id;
      this.setState({
        'selected_product': product,
      });
    }

    return this.have_path(product);
  }

  getStepSize(category) {
    const { selectionPath } = this.state;
    if (category in selectionPath) {
      const stepSelection = selectionPath[category];
      return stepSelection.items.reduce((prev, item) => prev + item.quantity, 0);
    }

    return 0;
  }

  click_product(product: any): void {
    // console.log('>>>>>> Choosed', product);
    // console.log('>>>>>> Props', this.props);

    if (product.out_of_stock) {
      return;
    }

    this.update_path(product);

    let selectionPath = {};
    if (this.have_path(product)) {
      console.log('>>>>> Path: ', product.path);
      this.setState({ selectionPath, stepCount: product.path.length, currentStep: 0 });
    } else {
      selectionPath = this.state.selectionPath;
    }

    let stepSize;
    let stepRule;
    if (product.category in selectionPath) {
      const stepSelection = selectionPath[product.category];

      stepRule = stepSelection.stepRule;
      stepSize = this.getStepSize(product.category);

      if (stepRule && stepRule.max_choice === 1) {
        if (stepSelection.items && stepSelection.items.length) {
          stepSelection.items[0] = { ...product, quantity: 1 }
        } else {
          stepSelection.items.push({ ...product, quantity: 1 });
        }
      } else if (stepRule && stepSize < stepRule.max_choice) {
        const foundItems = stepSelection.items.filter(it => it.id === product.id);
        if (foundItems && foundItems.length) {
          foundItems[0].quantity++;
          stepSize++;
        } else {
          stepSelection.items.push({ ...product, quantity: 1 });
          stepSize++;
        }
      } else {
        console.error('Path rule not defined or max choice reached');
      }
    } else {
      if (product.go_to && product.go_to.action !== 'popin') {
        stepRule = product.go_to;
      }

      stepSize = 1;
      selectionPath[product.category] = {
        stepRule,
        items: [product],
        step: this.pwd
      };
    }

    this.setState({
      selectionPath: { ...selectionPath }
    });

    if (!stepRule || stepSize >= stepRule.max_choice) {
      this.gotoStep(this.pwd + 1);
    }

    this.animate_scroll_to_end();
  }

  open_description_product(event: any, product: any) {
    this.props.goToPath({
      'action': 'popin',
      'id': 'product_description',
      'product': product,
      'selected_product': this.product_without_price(),
    });
    event.preventDefault();
    event.stopPropagation();
  }

  carrousel_key: any = Math.random();
  inmap_product_list(product_list: Array<any>): Array<JSX.Element> {
    let product_list_mapped: Array<JSX.Element> = [];
    let is_big_product: boolean = this.product_without_price(this.state.selected_product);

    // >> Génération de la partie slide.
    if (is_electron_browser() && !is_big_product && (product_list.length / (2 * 2)) > 1) {
      return [(
        <ProductCarrousel key={'carrousel' + this.carrousel_key} product_list={product_list} is_big_product={is_big_product} selectedItems={this.getSelectedProducts()} />
      )];
    } else if (is_electron_browser() && is_big_product && product_list.length / (3 * 2) > 1) {
      return [
        (<ProductCarrousel key={'carrousel' + this.carrousel_key} product_list={product_list} is_big_product={is_big_product} selectedItems={this.getSelectedProducts()} />),
        (this.map_next_button('mobile-hidden'))
      ];
    }

    let new_product_list = Object.assign([], product_list);
    if (!is_electron_browser()) {
      let virtual_product_1 = Object.assign({}, new_product_list[0]);
      let virtual_product_2 = Object.assign({}, new_product_list[0]);
      virtual_product_1.id = -(Math.random() * 0.1);
      virtual_product_2.id = -(Math.random() * 0.1);
      new_product_list.push(virtual_product_1)
      new_product_list.push(virtual_product_2)
    }

    const selectedItems = this.getSelectedProducts();
    for (let product of new_product_list) {
      const foundItems = selectedItems.filter(it => it.id === product.id);
      const quantity = foundItems && foundItems.length ? foundItems[0].quantity : 0;
      product_list_mapped.push(<MapProduct is_big_product={is_big_product} product={product} quantity={quantity} />);
    }

    return [(
      <div className="product_container">
        {product_list_mapped}
      </div>
    )/*, (
      this.map_next_button('mobile-hidden')
    )*/];
  }


  // **************************** RENDER ELEMENT
  product_without_price(selected_product: any = undefined): boolean {
    if (selected_product === undefined) {
      selected_product = this.state.selected_product;
    }
    return Object.keys(selected_product).length !== 0;
  }

  getCategoryTitle(catId) {
    const categories = (window as any).data.category.filter(c => c.id == catId);
    if (categories && categories.length) {
      return categories[0].title || categories[0].name;
    }

    return null;
  }

  // Définir le titre et le sous titre selon le code en cours d'executions.
  define_title_and_subtitle() {
    console.log('Define title: ', (this.props as any).go_to);

    setTimeout(() => {
      if ((this.props as any).go_to.action !== 'category') { return; }
      const items = this.getSelectedProducts();
      const { go_to } = (this.props as any);
      // console.log('Define title 2: ', go_to);

      if (!items || items.length === 0) {
        this.setState({
          'title': this.getCategoryTitle(go_to.id) || go_to.title || go_to.name,
        });
      } else {
        // console.log('Define title 3: ', go_to);
        this.setState({
          'title': items[0].name,
          'sub_title': go_to.title || go_to.name,
        });
      }
    }, 5);
  }

  displayPrecedentProductPageButton() {
    let go_to = this.state.go_to;
    let i = 0;
    for (let path_line of this.path) {
      if (i > 0) { return true }
      if (path_line.id === go_to.id) { break; }
      i += 1
    }
    return false;
  }

  clickable_precedent = false;
  precentProductPage() {
    let go_to = this.state.go_to;
    let i = 0;
    for (let path_line of this.path) {
      if (go_to.id === path_line.id) { break; }
      i += 1;
    }
    this.pwd = i - 1;
    this.go_to(this.path[this.pwd]);
    this.pwd++;
    this.clickable_precedent = true;
  }

  displayNextProductPageButton() {
    // if (this.state.go_to.min_choice === 0) {
    //   return true;
    // } else 
    if (!this.product_without_price(this.state.selected_product)) {
      return false;
    } else {
      let selected_number = this.state.go_to.max_choice - this_component.state.number_of_selection;
      //    // console.log(this.state.go_to.min_choice);
      //    // console.log(selected_number);
      return (this.state.go_to.min_choice <= selected_number);
    }
  }

  nextProductPage() {
    this.gotoStep(this.pwd + 1);
  }

  calculate_number_of_selection() {
    //console.log('------------------------------------------------------------------- calculate_number_of_selection')
    if (!this.state.go_to.hasOwnProperty('max_choice')) {
      this.state.go_to.max_choice = 1
    }

    if (!this.state.go_to.hasOwnProperty('num_free_choice')) {
      this.state.go_to.num_free_choice = 0;
    }

    let category_size = this.get_category_size_in_your_selection(this.state.go_to);
    this.state.number_of_selection = this.state.go_to.max_choice - category_size;

    if (this.state.number_of_selection <= -1) {
      this.state.number_of_selection = 0;
    }

    return 0;
  }

  private submenuSlider;
  nextSubmenuPage = () => {
    this.submenuSlider.slickNext();
  }
  previousSubmenuPage = () => {
    this.submenuSlider.slickPrev();
  }

  render(): Array<JSX.Element> {
    //console.log('----------------------------------------------- RENDER');
    (window as any).components.ProductListComponent = this;
    this_component = this;
    if (this.state.product_list.length === 0) {
      this.state.product_list = this.props.product_list;
      this.state.go_to = (this.props as any).go_to;
    }

    const availability = this.getAvailability();

    // -> Calculate number to selection elements.
    this.calculate_number_of_selection();
    if (availability.available) {
      const { subCategories } = this.props;
      if (subCategories && subCategories.length) {
        const { innerWidth } = window;

        const settings = {
          dots: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          rows: 3,
          slidesPerRow: innerWidth < 500 ? 2 : 3
        };

        const showSubmenuNav = subCategories.length > 9;

        return [
          <>
            <div className="parent-menu">
              <h2>{this.props.go_to.name}</h2>
            </div>
            <div className="submenu-wrapper">
              {showSubmenuNav && <div className="go-prev-button"><UpCircleFilled onClick={this.previousSubmenuPage} /></div>}
              <Slider
                className="submenu-container"
                ref={slider => (this.submenuSlider = slider)}
                {...settings}
              >
                {
                  this.props.subCategories.map(cat => (
                    <div style={{width: '33%'}}>
                      <div
                        className={`menu-item box_shadow_light`}
                        onClick={() => this.props.goToPath(cat)}
                        style={{ backgroundImage: 'url("' + cat.ico + '")' }}
                        key={"category_" + cat.id}
                        data-ico={cat.ico}
                      >
                        <div className="menu-title">{cat.name}</div>
                      </div>
                    </div>
                  ))
                }
              </Slider>
              {showSubmenuNav && <div className="go-next-button"><DownCircleFilled onClick={this.nextSubmenuPage} /></div>}
            </div>
          </>
        ];
      } else {
        return [
          (
            <div className="product_list_container"
              key={"product_list_container"}
              style={this.state.container_size}>
              <div className="order_title_container"><h1>{this.state.title}</h1></div>
              {/* {this.map_your_selection(this.state.selected_product, this.state.your_selection)} */}
              {this.mapSelectedItems()}
              {this.inmap_product_list(this.state.product_list)}
            </div>
          )
        ];
      }
    } else {
      return [(
        <div className="product_list_container"
          key={"product_list_container"}
          style={this.state.container_size}>
          <div className="order_title_container"><h1>{this.state.title}</h1></div>
          <div className="category-unavailable-container">
            {availability.message.map(m => <span>{m}</span>)}
          </div>
        </div>
      )];
    }
  }

  hourInSlot(hour, start, end) {
    const now = moment(hour, 'HH:mm');
    const from = moment(start, 'HH:mm');
    const to = moment(end, 'HH:mm');

    return now.isBetween(from, to);
  }

  getAvailability() {
    const category = (this.props as any).go_to;
    // console.log('Planning: ', category.planning);
    let available = true;

    if (category.planning) {
      const { planning } = category;
      const today = moment();

      // Check if holiday
      const { school_holidays } = (window as any).data;
      const formatted = today.format('YYYY-MM-DD');
      if (!planning.schoolHolidays) {
        available = !school_holidays || school_holidays.indexOf(formatted) === -1;
      }

      if (available) {
        // Check week day availability
        const weekDay = today.weekday();
        if (planning.weekdays && planning.weekdays[weekDay]) {
          const daySlots = planning.weekdays[weekDay];
          available = daySlots.reduce((current, slot) => {
            const [start, end] = slot;
            return current || this.hourInSlot(today.format('HH:mm'), start, end);
          }, false);

        } else {
          available = false;
        }
      }
    }

    return {
      available,
      message: available ? undefined : category.planning.unavailabilityMessage
    }

  }

  calculate_padding_bottom() {
    return (this.props as any).handdicap_version.activated ? '177px' : '0px';
  }

  calculate_height() {
    if (is_electron_browser()) {
      if ((this.props as any).handdicap_version.activated) {
        return '977px';
      }
      return '1385px';
    }
    let reduce = HEADER_NAVIGATION_HEIGHT + HEADER_HEIGHT;
    let height = window.innerHeight - reduce;
    return height + 'px';
  }

  updateDimensions() {
    //console.log('---------------- resize');
    // let width = window.innerWidth;
    let container_size = {
      paddingBottom: '0px',
      height: this.calculate_height(),
      'marginLeft': '266px',
    };
    if ((this.props as any).handdicap_version.activated) { container_size['overflow'] = 'auto'; }
    this.setState({
      container_size: container_size
    });
  }

  mobileProductContainerScroll() {
    /*let that = this;
    let scrollTop = $('.product_list_container').scrollTop();
    if (scrollTop < 95) {
      $('.product_list_container .your_selection').removeClass('dont_moove');
      $('.product_list_container .your_selection').addClass('moove');
      $('.product_list_container .nextProductPage').addClass('moove');
      // $('.product_list_container .moove.nextProductPage').css('marginTop', 160 - scrollTop + 'px');
      $('.menu_top_container').css('height', '117px');
      $('.my_wrap-container').css('display', 'block');
    } else {
      $('.product_list_container .your_selection').removeClass('moove');
      $('.product_list_container .nextProductPage').removeClass('moove');
      $('.product_list_container .your_selection').addClass('dont_moove');
      $('.product_list_container .nextProductPage').css('display', 'block');
      $('.product_list_container .nextProductPage').css('marginTop', 'unset');
      if (that.product_without_price(that.state.selected_product)) {
        $('.menu_top_container').css('height', '64px');
        $('.my_wrap-container').css('display', 'none');
      }
    }*/
  }

  componentDidMountForMobile() {
    if (is_electron_browser()) { return; }
    $('.nextProductPage .terminal-hidden-block')
    document.body.style.overflow = 'hidden';
    this.mobileProductContainerScroll();
    let that = this;
    $('.product_list_container').scroll(function (e) {
      that.mobileProductContainerScroll();
    });
  }

  componentDidMount() {
    console.log("ProductList did mount ...");
    // Contournement probleme d'affichage de la liste de produits lorsqu'on utilise la navigation du browser
    this.reset();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.componentDidMountForMobile();
  }

  componentWillMountForMobile() {
    if (is_electron_browser()) { return; }
    document.body.style.overflow = 'auto';
  }

  componentWillUnmount() {
    console.log("ProductList will unmount ...");
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    this.componentWillMountForMobile();
  }

  // **************************** CATEGORY°
  reset() {
    //      // console.log('------------------reset')
    $('.product_list_container').scrollTop(0);
    this.carrousel_key = Math.random();
    this.path = [];
    this.pwd = 0;
    this.setState({
      'your_selection': [],
      'selected_product': {},
      selectionPath: {},
      stepCount: 0,
      currentStep: 0
    });
  }

  go_to(go_to: any) {
    //      // console.log('function::go_to')
    if (go_to == undefined) { return; }
    go_to.from = 'product_list';
    this.props.goToPath(go_to);
  }

  update_go_to(go_to: any) {
    if (go_to.action !== 'category') { return; }
    if (this.state.go_to.id !== go_to.id) {
      this.carrousel_key = Math.random();
    }
    this.setState({
      'go_to': go_to,
    });
    this.define_title_and_subtitle();
    if (this.state.go_to == undefined || go_to === undefined) { return; }
    if (!go_to.hasOwnProperty('from')) { return; }
    if (go_to.action !== "category") { return; }
    if (go_to.from !== 'product_list' && this.state.go_to.id !== go_to.id) {
      this.reset();
    }
  }

  // **************************** product_list
  update_product_list(product_list: any, go_to: any, setState: boolean = true) {
    let new_product_list = [];
    let category = this.get_category_in_your_selection(go_to);

    for (let product of product_list) {
      let new_product = Object.assign({}, product);
      let category_index = category.filter((c) => c.id === product.id);
      if (category_index.length !== 0) {
        new_product.virtual_quantity = category_index[0].quantity;
      } else {
        new_product.virtual_quantity = 0;
      }
      new_product.go_to = go_to;
      new_product_list.push(new_product);
    }
    if (setState) {
      this.setState({
        'product_list': new_product_list,
      });
    } else {
      this.state.product_list = new_product_list;
    }
  }
}

function mapStateToProps(state: any) {
  if (this_component !== undefined) {
    this_component.update_go_to(state.go_to);
    this_component.update_product_list(state.product_list, state.go_to);
    setTimeout(() => { this_component.updateDimensions() }, 150);
  }

  return {
    product_list: state.product_list,
    handdicap_version: state.handdicap_version,
    go_to: state.go_to,
    subCategories: subCategoriesSelector(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    update_data: update_data,
    goToPath: goToPath,
    addToCart: addToCart,
    activeHandicappedVersion: activeHandicappedVersion,
    reduceCart: reduceCart
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListComponent);
