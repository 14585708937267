
import { is_electron_browser, command_system } from './index';
import { setCookie, getCookie } from './cookies';
import { ErrorLogger } from './../index';

interface PageListType {
  is_wifi_order_connection: boolean,
}

class TrackerClass {
  user_info:PageListType = {
    is_wifi_order_connection: !is_electron_browser(),
  };
  page_list:Array<any> = [];
  constructor() {
      this.page_list = [];
  }

  getActualPage() {
    return this.page_list[this.page_list.length - 1];
  }

  calculate_change_page_average(time) {
    let change_page_average:any = this.get_change_page_average();
    change_page_average.number_save ++;
    change_page_average.total_time += time.time_in_page!==undefined ? time.time_in_page : 0;
    change_page_average.average = change_page_average.total_time / change_page_average.number_save;
    setCookie('change_page_average', JSON.stringify(change_page_average))
  }

  get_change_page_average() {
    let change_page_average:any = getCookie('change_page_average')
    if (change_page_average === undefined) {
      change_page_average = {
        'number_save': 0,
        'total_time': 0,
        'average': undefined,
      }
    } else {change_page_average = JSON.parse(change_page_average);}
    return change_page_average;
  }

  set_go_to(goto) {
    let payload = goto.payload;
    let action = goto.action;

    // Update last page
    if (this.page_list.length !== 0) {
      let actual_page = this.getActualPage();
      let closed_page = (new Date().getTime() /1000);
      actual_page.time.time_in_page = closed_page - actual_page.time.opened_page;
      this.calculate_change_page_average(actual_page.time);
    }

    // Add new pages
    payload.time = {
      'opened_page': (new Date().getTime() / 1000),
      'time_in_page': undefined,
    };
    this.page_list.push(payload);
  }

  redirect_to_begin() {
      this.save().then((e) => {
        (window as any).react_history.push('/maintenance/#redirect_to_begin');
        // window.location.href = '/';
      //   ErrorLogger.save().then((e) => {
      }).catch((e) => {
        (window as any).react_history.push('/maintenance/#redirect_to_begin');
      });
  }

  // When the browser reload to home page send the instructions.
  save() {
    let post_data = {
      'user_info': this.user_info,
      'page_list': this.page_list,
    };
    return command_system('save_tracker', post_data);
  }
}

const Tracker = new TrackerClass();
export default Tracker;
