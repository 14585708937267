export const updateProduct = (product, state) => {
  console.log("Update product: ", product);
  const categories = (window as any).data.product_list;

  let catIds = Object.keys(categories);

  for (const catId of catIds) {
    for (let i = 0; i < categories[catId].length; i++) {
      const id = categories[catId][i].__id;
      if (id === product.__id) {
        categories[catId][i] = Object.assign({}, categories[catId][i], {
          unit_price: product.unit_price,
          out_of_stock: product.out_of_stock,
        });
        console.log(
          "#@#@#@# Cat ID: " + catId + ", product: ",
          categories[catId][i]
        );
      }
    }
  }

  if (state) {
    const newState = [];
    for (const p of state) {
      if (p.__id == product.__id) {
        newState.push(Object.assign({}, p, product));
        console.log("#@#@#@# Product found in redux: ", p);
      } else {
        newState.push(p);
      }
    }

    return newState;
  }

  return state;
};
