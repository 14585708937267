import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addToCart, reduceCart } from './../../../actions/index';
import { staticFile } from './../../../libs/index';
let this_component: any;

interface Props {
  popin_this: any,
  go_to: any,
  addToCart: Function,
  reduceCart: Function,
}

class ProductDescriptionComponent extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  state: any = {
    'product': {},
    'selected_product': false,
  }

  add_more() {
    Object.assign(this.state.product, {
      'quantity': this.state.product.quantity + 1,
      'total_price': this.state.product.total_price + this.state.product.unit_price,
    });
    this.setState({
      'product': this.state.product,
    });
  }

  add_less() {
    if (this.state.product.quantity === 1) { return; }
    Object.assign(this.state.product, {
      'quantity': this.state.product.quantity - 1,
      'total_price': this.state.product.total_price - this.state.product.unit_price,
    });
    this.setState({
      'product': this.state.product,
    });
  }
  close_description() {
    // this.props.addToCart({
    //   'product': this.state.product,
    // });
    this.props.popin_this.onHide();
  }

  selectUnselectIngredient(ingredient) {
    console.log(ingredient)
    ingredient.ingredient_selected = !ingredient.ingredient_selected;
    this.setState({
      product: this.state.product
    })
  }

  //     bottom: 603px !important;
  render(): JSX.Element {
    this_component = this;
    this.state.handdicap_version = (this.props as any).handdicap_version;
    return (
      <div className={"product_description_container " + (this.state.handdicap_version.activated ? 'handicap_mode_activated' : 'handicap_mode_deactivate')}>
        <div className="imageContainer" style={{ backgroundImage: 'url("' + staticFile(this.props.go_to.product.ico) + '")' }}></div>
        <h2>{this.props.go_to.product.name}</h2>
        <h3>Description du produit</h3>
        <p>{this.props.go_to.product.description}</p>
        <div className="container-fluid footer">
          <div className="row justify-content-center">
            <button onClick={() => this.close_description()} className="button_yellow_on_white box_shadow_light col-lg-7 col-8">RETOUR</button>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      'product': Object.assign({}, this_component.props.go_to.product),
      'selected_product': this_component.props.go_to.selected_product,
    });
  }
}

function mapStateToProps(state: any) {
  if (this_component) {
    this_component.setState({
      handdicap_version: state.handdicap_version,
    });
  }
  return {
    handdicap_version: state.handdicap_version,
    go_to: state.go_to,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    addToCart: addToCart,
    reduceCart: reduceCart,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionComponent);
