import jsQR from "jsqr";
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPath, load_customer, update_data } from './../../../actions/index';
import { load_as_usual_command } from './../../../libs/fidelity';
import { is_electron_browser } from './../../../libs/index';
import Tracker from './../../../libs/tracker';
import { you_fid_client } from './../../../libs/you_fid_api';
// import {Link} from 'react-router-dom';
import HeaderNavigationComponent from './../../nav/header_navigation/header_navigation.component';

let this_component: any = undefined;
interface Props {
  load_customer: Function,
  goToPath: Function,
  shop: any,
  update_data: Function
}
class ScanFidelityCardComponent extends React.Component<Props> {
  test_number = 0;
  send_to_client(value) {
    you_fid_client.login_with_qr_code(value).then((e) => {
      if (you_fid_client.status_is_ok(e)) {
        if (!is_electron_browser()) {
          this.video.srcObject.getTracks().map(function (val) { val.stop() })
        }
        e.data.qr_code = value;
        load_as_usual_command(value, this.props.shop.siret, this.props);
        this.props.load_customer(e.data);
        (this.props as any).history.push('/profile/');
        Tracker.set_go_to({
          'payload': {
            'action': 'history',
            'id': '/profile/'
          }
        });
      } else {
        (this.props as any).history.push("/profile/create/#" + value);
        Tracker.set_go_to({
          'payload': {
            'action': 'history',
            'id': '/profile/',
            'reason': '3 error in scan code',
          }
        });
      }
    })
  }
  decode(value) {
    let decode_table = {
      'à': 0,
      '&': 1,
      'é': 2,
      '"': 3,
      "'": 4,
      '(': 5,
      '-': 6,
      'è': 7,
      '_': 8,
      'ç': 9,
    };
    if (!isNaN(parseInt(value))) {
      this.send_to_client(value);
      return;
    }
    let decoded = '';
    for (let caractere of value) {
      if (!decode_table.hasOwnProperty(caractere)) {
        continue;
      }
      decoded += decode_table[caractere];
    }
    if (!isNaN(parseInt(decoded))) {
      this.send_to_client(decoded);
    }
    return false;
  }
  background = {
    'ico': {
      'src': '/public/img/nav/binary_choice/scan_background.png',
      'style': {
        'backgroundImage': 'url(/public/img/nav/binary_choice/scan_background.png)',
        'backgroundPosition': '50% 77%',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': '72%',
        'height': '100%',
      }
    }
  };

  lock: boolean = false;
  handleKeyUp(event) {
    if (this.lock) { return; }
    this.lock = true;
    setTimeout(() => {
      this.lock = false;
      this.decode(event.target.value);
      event.target.value = '';
    }, 200);
  }

  focus_in_hidden_scan_text() {
    (document.getElementsByClassName('hidden_scan_text')[0] as any).focus();
  }
  render() {
    this_component = this;
    (window as any).react_history = (this.props as any).history;
    try {
      this.background.ico.style.backgroundSize = (this.props as any).handdicap_version.activated ? "45%" : '72%'
    } catch (err) { }

    if (!is_electron_browser()) {
      return (
        <div className={"qr_code_read_container locked " + (window.innerHeight > window.innerWidth ? 'show_other_element' : 'hide_other_element')}>
          <div className="header">
            <div style={{ backgroundImage: 'url(/public/img/headers/header1052x256.jpg)' }}>
            </div>
          </div>
          <HeaderNavigationComponent history={(this.props as any).history}></HeaderNavigationComponent>
          <div id="loadingMessage">🎥 IMPOSSIBLE D'ACCÉDER AU FLUX VIDÉO (VEUILLEZ VOUS ASSURER QUE VOTRE CAMÉRA EST ACTIVÉE) </div>
          <div className="canvas_container">
            <canvas id="canvas"
              hidden
              width="375"
              height="250"
              style={{
                marginTop: window.innerHeight > window.innerWidth ? ((window.innerHeight - (93 + 34 + 51 + 40 + window.innerWidth)) / 2) : '0px',
                marginLeft: window.innerHeight > window.innerWidth ? '0px' : ((window.innerWidth - window.innerHeight) / 2) + 'px'
              }}
            ></canvas>
          </div>
          <div id="output" hidden>
            <div id="outputMessage"></div>

          </div>
          <div id="fotter_scanner_fidelity" className="button_yellow_on_white" hidden>SCANNEZ VOTRE CARTE DE FIDÉLITÉ</div>
        </div>
      )
    } else {
      return (
        <div className="container-fluid"
          style={this.background.ico.style}
          onClick={() => this.focus_in_hidden_scan_text()}>
          <HeaderNavigationComponent history={(this.props as any).history}></HeaderNavigationComponent>
          <div className={(this.props as any).handdicap_version.activated ? "div_airbag_10percent" : "div_airbag_20percent"}></div>
          <div className="container">
            <div className='row justify-content-center'>
              <h2 className="col-12 text_align_center">
                SCANNEZ VOTRE CARTE DE FIDÉLITÉ
              </h2>
              <p className="sub_title">DANS LE LECTEUR SOUS L'ÉCRAN</p>
              <input
                type="text"
                autoFocus
                onKeyDown={() => this.handleKeyUp(event)}
                className="hidden_scan_text"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  video: any = undefined;
  componentDidMount() {
    if (is_electron_browser()) { return; }
    var video = document.createElement("video");
    this.video = video;

    var canvasElement = document.getElementById("canvas");
    var canvas = (canvasElement as any).getContext("2d");
    var loadingMessage = document.getElementById("loadingMessage");
    var outputContainer = document.getElementById("output");
    var outputMessage = document.getElementById("outputMessage");
    var footer = document.getElementById("fotter_scanner_fidelity");
    var scanned_qr_code = [];

    (canvasElement as any).width = 150;
    (canvasElement as any).height = 200;

    function drawLine(begin, end, color) {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }

    // Use facingMode: environment to attemt to get the front camera on phones
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
      video.srcObject = stream;
      (video as any).setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
      video.play();
      requestAnimationFrame(tick);
    });

    function tick() {
      //  Définir la taille de la fenetre
      let size_cube = window.innerHeight > window.innerWidth ? window.innerWidth : window.innerHeight;
      (window as any).testVideo = video;
      loadingMessage.innerText = "⌛ Loading video..."
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        footer.hidden = false;
        loadingMessage.hidden = true;
        canvasElement.hidden = false;
        outputContainer.hidden = false;

        (canvasElement as any).height = size_cube;
        (canvasElement as any).width = size_cube;
        canvas.drawImage(video, 0, 0, size_cube, size_cube);

        var imageData = canvas.getImageData(0, 0, size_cube, size_cube);
        var code = jsQR(imageData.data, size_cube, size_cube, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
          drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
          drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
          drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
          outputMessage.hidden = true;

          if (code.data.length !== 0) {
            if (scanned_qr_code.indexOf(code.data) == -1) {
              scanned_qr_code.push(code.data);
              this_component.send_to_client(code.data);
            }
          }
        }
      }
      requestAnimationFrame(tick);
    }
  }
}

function mapStateToProps(state: any) {
  return {
    handdicap_version: state.handdicap_version,
    shop: state.shop,
  };
}


function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    load_customer: load_customer,
    goToPath: goToPath,
    update_data: update_data,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanFidelityCardComponent);
