
import $ from 'jquery';
import {staticFile} from './index';

const ALL_IMAGE = [];

function push_in_all_image(image) {
  image = staticFile(image);
  if (ALL_IMAGE.indexOf(image) !== -1) { return; }
  ALL_IMAGE.push(image);
}

function load_image() {
  let category = (window as any).data.category;
  let product_list = (window as any).data.product_list;

  for (let key in product_list) {
    for (let product of product_list[key]) {
      push_in_all_image(product.ico);
    }
  }
  category.forEach((category_line) => {
    push_in_all_image(category_line.ico);
  });
  for (let image of ALL_IMAGE) {
    $('#loadImage').append(`<img src="${image}"/>`)
  }
  // product_list.forEach((product) => {
  //   console.log(product)
  // });
}
export const IMAGE_LOADER = load_image;
