import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Keyboard from 'react-virtual-keyboard';
import { is_terminal_browsing, LOCAL_SERVER, is_electron_browser } from './../../../libs/index';
import { you_fid_client } from './../../../libs/you_fid_api';
import $ from 'jquery';
import axios from 'axios';

const AlphabetCustomLayout = {
  'normal' : ['a z e r t y u i o p', 'q s d f g h j k l m', '{shift} w x c v b n \' {bksp}	', '{meta1} {space} @ {a}'],
  'shift': ['A Z E R T Y U I O P', 'Q S D F G H J K L M', '{shift} W X C V B N \' {bksp}', '{meta1} {space} @ {a}'],
  'meta1': ['1 2 3 4 5 6 7 8 9 0 ', '- / : ; ( ) € & @ "', '$  . , ? ! + # \' {bksp}	',  '{meta1}  {space} @ {a}']
};
const AlphabetDisplay = {
 'meta1'  : '123', // Diamond
 'space': 'Espace',
 'shift': '&nbsp;',
 'a': 'Valider',
 'bksp': '&nbsp;',
}

interface YOUFID_ACCOUNT_PROPS {
  setYouFidState: Function
}
class YouFidAccountComponent extends React.Component<YOUFID_ACCOUNT_PROPS> {
  constructor(props:any) {
    super(props);
  }
  state = {
    login: '',
    password: '',
    error: false,
  }

  lock_field: string | undefined = undefined;
  wait_to_send_request = undefined;

  change_state(that, key, value) {
      let new_state = {}
      new_state[key] = value;
      that.state = new_state;
      this.props.setYouFidState(new_state)
      this.setState(new_state);
  }

  keyup_handle(event, field) {
    this.change_state(this, field, event.target.value);
  }

  onInputChanged(data:any) {
    this.change_state(this, this.lock_field, data);
  }
  onSubmitEmail(data:any) {
      console.log('----------------- onSubmitEmail')
      console.log(data)
  }
  onFocusKeyboard(key) {
      this.lock_field = key;
  }

  input_keyboard_login() {
    if (is_terminal_browsing()) {
      return (
        <Keyboard
          value={this.state.login}
          name='keyboard'
          options={{
            type:"input",
            layout : 'custom',
            display : AlphabetDisplay,
            customLayout: AlphabetCustomLayout,
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: true,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
          }}
          onChange={this.onInputChanged.bind(this)}
          onAccepted={this.onSubmitEmail.bind(this)}
          onFocus={ () => this.onFocusKeyboard('login')}
          placeholder="Login"
          className="form-control "/>
        )
    } else {
      return (
        <div className="form-group">
          <input onKeyUp={(event) => this.keyup_handle(event, 'login')} type="text" placeholder="Login" className="form-control youfid__login"/>
        </div>
      );
    }
  }
  input_keyboard_password() {
    if (is_terminal_browsing()) {
      return (
        <Keyboard
          value={this.state.password}
          name='keyboard'
          options={{
            type:"input",
            layout : 'custom',
            display : AlphabetDisplay,
            customLayout: AlphabetCustomLayout,
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: true,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
          }}
          onChange={this.onInputChanged.bind(this)}
          onAccepted={this.onSubmitEmail.bind(this)}
          onFocus={ () => this.onFocusKeyboard('password')}
          placeholder="Password"
          className="form-control"/>
        );
    } else {
      return ((
        <div className="form-group">
          <input onKeyPress={(event) => this.keyup_handle(event, 'password')}
                 type="password"
                 placeholder="Password"
                 className="form-control youfid__password"/>
        </div>
      ));
    }
  }
  render():Array<JSX.Element> {
    let mapped = [
      (<label>Compte youfid:</label>),
      (<br/>),
    ]
    mapped.push(this.input_keyboard_login())
    mapped.push(this.input_keyboard_password());

    if (this.state.error) {
      mapped.push(
        <div className="error_text">
          Votre identifiant ou mot de passe youfid est incorrect !
        </div>
      )
    }

    return mapped
  }
}

// #################################################################################################
// #################################################################################################
// #################################################################################################
// #################################################################################################
// #################################################################################################

let this_component:any;
interface Props {

}
class FirstRunConfigurationComponent extends React.Component<Props> {
  constructor(props:any) {
    super(props);
  }
  state = {
      terminal_type: 'TERMINAL',
      terminal_name: 'BORNE-1',
      tva_intracommunautaire: '',
      siret: '',
      error: {
        terminal_name: false
      },
      youfid: {
        login: '',
        password: '',
        error: false,
      }
  };

  change_state(key, value) {
    let error = this.state.error;
    if (key==='terminal_name') {
      if (value.length<4) {
        error.terminal_name = true;
      } else {
        let params = {
          key: value
        };
        axios.post(LOCAL_SERVER + '/config/terminal/terminal_name_is_unique/', params).then((e) => {
          error.terminal_name = !e.data.success;
          this.setState({'error': error});
        })
      }
    }
    let new_state = {'error': error}
    new_state[key] = value;
    this.setState(new_state);
  }

  keyup_handle(event, field) {
    console.log(event.target.value)
    this.change_state(field, event.target.value);
  }

  lock_field: string | undefined = undefined;
  onInputChanged(data:any) {
    this.change_state(this.lock_field, data);
  }
  onSubmitEmail(data:any) {}
  onFocusKeyboard(key) {this.lock_field = key;}

  formSubmitIsOk() {
    let state = this.state;
    return (state.error.terminal_name===false && state.siret.length!==0 && state.tva_intracommunautaire.length!==0 && state.terminal_name.length!==0);
  }

  formSubmit() {
    if (!this.formSubmitIsOk()) { return; }
      console.log(this.state);
      let youfid__login:any  = 'fakeid';
      let youfid__password:any  = 'fakeid';
      if (is_terminal_browsing()) {
        youfid__login  = $('#root > div > div.fluid-container.app_global_container.handicap_mode_deactivate.path_name_first_run_configuration > div > div:nth-child(6) > div:nth-child(3) > input').val();
        youfid__password  = $('#root > div > div.fluid-container.app_global_container.handicap_mode_deactivate.path_name_first_run_configuration > div > div:nth-child(6) > div:nth-child(4) > input').val();
      } else {
        youfid__login  = $('.youfid__login').val();
        youfid__password  = $('.youfid__password').val();
      }

      if (youfid__login !== '' &&  youfid__password !== '') {
        Object.assign((this.state as any).youfid, {'login': youfid__login, 'password': youfid__password, });
        you_fid_client.test_merchant_login(youfid__login, youfid__password).then((e) => {
          if (e.data.status === 'error') {
            alert("votre mot de passe ou login YOUFID est invalide");
          } else if (e.data.status==='ok'){
            axios.post(LOCAL_SERVER + '/config/terminal/save_terminal_config/', this.state).then((e) => {
              if (e.data.success) {
                console.log(this.state)
                window.location.pathname = '/';
              }
            });
          }
        });
      } else {
        axios.post(LOCAL_SERVER + '/config/terminal/save_terminal_config/', this.state).then((e) => {
          if (e.data.success) {
            console.log(this.state)
            window.location.pathname = '/';
          }
        });
      }
  }
  // onFocus={ () => this.onFocusKeyboard('siret')}
  show_siret_field() {
    if (is_terminal_browsing()) {
      return (
        <Keyboard
         value={this.state.siret}
         name='keyboard'
         options={{
            type:"input",
            layout : 'custom',
            display : AlphabetDisplay,
            customLayout: AlphabetCustomLayout,
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: true,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
          }}
          onChange={this.onInputChanged.bind(this)}
          onAccepted={this.onSubmitEmail.bind(this)}
          onFocus={ () => this.onFocusKeyboard('siret')}
         placeholder="Siret"
         className="form-control"/>
       );
    } else {
      return (
        <div className="form-group">
          <input onKeyUp={(event) => this.keyup_handle(event, 'siret')}
                 type="text"
                 placeholder="Siret"
                 className="form-control"/>
        </div>
      )
    }
  }
  show_name_field() {
    if (this.state.terminal_type!=='TERMINAL') {return (<div></div>);}
    if (is_terminal_browsing()) {
      return (
        <Keyboard
         name='keyboard'
         options={{
            type:"input",
            layout : 'custom',
            display : AlphabetDisplay,
            customLayout: AlphabetCustomLayout,
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: true,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
          }}
          onChange={this.onInputChanged.bind(this)}
          onAccepted={this.onSubmitEmail.bind(this)}
          onFocus={ () => this.onFocusKeyboard('terminal_name')}
          placeholder="Nom de la borne"
          className="form-control"/>
       );
    } else {
      return (
        <div className="form-group">
          <input onKeyUp={(event) => this.keyup_handle(event, 'terminal_name')}
                 type="text"
                 placeholder="Nom de la borne"
                 className="form-control"/>
        </div>
      )
    }
  }
  show_tva_intracommunautaire() {
    if (is_terminal_browsing()) {
      return (
        <Keyboard
         value={this.state.tva_intracommunautaire}
         name='keyboard'
         options={{
            type:"input",
            layout : 'custom',
            display : AlphabetDisplay,
            customLayout: AlphabetCustomLayout,
            alwaysOpen: false,
            usePreview: false,
            useWheel: false,
            stickyShift: true,
            appendLocally: true,
            color: "light",
            updateOnChange: true,
            initialFocus: true,
          }}
          onChange={this.onInputChanged.bind(this)}
          onAccepted={this.onSubmitEmail.bind(this)}
          onFocus={ () => this.onFocusKeyboard('tva_intracommunautaire')}
         placeholder="Tva intracommnunautaire"
         className="form-control"/>
       );
    } else {
      return (
        <div className="form-group">
          <input onKeyUp={(event) => this.keyup_handle(event, 'tva_intracommunautaire')}
                 type="text"
                 placeholder="Tva intracommnunautaire"
                 className="form-control"/>
        </div>
      )
    }
  }
  selecthandleChange(event) {
    this.setState({'terminal_type': $('.first_run_configuration_container select').val()})
    event.preventDefault();
  }

  setYouFidState(state) {
    this.state.youfid = state;
    this.setState({
      'youfid': state,
    });
  }

  render():Array<JSX.Element> {
    if (!is_electron_browser()) {
      window.location.pathname = '/';
      return [(<div></div>)]
    }
    this_component = this;
    return [
      (
        <div className="first_run_configuration_container container">
          <h1>Paramétrage du logiciel</h1>
          <p>
            <b>Attention !!!</b>  <br/>
            Le paramétrages des informations de base du logiciel ne sont réalisable qu'une
            seul fois, vous n'aurez plus ACCES à cette page après avoir appuyer sur le bouton "Sauvegarder et quitter".
            <br/>
            Prenez donc le temp de valider les informations saisie.
          </p>
          <div className="form-group">
            <label>Type d'appareil:</label><br/>
             <form onSubmit={() => {}}>
              <select value={this.state.terminal_type}
                      onChange={this.selecthandleChange.bind(this)}
                      className="form-control">
                <option value="TERMINAL">Borne</option>
                <option value="WIFI_ORDER">Wifi order</option>
              </select>
            </form>
          </div>
          {this.show_name_field()}
          <div className="error_text" style={{display: this.state.error.terminal_name ? 'block': 'none'}}>
            Le nom que vous avez attribuer est invalide ou déjà utiliser !
          </div>
          <div className="form-group">
            <YouFidAccountComponent setYouFidState={this.setYouFidState.bind(this)}></YouFidAccountComponent>
          </div>
          <div className="form-group">
            <label>Information complémentaire:</label>
            <div className="form-group">
              {this.show_tva_intracommunautaire()}
              {this.show_siret_field()}
            </div>
          </div>
          <div className="row justify-content-center">
            <button onClick={() => this.formSubmit()}
                    className="button_yellow_on_white"
                    style={{opacity: this.formSubmitIsOk() ? 1: 0.5}}>Sauvegarder et quitter</button>
          </div>
        </div>
      ),
    ];
  }
}

function mapStateToProps(state:any) {
    if(this_component!==undefined) {}
    return {
      handdicap_version: state.handdicap_version
    };
}

function mapDispatchToProps(dispatch:any) {
  return bindActionCreators({
    // goToPath: goToPath,
    // addToCart: addToCart,
    // activeHandicappedVersion: activeHandicappedVersion,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(FirstRunConfigurationComponent);
