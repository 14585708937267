import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from 'react';
import ReactDOM from 'react-dom';
import { PaylineProvider } from 'react-payline';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { command_system, is_electron_browser } from './libs/index';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store';

(window as any).components = {};

// ----------------------------------------------- ERROR LOGGER
window.onerror = function (message, file, line, col, error) {
  // console.warn('------------------------- onerror');
  console.error(message, file, line, col, error, 'DONT_SHOW');
  return false;
};
window.addEventListener('unhandledrejection', function (e) {
  console.warn('------------------------- unhandledrejection');
  return false;
})

class ErrorLoggerClass {
  constructor() {
    this.redefine_console_error();
  }
  error_list = [];
  // redefi
  redefine_console_error() {
    let error_function = console.error;

    console.error = (l, l2, l3, l4, u, u2, u3, u4) => {
      [l, l2, l3, l4, u, u2, u3, u4].indexOf('DONT_SHOW') == -1 ? error_function(l, l2, l3, l4, u, u2, u3, u4) : undefined;

      let error = {
        'catcher': 'console.error',
        'timestamp': new Date().getTime() / 1000,
        'project_part': 'backend',
        'is_electron_browser': is_electron_browser(),
        'page': window.location.pathname,
        'trace': this.getStackTrace(),
        'error': [l, l2, l3, l4, u, u2, u3, u4],
      };
      this.freezeTest(error);
      this.error_list.push(error);
    }
    // this.save()
  }

  freezeTest(error) {
    error.test_id = Math.random();
    // axios.post(`${LOCAL_SERVER}/error_freeze/`, error).then((e) => {
    //   // -> Attendre 10 seconde pour avant de répondre pour évaluer si oui ou non le navigateur à freezer
    //   setTimeout(() => {
    //     axios.post(`${LOCAL_SERVER}/error_freeze/`, error).then((e) => {});
    //   }, 10 * 1000);
    // });
  }

  getStackTrace() {
    var obj = {};
    Error.captureStackTrace(obj, this.getStackTrace);
    return (obj as any).stack;
  };

  save() { return command_system('save_bug', this.error_list); }
}

export const ErrorLogger = new ErrorLoggerClass();

const sentryDsn = (window as any).sentryDsn;
if (sentryDsn && sentryDsn.trim() !== '') {
  Sentry.init({
    dsn: (window as any).sentryDsn,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const tags = (window as any).data ? (window as any).data.sentry_tags : {};
Sentry.setTags(tags);

ReactDOM.render(
  <PaylineProvider production={(window as any).paylineProduction || false}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </PaylineProvider>
  , document.getElementById('root')
);

// ReactDOM.render(<EnhancedApp />, document.getElementById('#root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
